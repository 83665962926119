import { RESPONSE_ANSWER_ONLY_OPTIONS, RESPONSE_ANSWER_ONLY_TARGETS } from "../CONSTANT";

// PARSE RESPONSE
// THIS HOOK IS IN CHGARGE TO PARSE RESPONSE THAT WILL SEND IT TO OUR BACKEND TO DO THE REVISION OF THE ACTIVITY ANSWER
// 

const useParseUserAnswer = () => {

    const parseAnswer = (answer) => {
        let copyAnswer = [...answer];
        // Final answer
        // Final answer object
        let answerParsed = {
            options: [],
            targets: []
        };

        // Create asnwer object with options and targets
        for (let index = 0; index < copyAnswer.length; index++) {
            const element = copyAnswer[index];
            answerParsed.options.push({
                id: element.option?.id,
                target_order: element.target?.order
            }) 
            answerParsed.targets.push({
                    id: element.target?.id,
                    order: element.target?.order
            })
            
        }
        return answerParsed;

    }

    const buildUserAnswer = (response, activityAnswerType)=> {
        let keyResponse = RESPONSE_ANSWER_ONLY_OPTIONS.includes(activityAnswerType) ? 'options' : RESPONSE_ANSWER_ONLY_TARGETS.includes(activityAnswerType) ? 'targets' : 'both'

        // ADD TO
        if(keyResponse === "options"){
            return({
                answer_content: {
                    user_data: {
                        options: response?.map((option) => ({
                            id: option.id,
                        }))
                    }
                }
            })
        }
        if(keyResponse === "targets")
            return({
                answer_content: {
                    user_data: {
                        targets: response?.map((target) => ({
                            id: target.id,
                            accepted_values: target.accepted_values,
                            order: target.order,
                            area: target.area
                        }))
                    }
                }
            })
        if(keyResponse === "both")
            return({
                answer_content: {
                    user_data: response
                }
            })
        return undefined;
    }


    return {
        parseAnswer,
        buildUserAnswer
    }
}


export default useParseUserAnswer;
