import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const preview_en = {
    "preview": require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/en/preview.json`),
    "kakuma_activities": require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/en/kakuma_activities.json`),
};

export const preview_es = {
    "preview": require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/es/preview.json`),
    "kakuma_activities": require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/es/kakuma_activities.json`),
};

export const preview_cat = {
    "preview": process.env.REACT_APP_CLIENT === 'mhe' ? require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/cat/preview.json`) : {},
    "kakuma_activities": process.env.REACT_APP_CLIENT === 'mhe' ? require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/cat/kakuma_activities.json`) : {},
    
};

export const preview_eus = {
    "preview": process.env.REACT_APP_CLIENT === 'mhe' ? require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/eus/preview.json`) : {},
    "kakuma_activities": process.env.REACT_APP_CLIENT === 'mhe' ? require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/eus/kakuma_activities.json`) : {},
    
};

export const preview_gal = {
    "preview": process.env.REACT_APP_CLIENT === 'mhe' ? require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/gal/preview.json`) : {},
    "kakuma_activities": process.env.REACT_APP_CLIENT === 'mhe' ? require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/gal/kakuma_activities.json`) : {},
    
};

export const preview_uk = {
    "preview": process.env.REACT_APP_CLIENT === 'sek' ? require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/uk/preview.json`) : {},
    "kakuma_activities": process.env.REACT_APP_CLIENT === 'sek' ? require(`./translations/${process.env.REACT_APP_CLIENT.toUpperCase()}/uk/preview.json`) : {},
};


i18n
.use(initReactI18next)
.init({
  debug: false,
  supportedLngs: ['ca', 'cat', 'en', 'es', 'eu', 'gl', 'uk'],
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
  resources: {
    "en": preview_en,
    "es": preview_es,
    "ca": preview_cat,
    "cat": preview_cat,
    "eu": preview_eus,
    "gl": preview_gal,
    "uk": preview_uk,
  }
});

export default i18n;
