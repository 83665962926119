import ImageLilipad from "./../images/lilypad2.png";
import Vector, { calculePositionToAngle } from "./Vector";

class Lilipad {
    position = { x: 0, y: 0 };
    positionFinal = null;
    velocity;
    size;
    image;
    angle;

    constructor(config) {
        this.position = config.initialPosition;
        this.velocity = config.velocity;
        this.angle = Math.floor(Math.random() * (360 - 0)) + 0;
        this.size = config.size;
        const newImage = new Image();
        newImage.src = ImageLilipad;
        this.image = newImage;
    }

    update() {
        if (this.positionFinal === null) return;
        const vector = new Vector(this.position, this.positionFinal);
        const newPositio = vector.nextStep(this.velocity);
        this.position = newPositio;
    }

    setDirection(angle, distance) {
        this.positionFinal = calculePositionToAngle(this.position, distance, angle);

    }

    draw(context) {
        const position = this.getFullPosition();
        if (
            (position.initial.x > 1000 || position.final.x < 0) ||
            (position.initial.y > 1000 || position.final.y < 0)
        ) return;
        context.save();
        if (this.angle !== 0) {
            const tranlateX = position.initial.x + this.size / 2;
            const tranlateY = position.initial.y + this.size / 2;
            context.translate(tranlateX, tranlateY);
            context.rotate((Math.PI * this.angle) / 180);
            context.translate(-tranlateX, -tranlateY);
        }

        context.drawImage(
            this.image,
            position.initial.x, position.initial.y, this.size, this.size
        );
        context.restore()
    }

    getPosition() {
        return this.position;
    }

    getFullPosition() {
        let halfCover = this.size / 2;
        return {
            initial: {
                x: this.position.x - halfCover,
                y: this.position.y - halfCover
            },
            center: {
                x: this.position.x,
                y: this.position.y
            },
            final: {
                x: this.position.x + halfCover,
                y: this.position.y + halfCover
            }
        }
    }
}

export default Lilipad;
