import React, { useEffect, useState } from 'react'
import DOMPurify from "isomorphic-dompurify"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PLACEHOLDER from "../images/placeholder-image.jpg";
import { MEDIA_PREVIEW } from '../../../CONSTANT';
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, XEduActivityContainer } from '../XEduActivitiesStyles';
import {StyledContainerOptionsTargets} from './ActivityOptionPairingStyles';
import HTMLReactParser from 'html-react-parser';

const ActivityImagePairing = ({ client, activity, activityType, targetsList, optionsList, setStateMicrogame, ondragged = () => { } }) => {
    const [answers, setAnswers] = useState([])
    const [options, setOptions] = useState([])

    useEffect(() => {
        const newListAnswers = targetsList.map(element => {
            return {
                target: element,
                option: undefined
            }
        });
        setAnswers(newListAnswers);

        const newListOption = optionsList.map((element, index) => {
            return {
                option: element,
                show: true
            }
        })
        setOptions(newListOption);
    }, [])

    useEffect(() => {
        const userAnswers = answers.filter(answer => answer?.option)
        setStateMicrogame(userAnswers)
    }, [answers]);

    const setShowOption = (optionId, isShowing) => {
        let newListOption = [...options];
        newListOption = newListOption.map(element => {
            if (element.option.id.toString() === optionId.toString()) {
                element.show = isShowing;
            }

            return element;
        });
        setOptions(newListOption)
    }

    const setOptionInTarget = (targetId, newOption) => {
        let newAnswersList = answers.map(element => {
            if (element.target.id.toString() === targetId.toString()) {
                if (element.option) {
                    setShowOption(element.option.id, true);
                }
                if (newOption) setShowOption(newOption.id, false);
                element.option = newOption;
            }
            return element;
        });

        setAnswers(newAnswersList);
        ondragged(newAnswersList);
        return newAnswersList;
    }

    const onDragEnd = (event) => {
        if (event.destination === null) return;
        const optionToSetInTarget = options.find(element => element.option.id.toString() === event.draggableId).option;
        setOptionInTarget(event.destination.droppableId, optionToSetInTarget);
    }

    const onClickAnswer = (elementClicked) => {
        setOptionInTarget(elementClicked.target.id, undefined);
    }

    return (
        <StyledActivityContainer>
			{activity?.title && <StyledActivityHeader><h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}/></StyledActivityHeader>}

            <StyledActivityContent client={client}>
                <XEduActivityContainer>
                {activity?.text && <p className='subtitle'  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }} />}
                <DragDropContext onDragEnd={onDragEnd}>
                    <StyledContainerOptionsTargets activityName={activityType === "text-pairing" ? "textPairing" : "imagePairing"}>
                        {
                            answers.map((element, index) => {
                                if (element.option !== undefined) {
                                    return <Droppable key={index} droppableId={"" + element.target.id} >
                                        {
                                            (provider) => (
                                                <div
                                                ref={provider.innerRef}
                                                {...provider.draggableProps}
                                                {...provider.dragHandleProps}
                                                key={index}
                                                className="container-answer"
                                                onClick={() => onClickAnswer(element)}
                                                
                                                >
                                                    {activityType === "image-pairing" ? (
                                                        <>
                                                            <img 
                                                                src={element.target?.image ? 
                                                                    `${element.target?.image?.startsWith("@@@") ? MEDIA_PREVIEW : ''}${element.target?.image}` : element.target?.image_url ?
                                                                    element.target?.image_url : 
                                                                    PLACEHOLDER
                                                                } 
                                                                alt="Target"
                                                                className="img-target" 
                                                                />
                                                            <img 
                                                                src={element.option?.image ? 
                                                                    `${element.option?.image?.startsWith("@@@") ? MEDIA_PREVIEW : ''}${element.option?.image}` : element.option?.image_url ?
                                                                    element.option?.image_url :
                                                                    PLACEHOLDER
                                                                } 
                                                                alt="Option"
                                                                className="img-answer"
                                                                />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p className="text-target" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.target?.title) }} ></p>
                                                            <p className="text-answer" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.option?.title) }}></p>
                                                        </>
                                                    )}
                                                    {provider.placeholder}
                                                </div>
                                            )
                                        }

                                    </Droppable>
                                }
                                
                                return <Droppable key={index} droppableId={"" + element.target.id} >
                                    {
                                        (provider) => (
                                            <div ref={provider.innerRef}
                                            {...provider.draggableProps}
                                            {...provider.dragHandleProps}
                                            className="container-droppable"
                                            >
                                                {activityType === "image-pairing" ? (
                                                    <>
                                                        <img 
                                                            src={element.target?.image ? 
                                                                `${element.target?.image?.startsWith("@@@") ? MEDIA_PREVIEW : ''}${element.target?.image}` : element.target?.image_url ?
                                                                element.target?.image_url : 
                                                                PLACEHOLDER
                                                            } 
                                                            alt="Target"
                                                            />
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className="text-target" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.target?.title) }} ></p>
                                                    </>
                                                )}
                                                {provider.placeholder}
                                            </div>
                                        )
                                    }

                                </Droppable>
                            })
                        }
                    </StyledContainerOptionsTargets>
                    <Droppable key={"chronologyTargeta"} index={1} droppableId={"optionsDroppable"} direction="horizontal">
                        {(provider) => (
                            <StyledContainerOptionsTargets 
                            activityName={activityType === "text-pairing" ? "textPairing" : "imagePairing"}
                            ref={provider.innerRef}
                            {...provider.draggableProps}
                            {...provider.dragHandleProps}
                            
                            >
                                {
                                    options.map((element, index) => {
                                        if (element.show === false) return null;
                                        return <Draggable key={index} index={element.option.id} draggableId={element.option.id + ""}>
                                            {(provided, snapshot) => (
                                                <div
                                                className="container-draggable"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{ ...provided.draggableProps.style }}
                                                >
                                                    {activityType === "image-pairing" ? (
                                                        <>
                                                            <img 
                                                                src={element.option?.image ?
                                                                    `${element.option?.image?.startsWith("@@@") ? MEDIA_PREVIEW : ''}${element.option?.image}` : element.option?.image_url ? 
                                                                    element.option?.image_url :
                                                                    PLACEHOLDER
                                                                } 
                                                                alt="Option"
                                                                />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p className="text-target" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.option?.title) }}></p>
                                                        </>
                                                    )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Draggable>
                                    })
                                }
                                {provider.placeholder}
                            </StyledContainerOptionsTargets>
                        )}
                    </Droppable>
                </DragDropContext >
                </XEduActivityContainer>
            </StyledActivityContent>
        </StyledActivityContainer>
    )
}

export default ActivityImagePairing
