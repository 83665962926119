import { XEduActivityContainer } from '../XEduActivitiesStyles';
import { IThemeProps } from '../../../themes/types';
import styled from "styled-components";

export const StyledMatchContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
`

export const StyledMatchCard = styled('div')<{theme: IThemeProps}>`
    width: ${({theme}) => theme.themeActivities.match.cardWidth};
    height: ${({theme}) => theme.themeActivities.match.cardHeight};
    margin: ${({theme}) => theme.margin.short};
    border-radius:  ${({theme}) => theme.themeActivities.match?.cardRadius};
  
    overflow: hidden !important;
    display: flex;
    justify-content: center;
    align-items: center;

`

export const StyledMatchCardOption = styled(StyledMatchCard)<{ isSelected: boolean, theme: IThemeProps}>`
    background-color: ${({theme, isSelected}) => isSelected && theme.themeActivities.match.option.backgroundColorEmptyCardSelected ? theme.themeActivities.match.option.backgroundColorEmptyCardSelected : 'transparent'};
    border: ${({theme, isSelected}) => isSelected && theme.themeActivities.match.option.borderEmptyCardSelected ? theme.themeActivities.match.option.borderEmptyCardSelected : '2px solid transparent'};
    p {
        display: -webkit-box !important;
        -webkit-line-clamp: 6 !important;
        -webkit-box-orient: vertical !important;  
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        text-align: center;
        color: ${({theme, isSelected}) => isSelected ? theme.themeActivities.match.option.colorOnSelect : theme.themeActivities.match.option.color};
        border-radius:  ${({theme}) => theme.themeActivities.match?.cardRadius};
        font-size: ${({ theme }) => theme.themeActivities.match.option?.size ?? theme.styles.text.subtitle.size};
        font-weight: ${({theme}) => theme.themeActivities.match.option?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
        font-family: ${({theme}) => theme.themeActivities.match.option?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
        line-height: ${({ theme }) => theme.themeActivities.match.option?.lineHeight ?? theme.styles.text.subtitle.lineHeight};
        background-color: ${({theme, isSelected}) => isSelected ? theme.themeActivities.match.option.backgroundColorOnSelect :theme.themeActivities.match.option.backgroundColor};

    }

    .match_card_text_container{
        display:flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: ${({theme, isSelected}) => isSelected && theme.themeActivities.match.option.backgroundColorEmptyCardSelected ? theme.themeActivities.match.option.backgroundColorEmptyCardSelected : theme.themeActivities.match.option.backgroundColor};
        border-radius:  ${({theme}) => theme.themeActivities.match?.cardRadius};
    }
    
`

export const StyledMatchCardTarget = styled(StyledMatchCard)<{ isSelected: boolean, theme: IThemeProps, hasCardDropped:boolean }>`
    border: ${({theme}) => theme.themeActivities.match.target.border};
    background-color: ${({hasCardDropped, theme, isSelected}) => hasCardDropped && isSelected ? 'transparent' : hasCardDropped ? theme.themeActivities.match.option?.backgroundColorOnSelect : 'transparent'};
    p {
        display: -webkit-box !important;
        -webkit-line-clamp: 6 !important;
        -webkit-box-orient: vertical !important;  
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        text-align: center;
        color: ${({theme}) => theme.themeActivities.match.target.color};
        font-size: ${({ theme }) => theme.themeActivities.match.target?.size ?? theme.styles.text.subtitle.size};
        font-weight: ${({theme}) => theme.themeActivities.match.target?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
        font-family: ${({theme}) => theme.themeActivities.match.target?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
        line-height: ${({ theme }) => theme.themeActivities.match.target?.lineHeight ?? theme.styles.text.subtitle.lineHeight};


    }

    .match_card_text {
        color: ${({theme}) => theme.themeActivities.match.target.colorOnSelect};
        border-radius:  ${({theme}) => theme.themeActivities.match?.cardRadius};
    }

    .match_card_text_container{
        display:flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: ${({theme}) => theme.themeActivities.match.target.backgroundColorOnSelect};
        border-radius:  ${({theme}) => theme.themeActivities.match?.cardRadius};
    }

    &:hover{
        background-color: ${({theme, isSelected}) => isSelected ? theme.themeActivities.match.target.backgroundColorOnDrop : ''}
    }
`


export const StyledSeparatorLine = styled('div')<{theme: IThemeProps}>`
    height: 1px;
    width: 100%;
    background-color: ${({theme})=> theme.themeActivities.match?.lineSeparator?.useLine ? theme.themeActivities.match?.lineSeparator?.color : 'transparent'};
    margin: ${({theme}) => theme.margin.short} 0;

`
