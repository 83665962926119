import React from 'react';
import { StyledHeaderContainer, StyledHeaderContent } from './HeaderStyles';
import { useTranslation } from 'react-i18next';
import ReadSpeaker from '../../components/ReadSpeaker/ReadSpeaker';

const Header = ({showTitle, showReadSpeaker}) => {
    const [_t] = useTranslation("preview");

    return (
        <StyledHeaderContainer className="rs_skip">
            <StyledHeaderContent>
                <div className='titleContainer'>
                    <img src={'/icons/headerLogo.svg'} alt={_t("header.alt_img_header_logo")} tabIndex={0}/>
                    {showTitle && <h1 tabIndex={0}>{_t("header.title")}</h1>}
                </div>
                {showReadSpeaker ? <ReadSpeaker/> : <div></div>}
            </StyledHeaderContent>
        </StyledHeaderContainer>
    );
}

export default Header;
