import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";

export const StyledFillTheBlanksInput = styled('input')<{theme: IThemeProps}>`
	border: 0;
	font-size: ${({theme})  => theme.themeActivities?.fillTheBlanks?.input?.fontSize ?? theme.styles.text.subtitle.size};
	margin: 0 2px;
	background: ${({theme})  => theme.themeActivities?.fillTheBlanks?.input?.backgroundColor};
	color: ${({theme})  => theme.themeActivities?.fillTheBlanks?.input?.color ?? theme.styles.text.subtitle.color};
	font-family:${({theme}) => theme.themeActivities?.fillTheBlanks?.input?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
	border-bottom: 2px solid ${({theme})  => theme.themeActivities?.fillTheBlanks?.input?.borderBottom};
	max-width: ${({theme})  => theme.themeActivities?.fillTheBlanks?.input?.maxWidth ?? '80px'};
	font-weight: ${({theme})  => theme.themeActivities?.fillTheBlanks?.input?.fontWeight ?? 'unset'};
	&:focus{
		outline: none;
	}
`;


export const StyledFillTheBlanksText = styled('span')<{theme: IThemeProps}>`
	color: ${({theme})  => theme.themeActivities?.fillTheBlanks?.span?.color};
	font-family: ${({theme})  => theme.themeActivities?.fillTheBlanks?.span?.fontFamily};
	font-size: ${({theme})  => theme.themeActivities?.fillTheBlanks?.span?.fontSize};
	font-weight: ${({theme})  => theme.themeActivities?.fillTheBlanks?.span?.fontWeight ?? 'unset'};
`;


export const StyledFillTheBlanksContainer = styled('div')`
	display: flex;
    justify-content: center;
    width: 100%;
`

export const StyledParagraph = styled('p')<{theme: IThemeProps, channel: String}>`
	padding: ${({theme}) => theme.themeActivities?.fillTheBlanks?.paragraph?.padding ?? '0'};
	margin: ${({theme}) => theme.themeActivities?.fillTheBlanks?.paragraph?.margin ?? '0'};
	background-color:  ${({theme}) => theme.themeActivities?.fillTheBlanks?.paragraph?.backgroundColor ?? 'transparent'};
	border-radius:  ${({theme}) => theme.themeActivities?.fillTheBlanks?.paragraph?.borderRadius ?? '0'};
	`

export const StyledCounterCharacter = styled('span')`



`
