import * as React from 'react';
import { ContainerFeedBackImage, ContainerFeedBackInfo, FeedBackImage } from '../BottomBarStyles';
import { useTranslation } from 'react-i18next';
import { IFeedbackProps } from '../types';


export default function FeedbackImage ({client, feedback}: IFeedbackProps) {
    const [_t] = useTranslation("preview");
    
    
    return (
    <ContainerFeedBackImage>
      <FeedBackImage>
          {<img src={`/images/feedbacks/${client}/${client}_${feedback?.type}${client === 'mhe' ? '.svg' : '.png'}`} alt='feedback-img' tabIndex={0}/>}
        </FeedBackImage>
        <ContainerFeedBackInfo>
            <p className="title-feedback" tabIndex={0} aria-live="assertive">
                {_t(`feedback_answer_activity_title.${feedback?.type}`)}
            </p>
            <p className="generic-feedback" tabIndex={0} aria-live="assertive">
                {feedback?.generic_feedback_title ?? _t(`feedback_answer_activity_generic.${feedback?.type}`)}
            </p>
        </ContainerFeedBackInfo>
    </ContainerFeedBackImage>
    );
}
