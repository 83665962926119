import styled from "../../themes/styled";
import { IThemeProps } from "../../themes/types";

export const ContainerBottomBar = styled("div")<{ hasFeedback: boolean, theme: IThemeProps}>`
    width: 100%;
    border-radius: 20px 20px 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    background-color: ${({ theme }) => theme.styles.previewContainerBackgroundColor ?? "white"};
`

export const ContainerFeedback = styled("div")<{hasFeedback:boolean}>`
    width: 100%;
    height: auto;
    display: flex;
    bottom: 80px;
    position: absolute;
    background-color: ${({ theme }) => theme.bottomBar.feedback.backgroundColor ?? "white"};

    color: var(--color-secondary);
    justify-content: flex-start;
    align-items: center;

`

export const ContainerFeedBackImage = styled("div")<{theme: IThemeProps}>`
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: var(--border-radius );
    border-radius: ${({ theme }) => theme.bottomBar.feedback.borderRadius ?? "0px"};
    background-color: ${({ theme }) => theme.bottomBar.feedback.backgroundColor ?? "white"};
    flex-direction: ${({ theme }) => theme.bottomBar.feedback.flexDirection ?? "row"};
    justify-content: flex-start;
    align-items: center;
    padding: var(--margin-short) var(--margin-default);
    padding-bottom: var(--margin-long);
    margin: 0 auto;

    
`
export const FeedBackImage = styled("div")`
    width: 100%%;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 15px;
    padding: 5px;

    & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
        animation: swing-in-top-fwd 2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    }

`

export const ContainerFeedBackInfo = styled("div")<{theme: IThemeProps}>`
    width: 100%;

    & > .title-feedback {
        color: ${({theme}) => theme.bottomBar.feedback.title.color ?? 'black'};
        font-family: ${({theme}) => theme.styles.text.subtitle.fontFamily};
        font-weight: ${({theme}) => theme.bottomBar.feedback.title.fontWeight ?? 'bold'};
        font-size: ${({theme}) => theme.bottomBar.feedback.title.fontSize ?? 'calc(1em + 1vw)'};
        text-align: center;
        margin: 0;
        webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

    & > .generic-feedback {
        font-family: ${({theme}) => theme.styles.text.subtitle.fontFamily};
        color: ${({theme}) => theme.bottomBar.feedback.subtitle.color ?? 'grey'};
        font-weight: ${({theme}) => theme.bottomBar.feedback.subtitle.fontWeight ?? '100'};
        font-size: ${({theme}) => theme.bottomBar.feedback.subtitle.fontSize ?? '15px'};
        width: 100%;
        text-align: center;
        margin: 0;
        webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

`


export const ContainerFeedbackBar = styled("div")<{ feedback: string, theme: IThemeProps}>`
    width: 100%;
    height: auto;
    margin: 0 1em 0 1em;
    margin-bottom: var(--margin-short);


    z-index: 999;
    & > .title-feedback{
        webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        border-radius: ${({ theme }) => theme.bottomBar.feedback.borderRadius ?? "0px"};
        background-color: ${({ theme, feedback }) => theme.bottomBar?.feedback[feedback] ? theme.bottomBar?.feedback[feedback] : 'red'} !important;
        padding: 0.5em 1em;
        color: ${({theme}) => theme.bottomBar.feedback.title.color ?? 'black'};
        font-weight: ${({theme}) => theme.bottomBar.feedback.title.fontWeight ?? 'bold'};
        font-size: ${({theme}) => theme.bottomBar.feedback.title.fontSize ?? 'calc(1em + 1vw)'};
        text-align: center;

    }
    & > div{
        margin-bottom: var(--margin-short);
        border-top: 1px solid #CCCCCC;
        padding-bottom: 20px;
    }

    & > .generic-feedback {
        box-sizing: border-box;
        font-family: ${({theme}) => theme.styles.text.subtitle.fontFamily};
        font-weight: ${({theme}) => theme.bottomBar.feedback.title.fontWeight ?? 'bold'};
        font-size: ${({theme}) => theme.bottomBar.feedback.subtitle.fontSize ?? 'calc(1em + 1vw)'};
        padding: 1em;
        width: 100%;
        text-align: center;
        margin: 0;
        webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

`

export const ButtonCheckResult = styled("button") <{ disabled?: boolean, feedback: string, theme: IThemeProps }>`
    --color-default: ${({ theme }) => theme.color.primary.contrastText};
    --background-primary: ${({ theme }) => theme.color[theme.bottomBar.button.backgroundColor].main};
    
    
    width: ${({ theme }) => theme.bottomBar.button.width ?? '100%'};
    padding: ${({ theme }) => theme.margin.default};
    border: ${({ theme }) => theme.bottomBar.button.border ?? 'none'};
    border-radius: ${({ theme }) => theme.bottomBar.button.borderRadius};
    font-size: ${({ theme }) => theme.bottomBar.button.fontSize ? theme.bottomBar.button.fontSize : theme.styles.text.title.size};
    font-weight: ${({ theme }) => theme.bottomBar.button.fontWeight ? theme.bottomBar.button.fontWeight : theme.styles.text.title.fontWeight};
    line-height: ${({ theme }) => theme.bottomBar.button.lineHeight ? theme.bottomBar.button.lineHeight : theme.styles.text.title.lineHeight};
    font-familty: ${({ theme }) => theme.bottomBar.button.fontFamily ? theme.bottomBar.button.fontFamily : theme.styles.text.title.fontFamily};
    
    margin: 0;
    color: ${({ theme }) => theme.bottomBar?.button.colorEnabled};
    
    background-color: ${({theme, feedback}) => (theme.bottomBar?.button.useFeedbackBackgroundColor && theme.bottomBar?.feedback[feedback] ) ? theme.bottomBar?.feedback[feedback] 
    : theme.bottomBar.button.backgroundColorEnabled};
    
    opacity: 1;
    margin: 0.5em;
    margin-bottom: 1em;
    &:hover {
        cursor: pointer;
        outline: none;
        opacity: 0.9;
    }
    
    &:disabled{
        background-color: ${({theme}) => theme.bottomBar.button.backgroundColorDisabled};
        opacity: ${({ theme }) => theme.bottomBar.button.opacityDisabled};   
        color: ${({ theme }) => theme.bottomBar?.button.colorDisabled};
        cursor: inherit;

    }
`

export const ContainerButton = styled("div")`
    width: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
`
