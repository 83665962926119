import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";

export const StyledContainerInput = styled('div')<{theme: IThemeProps, activityName: string}>`
    & > *{	
        margin: 0;	
    }	
    font-size: ${({theme, activityName}) => theme.themeActivities[activityName].option.fontSize} !important;	
    font-family: ${({theme, activityName}) =>theme.themeActivities[activityName].option.fontFamily};	
    display: flex;	
    align-items: center;	
    justify-content: ${({theme, activityName}) =>theme.themeActivities[activityName].option.justifyContent};	
    flex-direction: ${({theme, activityName}) =>theme.themeActivities[activityName].option.flexDirection};	
    padding: ${({theme, activityName}) =>theme.themeActivities[activityName].option.padding};
    cursor: pointer;	
    border: ${({theme, activityName}) =>theme.themeActivities[activityName].option.border};
    border-radius: ${({theme, activityName}) =>theme.themeActivities[activityName].option.borderRadius};
    background-color:${({theme, activityName}) =>theme.themeActivities[activityName].option?.background ?? 'transparent'};
    color: ${({theme, activityName}) => theme.themeActivities[activityName].option.color};
    margin-bottom: ${({ theme }) => theme.margin.short};	
    margin-left: ${({theme, activityName}) => theme.themeActivities[activityName].option.hasMarginHorizontal ? theme.margin.default : '0'};
    margin-right: ${({theme, activityName}) => theme.themeActivities[activityName].option.hasMarginHorizontal ? theme.margin.default : '0'};

    p{
        margin: 0;
    }

    &.selected{	
        ${({theme, activityName}) => {
            const textColorHover = theme.themeActivities[activityName].option.colorHover ?? theme.color.primary.main;	
            const fontWeightHover = theme.themeActivities[activityName].option.fontWeightHover ?? 'none';	

            let backgroundHoverColor = "transparent";	
            const backgroundHoverName = theme.themeActivities[activityName].option.backgroundHover;	
            if (backgroundHoverName && backgroundHoverName !== "transparent")	
                backgroundHoverColor = theme.color[backgroundHoverName].main;
    
            return `
                background-color: ${backgroundHoverColor};	
                // border: 2px solid ${backgroundHoverColor};	
                p{
                    color: ${textColorHover};
                    font-weight ${fontWeightHover};
                }	
            `
        }}
    }	
`	



export const StyledBallSelected = styled.div<{ theme: IThemeProps, activityName: string }>(({ theme, activityName }) => {
    const size = theme.themeActivities[activityName].option.point.size;
    const border = theme.themeActivities[activityName].option.point.border;
    const borderRadius = theme.themeActivities[activityName].option.point.borderRadius;
    const innerBallSize = theme.themeActivities[activityName].option.point.innerBallSize;
    const innerBallBorderRadius = theme.themeActivities[activityName].option.point.innerBallBorderRadius;
    const ballColorName = theme.themeActivities[activityName].option.point.color;
    const borderSelected = theme.themeActivities[activityName].option.point.borderSelected ?? border;
    const colorCheck = theme.themeActivities[activityName].option.point.colorCheck;
    const type = theme.themeActivities[activityName].option.point.type;

    return `
        --ball-size: ${size};	
        --ball-border: ${border};	
        --ball-border-radius: ${borderRadius ?? '0px'};	
        --inner-ball-size: ${innerBallSize ?? "24px"};	
        --inner-ball-border-radius: ${innerBallBorderRadius ?? "0px"};	
        --ball-color: ${ballColorName};
        --border-selected: ${borderSelected};
        --color-check: ${colorCheck};

        display: inline-flex;
        align-items: center;

        input {
            overflow: hiddden;
            height: var(--ball-size);
            width: var(--ball-size);
            border: var(--ball-border);
            border-radius: var(--ball-border-radius);
            position: relative;
            margin-right: var(--margin-short);
            appearance: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &::before {
                content: '';
                border-radius: var(--inner-ball-border-radius);
                height: var(--inner-ball-size);
                width: var(--inner-ball-size);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                background-color: transparent; /* Se asegura de que el fondo sea transparente cuando no está marcado */
            }

            &:checked::before {

                content: '${type === "check" ? "✓" : ""}';
                color: var(--color-check);
                background-color: var(--ball-color);
                font-weight: bold;
                font-size: 16px;
            }

            &:checked {
                border: var(--border-selected);
            }
        }
    `;
});

export const StyledTextContainer = styled ('p')<{theme: IThemeProps, channel: string, activityName: string}>`
    box-sizing: border-box;
    background-color: white;
    border-radius: 8.08px;
    overflow-y: scroll;
    min-height: ${({channel}) => channel === "mobile" ? '40px' : 'auto'} !important;
    width: ${({channel,theme, activityName}) => channel === "mobile" ? '100%' : theme.themeActivities[activityName].text?.fullwidth ? '100%' : "45%"};
    margin-bottom: ${({channel}) => channel === "mobile" ? '0.5rem' : "0"};
    word-wrap: break-word;
    font-size: ${({theme, activityName}) => theme.themeActivities[activityName].text?.fontSize ?? theme.styles.text.subtitle.size};
    color: ${({theme, activityName}) => theme.themeActivities[activityName].text?.color ?? theme.styles.text.subtitle.color};
    font-family: ${({theme, activityName}) => theme.themeActivities[activityName].text?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
    font-weight:  ${({theme, activityName}) => theme.themeActivities[activityName].text?.fontWeight ?? theme.styles.text.subtitle.fontWeight};


    /* Width */
    &::-webkit-scrollbar {
        width: 6px;
        margin: 1em;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 4px ${({theme}) => theme.color.primary.main};
        border-radius: 0px;
    }

    /* Handle #43a1a2 or #214F61 or #ADADAD */
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 4px 2px 12px 2px ${({theme}) => theme.color.primary.main};
        border-radius: 4px;
    }
`


export const StyledOptionButton = styled("button")<{theme: IThemeProps, activityName: string, checked: boolean}>`
    --color-default: ${({ theme }) => theme.color.primary.contrastText};
    --background-primary: ${({ theme }) => theme.color[theme.bottomBar.button.backgroundColor].main};
    

    width: 253px;
    min-height: 45px;
    padding: ${({ theme }) => theme.margin.short};
    margin: ${({ theme }) => theme.margin.short};
    border: ${({ theme }) => theme.bottomBar.button.border ?? 'none'};
    border-radius: ${({ theme }) => theme.bottomBar.button.borderRadius};
    font-size: ${({ theme,activityName }) => theme.themeActivities[activityName].option?.buttons?.fontSize ?? theme.styles.text.subtitle.size};
    font-weight: ${({ theme,activityName }) => theme.themeActivities[activityName].option?.buttons?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
    line-height: ${({ theme,activityName }) => theme.themeActivities[activityName].option?.buttons?.lineHeight ?? theme.styles.text.subtitle.lineHeight};
    font-familty: ${({ theme,activityName }) => theme.themeActivities[activityName].option?.buttons?.fontFamily ?? theme.styles.text.subtitle.fontFamily};

    background-color: ${({theme, checked}) => checked ? theme.bottomBar.button.backgroundColorEnabled : theme.bottomBar.button.backgroundColorDisabled};
    color: ${({ theme, checked }) => checked ? theme.bottomBar.button.colorEnabled : theme.bottomBar?.button.colorDisabled};
    
    opacity: 1;
    
    &:hover {
        cursor: pointer;
        outline: none;
        opacity: 0.9;
    }
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    
`;

export const StyledButtonsContainer = styled('div')<{channel: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box
    width: ${({channel}) => channel === "mobile" ? '100%' : "50%"};
    height: auto;
    margin: 10px;
`;
