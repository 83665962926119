import { MheBasicThemeWeb } from "./MheBasicThemeWeb";
import { DefaultThemeWeb } from "./DefaultThemeWeb";
import { Mhe2023ThemeWeb } from "./Mhe2023ThemeWeb";
import { SekBasicThemeWeb } from "./SekBasicThemeWeb";


export const THEME_CONFIG = {
    'MHE': {
        'basic': MheBasicThemeWeb,
        'mhe_2023': Mhe2023ThemeWeb,
    },
    'SEK': {
        'basic': SekBasicThemeWeb,
    },
    'XIMDEX': {
        'default': DefaultThemeWeb
    }
}
