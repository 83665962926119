import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, XEduActivityContainer } from "../XEduActivitiesStyles";
import DOMPurify from "isomorphic-dompurify";
import { StyledMatchCardOption, StyledMatchCardTarget, StyledMatchContainer, StyledSeparatorLine } from "./MatchStyles";
import useParseUserAnswer from "../../../hooks/useParseUserAnswer";
import { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { useAccessibility } from "../../../hooks/useAccesibility";

const Match = ({activity, client, setStateMicrogame}) => {
	const [answer, setAnswer] = useState([])
	const [cards, setCards] = useState([]);
	const [cardsCopy, setCardsCopy] = useState([]);
	const [cardsInTarget, setCardsInTarget] = useState([]);
	const [emptyTargets, setEmptyTargets] = useState([]);
    const [optionSelected, setOptionSelected] = useState(undefined)
    const {parseAnswer} = useParseUserAnswer();
    const theme = useContext(ThemeContext);
    const showTargetOnSelect = theme.themeActivities.match.showTargetOnSelect;
    const [_t] = useTranslation("kakuma_activities");
    const sanitizeHtml = useAccessibility()

	useEffect(() => {
		setStateMicrogame(parseAnswer(answer));
	}, [answer]);

	useEffect(() => {
		setCards(activity.options);
		setCardsInTarget(activity?.targets?.sort((a, b) => a.order - b.order));
		setEmptyTargets(activity?.targets?.sort((a, b) => a.order - b.order));
	}, [activity]);

	const handleOnDragEnd = (result) => {
        setOptionSelected(undefined)
        if (!result.destination || !result.destination?.droppableId.includes("target")) return null;
		if (result.source.droppableId === result.destination?.droppableId && result.source.index === result.destination.index) return null;

        let draggedItem;
		const newCards = [...cards];
		let answerCopy = [...answer]
		let cardstemplateCopy = [...cardsCopy];
		const destinationIndex = result.destination.droppableId.split("-")[1];
        const targetSelected = emptyTargets[destinationIndex]

		if (result.source.droppableId.includes("target")) {
			draggedItem = cardstemplateCopy[result.source.index];
			cardstemplateCopy[result.source.index] = cardstemplateCopy[destinationIndex];
			cardstemplateCopy[destinationIndex] = draggedItem
			let index = answerCopy.findIndex(element => element.option.id === draggedItem.id)
			if(index !== -1) answerCopy[index].target = targetSelected
		} else {
			draggedItem = cards[result.source.index];
            if(cardstemplateCopy[destinationIndex]) newCards.push(cardstemplateCopy[destinationIndex])
			cardstemplateCopy[destinationIndex] = draggedItem;

			newCards.splice(result.source.index, 1);
			answerCopy.push({
				option: draggedItem,
				target: targetSelected
			})
		}
        setCardsCopy(cardstemplateCopy);
		setCards(newCards);
		setAnswer(answerCopy)
	};

    const handleOnDragStart = (result) => {
        setOptionSelected(result.source.droppableId)
    }


	return (
	<StyledActivityContainer >

		{activity?.title && 
            <StyledActivityHeader>
                <h2
                    tabIndex={0}
                    aria-labelledby={`${activity.title}`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}
                />    
            </StyledActivityHeader>}

		<StyledActivityContent client={client}>
            <XEduActivityContainer>
            {activity?.text && (
                    <>
                        <p 
                            role="region"
                            aria-labelledby={`${sanitizeHtml(activity?.text)}`}
                            className="subtitle" 
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }}
                        />
                    </>
                    )
            }
            

			<DragDropContext onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd}>

                {cardsInTarget && (
                    <StyledMatchContainer
					client={client}
					>
						{cardsInTarget.map((card, index) => {
                            return (
                                <Droppable key={"chronologyTarget" + index} droppableId={"target-" + index}>
									{(provided) => (
                                        <StyledMatchCardTarget 
                                        isSelected={optionSelected ? true : false}
                                        hasCardDropped={cardsCopy[index] !== '' && cardsCopy[index] ? true : false}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="chronologyTarget"
                                        client={client}
										>
											{!(cardsCopy[index] !== '' && cardsCopy[index]) ?
                                                <>
                                                    <p className={"orderChronology_card_text"} aria-labelledby={`${index  + 1}`}>
                                                      {card?.title}
                                                    </p>
                                                </>
                                            : cardsCopy[index] !== '' && cardsCopy[index] && showTargetOnSelect ?
                                                <>
                                                    <p className={"orderChronology_card_text"} aria-labelledby={`${index  + 1}`}>
                                                    {card?.title}
                                                    </p>
                                                </>
                                            : 
                                            <></>
                                            }
											{cardsCopy[index] !== '' && cardsCopy[index] &&(
                                                <Draggable
                                                draggableId={index.toString()}
                                                key={card.id}
                                                index={index}
												>
													{(provided) => (
                                                        <div className="match_card_text_container"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div className="match_card_text">
                                                                <p aria-labelledby={card.title}>{cardsCopy[index]?.title}</p>
                                                                {provided.placeholder}
                                                            </div>
                                                        </div>
													)}
												</Draggable>
											)}
											{provided.placeholder}
										</StyledMatchCardTarget>
									)}
								</Droppable>
							);
						})}
					</StyledMatchContainer>
				)}

                <StyledSeparatorLine></StyledSeparatorLine>

                <StyledMatchContainer>
                    {cards && cards.map((card, index) => {
                                return (
                                    <Droppable  key={"match" + index} droppableId={"origin-" + index}>
                                        {(provided) => (
                                            <StyledMatchCardOption
                                            isSelected={optionSelected === ("origin-" + index)}
                                            {...provided.droppableProps} 
                                            ref={provided.innerRef}
                                            
                                            >
                                                <Draggable
                                                    draggableId={(index + 10).toString()}
                                                    key={card.id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div className="match_card_text_container"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div className="match_card_text">
                                                                <p aria-labelledby={card.title}>{card.title}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                                {provided.placeholder}
                                            </StyledMatchCardOption>
                                        )}
                                    </Droppable>
                                );
                    
                    })}
                </StyledMatchContainer>

			</DragDropContext>
                </XEduActivityContainer>

		</StyledActivityContent>

	</StyledActivityContainer>
	);
};

export default Match;
