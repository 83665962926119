import { DefaultThemeWeb } from "./DefaultThemeWeb"
import { ITheme } from "./types"

export const Mhe2023ThemeWeb: ITheme = {
    ...DefaultThemeWeb,
    themeType: 'v2',
    header: {
        display: true,
        color: 'white',
        fontSize: '0.6em',
        fontWeight: 'bold'
    },
    topBar: {
        ...DefaultThemeWeb.topBar,
        croos: {
            display: false
        },
        direction: "column",
        progressDetails: {
            fontSize: '12px',
            activityTextColor: '#E21A23',
            activityCounterColor: '#666666',
            hasTitle: true,
            hasCounter: true,
            hasSlashBetweenCounter: true
        },
        progressBar:{
            color: '#E21A23'
        }
    },
    color: {
        primary: {
            main: '#06235B',
            contrastText: "white",
            dark: '#163250'
        },
        secondary: {
            main: "#E21A23",
            contrastText: "white"
        },
        background: {
            main: "white",
            paper: "rgb(242, 242, 242)"
        }
    },
    styles:{
        ...DefaultThemeWeb.styles,
        previewContainerBackgroundColor: '#F7F9FA',
        text:{
            ...DefaultThemeWeb.styles.text,
            subtitle: {
                color: '#000000',
                size: '1em',
                fontFamily: 'Proxima Nova',
                fontWeight: '400',
                lineHeight: '20px',
                addMargin: false
            },
            title: {
                color: '#0A223D',
                size: "1.2em",
                lineHeight: '24px',
                fontWeight: '700',
                fontFamily: 'Proxima Nova',
                addPadding: false
            }
        },
        activityContent:{
            backgroundColor: "transparent",
            borderRadius: "0",
            border: "none"
        }
    },
    bottomBar: {
        ...DefaultThemeWeb.bottomBar,
        
        button:{
            backgroundColorEnabled: '#06235B',
            backgroundColorDisabled: 'transparent',
            colorEnabled: 'white',
            colorDisabled: '#06235B',
            useFeedbackBackgroundColor: false,
            backgroundColor: 'secondary',
            border: '1px solid #06235B',
            width: 'auto',
            borderRadius: '70px',
            opacityDisabled: '0.7',
            fontSize: '1em',
            fontWeight: '600',
            lineHeight: '19px',
        },
        feedback:{
            ...DefaultThemeWeb.bottomBar.feedback,
            backgroundColor: '#F7F9FA',
            borderRadius: '50px',
            flexDirection: 'row',
            title:{
                color: "black",
                fontSize: "1.4em",
                fontWeight: "700",
                lineHeight: '19px',
            },
            subtitle:{
                color: "grey",
                fontSize: "1.2em",
            },
            good: '#63d1a98f',
            bad: '#fc616c99',
            partialy: '#fc616c99'
        },
        useFeedbackImage: false,
        useFeedbackBar: true,
    },
    endUserAssessment:{
        ...DefaultThemeWeb.endUserAssessment,
        flexPosition: 'center',
        useRandomFeedbackImages: true,
        hasTryAgainButton: true,
        circularProgressBar:{
            imageInsideProgressBar: false,
            circularSize: '159px',
            textFont: 'Proxima Nova',
            textSize: '1em',
            textColor: '#737373',
            accurateSize: '39.75px',
            accurateFont: 'Proxima Nova',
            accurateFontWeight: '700',
            accurateColor: '#0A0A0A',
        },
        title:{
            fontSize: "1.5em",
            fontWeight: '700',
            fontFamily: 'Proxima Nova',
            align: 'center'

        },
        percentage: {
            fontSize: "1.4em"
        },
        good:"#63D1A9",
        bad: "#B71622",
        partialy: "#FECE51"
    },
    themeActivities:{
        ...DefaultThemeWeb.themeActivities,
        multipleChoice: {
            option: {
                hasMarginHorizontal: true,
                fontFamily: "Proxima Nova",
                borderRadius: "10px",
                fontSize: "1em",
                border: '0px',
                background: "transparent",
                color: "white",
                point: {
                    size: "19px",
                    type: "check",
                    borderRadius: "4px",
                    innerBallSize: '100%',
                    color: "#06235B",
                    colorCheck: "white",
                    border: "2px solid #06235B",
                    innerBallBorderRadius: "4px"
                }
            }
        },
        singleChoice: {
            option: {
                hasMarginHorizontal: true,
                fontFamily: "Proxima Nova",
                background: "transparent",
                color: "#06235B",
                fontSize: "1em",
                borderRadius: "10px",
                border: '0px',
                fontWeightHover: '600',
                point: {
                    size: "25px",
                    type: "filling",
                    borderRadius: "50%",
                    innerBallSize: '16px',
                    color: "#06235B",
                    border: "2px solid #909090",
                    innerBallBorderRadius: "50%",
                    borderSelected: "2px solid #06235B"
                }
            }
        },
        trueFalse: {
            text: {
                color: "#000000",
                fontFamily: "Proxima Nova",
                fontSize: "1em",
                fontWeight: "400",
                backgroundColor: "white",
                fullwidth: false,
            },
            option: {
                useButtons: true,
                fontFamily: "Proxima Nova",
                fontSize: "1em",
                color: "#06235B",
                borderRadius: "10px",
                border: '0px',
            }
        },
        imageChoice: {
            option:{
                labelBorder: "solid #e0e0e0 3px",
                labelSelectedBorder: "red dashed 3px",
            }
        },
        textChoice:{
            option:{
                labelBorder: "solid #e0e0e0 3px",
                labelSelectedBorder: "red dashed 3px",
            }
        },
        imagePairing: {
            optionsBorder: "4px solid #e1e1e1",
            optionsSelected: "1px solid #efefef",
        },
        textPairing: {
            optionsBorder: "4px solid #e1e1e1",
            optionsSelected: "1px solid #efefef",
        },
        cardChoice: {},
        froggyJump: {},
        flashCards: {
            normalCard: {
                fontFamily: "Proxima Nova",
                border: '1px solid #ececec',
                backgroundColor: '#FFFFFF',
                title:{
                    color: '#385985',
                    fontSize: '25px',
                    fontWeight: 'bold',
                },
                subTitle:{
                    color: '#385985',
                    fontSize: '18px',
                    fontWeight: '400',
                }
            },
            initialCard: {
                fontFamily: "Proxima Nova",
                color: '#385985',
                fontSize: '18px',
                fontWeight: '400',
                backgroundColor: '#FFFFFF',
            },
            finalCard:{
                fontFamily: "Proxima Nova",
                color: '#9f9f9f',
                fontSize: '18px',
                fontWeight: '400',
                backgroundColor: '#fafafa'
            }
        },
        wordSearch: {
            titilesWithActivityInfoWeb: true,
            subtitlesWithActivityInfoWeb: true,
            webDirection: "row-reverse",
            wordColorSelector: 'rgba(6, 35, 91, 0.2)',
            wordFindedBackgroundColor: '#06235B',
            wordFindedColor: '#FFFFFF',
            wrongColorFade: '#E21A23',
            timer:{
                useIcon: true,
                backgroundColor: 'rgba(217, 229, 252, 1)',
                color: '#000000',
                fontSize: '20px',
                borderRadius: '4px',
            },
            wordCounter:{
                useWordCounter: true,
                text: {
                    color: '#000000',
                    fontSize: "14px",
                    fontFamily: "Proxima Nova",
                    fontWeight: "600",
                    lineHeight: '20px',
                },
                counter:{
                    color: '#000000',
                    fontSize: "15px",
                    fontFamily: "Proxima Nova",
                    fontWeight: "400",
                    lineHeight: '15px',
                    backgroundColor: '#E5E5E5',
                    backgroundColorWordFinded: '#D9E5FC',
                    borderRadius: '54px'
                }
            }
        },
        wordCatcher: {
            timer:{
                backgroundColor: 'rgb(0,126,123)',
                color: 'white',
                fontSize: '20px',
                borderRadius: '15px',
            },
            marker:{
                findedWordColor: '#006B6C',
                notFindedWordColor: '#F58000'
            }
        },
        seekAndFind: {
            titilesWithActivityInfoWeb: true,
            subtitlesWithActivityInfoWeb: true,
            webDirection: "row-reverse",
            timer:{
                useIcon: true,
                backgroundColor: 'rgba(217, 229, 252, 1)',
                color: '#000000',
                fontSize: '20px',
                borderRadius: '4px',
            },
        },
        fillTheBlanks: {
            input:{
                backgroundColor: 'transparent',
                color: '#06235B',
                fontFamily: 'Proxima Nova',
                borderBottom: '#909090',
                fontSize: '1em',
                maxWidth: '240px',
                fontWeight: '600',
            },
            span: {
                color: '#000000',
                fontFamily: 'Proxima Nova',
                fontSize: '1em',
                fontWeight: '400'
            },
            paragraph: {
                padding: '1em 1em 2em 1em',
                backgroundColor: 'white',
                borderRadius: '8.08px'
            }
        },
        input: {
            text: {
                color: "#000000",
                fontFamily: "Proxima Nova",
                fontSize: "1em",
                fontWeight: "400",
                backgroundColor: "white",
                fullwidth: false,
            },
            input: {
                border: "1px solid #D9D9D9",
                borderOnFocus: "1px solid #06235B",
                borderRadius: "8.08px",
                color: "#06235B",
                fontFamily: "Proxima Nova",
                fontSize: "1em",
                fontWeight: "400",
                direction: 'column',
                height: "122px",
                hasMargin: true
            },
            label:{
                hasLabel: true,
                color: "#000000",
                fontSize: "1em",
                fontFamily: "Proxima Nova",
                fontWeight: "600"
            },
            wordCounter:{
                show: false,
                color: "#9E9E9E",
                fontSize: "1em",
                fontFamily: "Proxima Nova",
                fontWeight: "400"
            }
        },
        opinionScale: {
            backgroundColor: '#f2f2f2',
            backgroundRadius: '8px',
            scaleSentence: {
                color: '#D5D5D5',
                fontFamily: 'PoppinsMedium',
                fontSize: '1.5em'    
            },
            scaleValueSelected:{
                color: '#818a96',
                fontFamily: 'PoppinsRegular',
                fontSize: '1.5em'    
            }
        },
        correspondence: {
            webDirection: 'row',
            steps: {
                showDotStepper: true,
                color: "#06235B",
                width: "6px",
                height: "6px",
            },
            cards:{
                backgroundColor: '#FFFFFF',
                color: "#000000",
                fontSize: "0.8em",
                fontFamily: "Proxima Nova",
                fontWeight: "600",
                lineHeight: '22.61px',
                separation: "8",
                shadow: "0px 0px 15px 0px #0000000D",
                border: '1px solid transparent',
                borderRadius: '11.9px'
            },
            options:{ 
                width: '253px',
                height: '45px',
                backgroundColor: '#FFFFFF',
                color: "#06235B",
                fontSize: "1em",
                fontFamily: "Proxima Nova",
                fontWeight: "400",
                lineHeight: '19px',
                border: '1px solid #06235B',
                borderRadius: '70px',
                backgroundColorHover: '#06235B',
                colorHover: '#FFFFFF'
            },
            
        },
        deduction: {
            option:{
                background: '#ECF2F5',
                color: '#000000',
                fontSize: "1.2em",
                fontFamily: "Proxima Nova",
                fontWeight: "400",
                lineHeight: '19px',
                backgroundOnDrag: '#06235B',
                colorOnDrag: 'white'
            },
            optionInput:{ 
                allBorders: true,
                border: '1px dashed #8C8C8C',
                backgroundOption: '#06235B',
                colorOption: 'white',
                backgroundHoverOnDrop: '#D9E5FC'
            },
            text: {
                color: '#000000',
                fontSize: "1.2em",
                fontFamily: "Proxima Nova",
                fontWeight: "400",
                lineHeight: '19px',
            },    
            containerBg: '#fbfbfb',
            containerBoxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
            containerBorder: 'unset',
            containerRadius: '1rem',
            lineSeparator: {
                useLine: true,
                color: '#CCCCCC'
            }
        },
        orderChronology: {
            webDirection: 'row-reverse',
            cardWidthMobile: '7em',
            cardHeightMobile: '150px',
            cardWidthWeb: '100%',
            cardHeightWeb: '120px',
            cardRadius: '8px',
            showTargetOnSelect: false,
            option:{
                size: '1em',
                fontFamily: 'Proxima Nova',
                fontWeight: '400',
                lineHeight: '19px',
                color: '#000000',
                border: '2px dashed #8C8C8C',
                backgroundColor: '#ECF2F5',
                colorOnSelect: 'white',
                backgroundColorOnSelect: '#06235B',
                backgroundColorEmptyCardSelected: 'transparent',
                borderEmptyCardSelected: '2px dashed #E5E5E5',
            },
            target:{
                border: '2px dashed #8C8C8C',
                backgroundColorOnDrop: '#AFCBFF',
                backgroundColorOnSelect: 'transparent',
                backgroundColor: 'transparent',
                colorOnSelect: '#8C8C8C',
                color: '#8C8C8C',
                size: '1em',
                fontFamily: 'Proxima Nova',
                fontWeight: '400',
                lineHeight: '19px',
            },
            lineSeparator: {
                useLine: true,
                color: '#CCCCCC'
            }
        },
        shortQuestion: {
            option: {
                colorSelected: 'white',
                background: "transparent",
                backgroundSelected: "background",
                color: "primary",
                fontSize: "1.2em",
                borderRadius: "8px",
                border: '0px',
            }
        },
        match:{
            cardWidth: '7em',
            cardHeight: '150px',
            cardRadius: '8px',
            showTargetOnSelect: false,
            option:{
                size: '0.8em',
                fontFamily: 'Proxima Nova',
                fontWeight: '400',
                lineHeight: '19px',
                color: '#000000',
                border: '2px dashed #8C8C8C',
                backgroundColor: '#ECF2F5',
                colorOnSelect: 'white',
                backgroundColorEmptyCardSelected: '#06235B',
                backgroundColorOnSelect: '#06235B',
                borderEmptyCardSelected: '2px dashed #E5E5E5',
            },
            target:{
                border: '2px dashed #8C8C8C',
                backgroundColorOnDrop: '#AFCBFF',
                backgroundColorOnSelect: '#06235B',
                backgroundColor: 'transparent',
                colorOnSelect: 'white',
                color: '#9E9E9E',
                size: '0.8em',
                fontFamily: 'Proxima Nova',
                fontWeight: '400',
                lineHeight: '19px',
            },
            lineSeparator: {
                useLine: true,
                color: '#CCCCCC'
            }
        }
    }
}
