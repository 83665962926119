import React, { useState, useEffect } from "react";
import DOMPurify from "isomorphic-dompurify";
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, XEduActivityContainer } from "../XEduActivitiesStyles";
import { StyledFillTheBlanksInput, StyledFillTheBlanksText , StyledFillTheBlanksContainer, StyledParagraph, StyledCounterCharacter} from "./FillTheBlanksStyles";
import { useTranslation } from "react-i18next";

const FillTheBlanks = ({ activity, setStateMicrogame, client, ...props }) => {
	const [paragraphs, setParagraphs] = useState(undefined);
    const [blanks, setBlanks] = useState(undefined);
	const [answers, setAnswers] = useState("");
	const [_t] = useTranslation("kakuma_activities");
	useEffect(() => {
		initActivity();
	}, [activity.id]);

	const initActivity = () => {
		const originalSentence = activity?.text 
		let matches = []

		// Split paragraphs from text
		const regexParagraphs = /<p>(.*?)<\/p>/g;
		matches = Array.from(originalSentence.matchAll(regexParagraphs));
		let hasOnlyOne
		
		// No matches == text without tag <p></p>, so we add the tag
		if(matches.length === 0) {
			const text = `<p>${activity.text}</p>`
			matches[0] = text;
			hasOnlyOne = true
		}else{
			hasOnlyOne = false
		}

		
		// Build paragraph with placeholder (input text)
		const initParagraph = matches.map((match) => {
            const mergedParagraph = [];
            
			const innerHTML = hasOnlyOne ? match : match[1];
            const parts = innerHTML.split(/(\{[\d]+\})/);
    
            parts.forEach((part) => {
                if (part.startsWith("{") && part.endsWith("}")) {
                const placeholder = parseInt(part.slice(1, -1));
                if (!isNaN(placeholder)) {
                    mergedParagraph.push({ placeholder });
                }
                } else {
                    mergedParagraph.push({ text: part });
                }
            });
            
            return mergedParagraph;
        });

        setParagraphs(initParagraph);
    
        const blankOrder = initParagraph?.flatMap(arr => {
            return arr.reduce((result, item) => {
              if (item.placeholder) {
                result.push({ "placeholder": item.placeholder });
              }
              return result;
            }, []);
        });
        setBlanks(blankOrder);

		const initAnswers = new Array(blankOrder.length).fill(undefined);
		setAnswers(initAnswers);
	};

	const saveStudentAnswer = (e, placeholderIndex) => {
		const updatedAnswers = [...answers];
		const studentAnswer = e.target.value;

		// Update answer written by the student
		updatedAnswers[placeholderIndex] = studentAnswer ? checkValidWord(studentAnswer, placeholderIndex) : undefined;
		setAnswers(updatedAnswers);

		// Check if all blanks have been filled before sending out the answers
		const hasAnsweredAll = updatedAnswers?.filter(answer => (!answer))?.length === 0;
		if (hasAnsweredAll) setStateMicrogame(updatedAnswers);
	};

	const checkValidWord = (studentAnswer, placeholderIndex) => {
		const placeholderValue = blanks[placeholderIndex].placeholder;

		const correctAnswer = activity?.targets?.find((target) => target?.order === placeholderValue);
		// const wordFound = correctAnswer?.accepted_values?.find((word) => word.toUpperCase().trim() === studentAnswer.toUpperCase().trim());

		const validity = { 
			// ...(wordFound && {...correctAnswer}), 
			order: placeholderValue,
			id: correctAnswer.id,
			accepted_values: [studentAnswer],
			// placeholder: placeholderValue, 
			// is_correct: wordFound ? true : false
		};

		return validity;
	};

	return (
		<StyledActivityContainer>
			{activity?.title && 
				<StyledActivityHeader>
					<h2 
					 	tabIndex={0}
					 	aria-labelledby={`${activity.title}`}
						dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}
					/>
					
				</StyledActivityHeader>
			}

			<StyledActivityContent>
			<XEduActivityContainer style={{backgroundColor:'white'}}>
			{paragraphs && <>
					{paragraphs.map((paragraph, index) => {
						return(
							<StyledParagraph key={'paragraph' + index} channel={props.channel}>
									{paragraph.map((fragment, fragmentIndex) => {
										if (fragment.hasOwnProperty('text')) {
											return (
												<StyledFillTheBlanksText 
													key={'paragraph-' + index + ' fragment-' + fragmentIndex} 
													dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fragment?.text) }} 
												/>
											)
										} else if (fragment.hasOwnProperty('placeholder')) {
											const placeholderIndex = blanks.findIndex(item => item?.placeholder === fragment?.placeholder)
											return (
												<StyledFillTheBlanksInput
													onChange={(e) => saveStudentAnswer(e, placeholderIndex)}
													key={placeholderIndex}
													type='text'
													value={answers?.[placeholderIndex]?.accepted_values[0] ?? ''}
													id={`fillBlanks-${placeholderIndex}`}
													aria-label={`${_t("fill_the_blanks.aria_label_option")} ${placeholderIndex + 1}`}
													aria-describedby={`instruction-${placeholderIndex}`}
												/>
											)
										}
										return null;
									})}
				</StyledParagraph>
						)
					})}
					</>
			}
			</XEduActivityContainer>
			</StyledActivityContent>
		</StyledActivityContainer>
	);
};

export default FillTheBlanks;
