import { default as Tools } from './Tools';
class WordSearchClass {
    ctx;
    config;

    letters;
    ///

    //Mouse
    isLastClicked = false;

    mouse = {
        x: 0,
        y: 0,
        click: false,
        enter: false
    };

    /////////
    wordsSelected = [];
    wordsSelectedFiled = [];
    stringWordsSelected = [];
    wordsToSearch = [];
    findedWordColor = "";
    findedWordColorBackground = "";
    wordColorSelector = ""
    wrongWordColorfade = ""


    //Functions to operate component father
    drawLettersSearchedFuncion = null;
    gameEndFunction = null;


    //Boxe Settings
    box = {
        size: 0,
        center: 0
    }

    //Boxes
    actualBox = {
        y: null,
        x: null
    }

    lastBoxSelected = {
        y: null,
        x: null
    }

    lastBoxClicked = {
        y: null,
        x: null
    }

    frameCount = 0;

    gameEnd = false;

    constructor(ctx, config = { width: 500, height: 500, size: 15 }, wordsToSearch = [], gameEndFunction, findedWordColorBackground, findedWordColor, wordColorSelector, wrongWordColorfade) {
        this.ctx = ctx;
        this.config = config;
        this.box.size = config.width / config.size;
        this.box.center = (config.width / config.size) / 2;
        this.wordsToSearch = wordsToSearch;
        this.gameEndFunction = gameEndFunction;
        this.findedWordColor = findedWordColor;
        this.findedWordColorBackground = findedWordColorBackground;
        this.wordColorSelector = wordColorSelector;
        this.wrongWordColorfade = wrongWordColorfade;
    }

    intertLetters(letters) {
        this.letters = letters;
    }

    drawLettersSearched = (fuc) => {
        this.drawLettersSearchedFuncion = fuc;
    }

    clearCanvas() {
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
        this.ctx.fillStyle = '#000000'
        this.ctx.beginPath()
        this.ctx.fill()

    }

    get centerBox() {
        return this.box.size / 2;
    }

    updateStatus(mouse, scaleRatio) {
        if (this.gameEnd === true) return;
        this.config.width *= scaleRatio.xRatio;
        this.config.height *= scaleRatio.yRatio;
        this.box.size = this.config.width / this.config.size;

        this.mouse = mouse;

        this.mouse.x *= scaleRatio.xRatio;
        this.mouse.y *= scaleRatio.yRatio;

        this.actualBox = Tools.coordinatesToBox(this.mouse, this.box.size);
        this.updateSelectedWord()

        //Save last Variables
        this.isLastClicked = this.mouse.click;
    }

    draw() {

        if (!this.letters) return;
        this.clearCanvas();
        this.drawWordsSelected()
        this.drawBoxSelected();
        this.drawLetters();
        this.drawLettersSearchedFuncion(this.wordsToSearch, this.stringWordsSelected)
    }

    drawBoxSelected() {
        if (this.gameEnd === true) return
        if (this.mouse.enter && !this.mouse.click) {

            //const color = this.mouse.click ?  : "#E1E1E1";
            this.ctx.fillStyle = this.wordColorSelector;
            this.ctx.globalAlpha = 0.5;

            let boxX = this.box.size * this.actualBox.x;
            let boxY = this.box.size * (this.actualBox.y);

            if (this.lastBoxSelected.x !== this.actualBox.x || this.lastBoxSelected.y !== this.actualBox.y) {
                this.frameCount = 0;

                this.lastBoxSelected = this.actualBox;
            }

            if (this.frameCount < 11) {
                this.frameCount++;
            }

            this.ctx.beginPath()
            this.ctx.arc(boxX + this.centerBox, boxY + this.centerBox, 40 * Math.sin(this.frameCount * 0.15) ** 2, 0, 2 * Math.PI)
            this.ctx.fill()
            this.ctx.globalAlpha = 1;

        } else {
            this.frameCount = 0;
        }
    }

    drawLetters() {
        if (!this.letters) return;
        this.ctx.font = "bold 50px sans-serif";
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = 'middle';
        this.ctx.fillStyle = "#000000";
        for (let y = 0; y < this.letters.length; y++) {
            let row = this.letters[y];
            for (let x = 0; x < row.length; x++) {
                let box = row[x]
                let coordinates = Tools.boxToCoordinates(box, this.config);
                this.ctx.fillText(box.letter, coordinates.x + this.centerBox, coordinates.y + this.centerBox);
            }
        }
    }

    updateSelectedWord() {
        if (this.mouse.click) {
            if (this.mouse.enter && !this.isLastClicked && (this.lastBoxClicked.x !== this.actualBox.x || this.lastBoxClicked.y !== this.actualBox.y)) {
                this.lastBoxClicked = this.actualBox;
            }
        } else {
            if (this.isLastClicked) {
                let word = {
                    fistLetter: this.lastBoxClicked,
                    lastLetter: this.actualBox
                }

                if (Tools.isPosibleSelected(word) && (word.fistLetter.x !== word.lastLetter.x || word.fistLetter.y !== word.lastLetter.y)) {
                    let stringWord = this.isAWord(word)
                    if (stringWord) {
                        if (!this.stringWordsSelected.includes(stringWord.toLowerCase())) {
                            this.stringWordsSelected.push(stringWord.toLowerCase());
                            this.wordsSelected.push(word);
                            //We check that if all the words have been found
                            if (this.stringWordsSelected.length === this.wordsToSearch.length) {
                                this.gameEnd = true;
                                this.gameEndFunction();
                            }
                        }
                    } else {
                        this.wordsSelectedFiled.push({ word, opacity: 0.8 })
                    }
                }
            }
            this.lastBoxClicked = {
                y: null,
                x: null
            };
        }
    }

    isAWord(word) {
        let { fistLetter, lastLetter } = word;
        let stringWord = "";
        if (lastLetter.y < this.config.size && lastLetter.x < this.config.size && lastLetter.y >= 0 && lastLetter.x >= 0) {
            if (fistLetter.y === lastLetter.y) {
                if (fistLetter.x < lastLetter.x) {
                    for (let x = fistLetter.x; x <= lastLetter.x; x++) {
                        stringWord += this.letters[fistLetter.y][x].letter;
                    }
                } else {
                    for (let x = lastLetter.x; x <= fistLetter.x; x++) {
                        stringWord += this.letters[fistLetter.y][x].letter;
                    }
                }
            } else if (fistLetter.x === lastLetter.x) {
                if (fistLetter.y < lastLetter.y) {
                    for (let y = fistLetter.y; y <= lastLetter.y; y++) {
                        stringWord += this.letters[y][lastLetter.x].letter
                    }
                } else {
                    for (let y = lastLetter.y; y <= fistLetter.y; y++) {
                        stringWord += this.letters[y][lastLetter.x].letter;
                    }
                }
            } else {
                let distanceX = lastLetter.x - fistLetter.x;

                if (fistLetter.x < lastLetter.x && fistLetter.y < lastLetter.y) {
                    //( X+ Y+ )
                    for (let x = 0; x <= distanceX; x++) {
                        stringWord += this.letters[fistLetter.y + x][fistLetter.x + x].letter
                    }
                } else if (fistLetter.x < lastLetter.x && fistLetter.y > lastLetter.y) {
                    //( X+ Y- )
                    for (let distance = 0; distance <= distanceX; distance++) {
                        stringWord += this.letters[fistLetter.y - distance][fistLetter.x + distance].letter
                    }
                } else if (fistLetter.x > lastLetter.x && fistLetter.y > lastLetter.y) {
                    //( X- Y- )
                    for (let distance = 0; distance <= Math.abs(distanceX); distance++) {
                        stringWord += this.letters[fistLetter.y - distance][fistLetter.x - distance].letter
                    }
                } else if (fistLetter.x > lastLetter.x && fistLetter.y < lastLetter.y) {
                    //( X- Y+ )
                    for (let distance = 0; distance <= Math.abs(distanceX); distance++) {
                        stringWord += this.letters[fistLetter.y + distance][fistLetter.x - distance].letter
                    }
                }
            }

            if (stringWord.length > 0) {
                //We check that the selected word is correct
                let reverseWord = "";
                let x = stringWord.length;
                while (x >= 0) {
                    reverseWord = reverseWord + stringWord.charAt(x);
                    x--;
                }
                if (this.wordsToSearch.includes(stringWord.toLowerCase())) {
                    return stringWord;
                } else if (this.wordsToSearch.includes(reverseWord.toLowerCase())) {
                    return reverseWord;
                } else {
                    return null
                }
            }

        }
        return null;
    }

    drawWordsSelected() {
        //We draw already searched words
        this.ctx.strokeStyle = this.findedWordColorBackground;

        //fillStyle not working (?)
        this.ctx.fillStyle = this.findedWordColor;
        
        this.ctx.globalAlpha = 0.8;
        this.ctx.lineCap = "round";


        for (let i = 0; i < this.wordsSelected.length; i++) {
            let word = this.wordsSelected[i];
            let coordinatesFistLetter = Tools.boxToCoordinates(word.fistLetter, this.config);
            let coordinatesLastLetter = Tools.boxToCoordinates(word.lastLetter, this.config);

            this.drawLine(
                { x: coordinatesFistLetter.x + this.centerBox, y: coordinatesFistLetter.y + this.centerBox },
                { x: coordinatesLastLetter.x + this.centerBox, y: coordinatesLastLetter.y + this.centerBox });
        }

        //We draw the words that are wrong as they fade

        this.ctx.strokeStyle = this.wrongWordColorfade;
        for (let i = 0; i < this.wordsSelectedFiled.length; i++) {

            let { word } = this.wordsSelectedFiled[i];

            let coordinatesFistLetter = Tools.boxToCoordinates(word.fistLetter, this.config);
            let coordinatesLastLetter = Tools.boxToCoordinates(word.lastLetter, this.config);

            this.wordsSelectedFiled[i].opacity -= 0.04;

            if (this.wordsSelectedFiled[i].opacity < 0) {
                this.wordsSelectedFiled.splice(i, 1);
            } else {
                this.ctx.globalAlpha = this.wordsSelectedFiled[i].opacity;

                this.drawLine(
                    { x: coordinatesFistLetter.x + this.centerBox, y: coordinatesFistLetter.y + this.centerBox },
                    { x: coordinatesLastLetter.x + this.centerBox, y: coordinatesLastLetter.y + this.centerBox });
            }
        }

        //We draw the line that the user is selecting
        if (this.gameEnd === true) return
        this.ctx.strokeStyle = this.wordColorSelector;
        this.ctx.globalAlpha = 0.8;
        this.ctx.lineWidth = 75;
        this.ctx.lineCap = "round";

        if (this.lastBoxClicked && this.mouse.click) {
            let coordinatesFistLetter = Tools.boxToCoordinates(this.lastBoxClicked, this.config);
            let isPosibleSelected = Tools.isPosibleSelected(
                {
                    fistLetter: this.lastBoxClicked,
                    lastLetter: this.actualBox
                });

            let coordinatesLastLetter = null;
            if (isPosibleSelected) {
                coordinatesLastLetter = Tools.boxToCoordinates(this.actualBox, this.config)
                coordinatesLastLetter = {
                    x: coordinatesLastLetter.x + this.centerBox,
                    y: coordinatesLastLetter.y + this.centerBox
                }
            } else {
                coordinatesLastLetter = {
                    x: this.mouse.x,
                    y: this.mouse.y
                }
            }
            this.drawLine(
                {
                    x: coordinatesFistLetter.x + this.centerBox,
                    y: coordinatesFistLetter.y + this.centerBox
                },
                coordinatesLastLetter
            );
        }

        this.ctx.globalAlpha = 1;
    }

    drawLine(firstPoint = { x: 0, y: 0 }, secondPoint = { x: 0, y: 0 }) {
        this.ctx.lineWidth = 75;
        this.ctx.beginPath();
        this.ctx.moveTo(firstPoint.x, firstPoint.y);
        this.ctx.lineTo(secondPoint.x, secondPoint.y);
        this.ctx.stroke();
        
    }
}

export default WordSearchClass;
