import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { preview_cat, preview_en, preview_es, preview_uk, preview_gal, preview_eus } from './i18next';

const i18nCustom = i18next.createInstance({
    debug: false,
    supportedLngs: ['ca', 'en', 'es', 'eu', 'gl', 'uk'],
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    resources: {
      "en": preview_en,
      "es": preview_es,
      "ca": preview_cat,
      "eu": preview_eus,
      "gl": preview_gal,
      "uk": preview_uk,
    }
  })
i18nCustom.init()

export const useCustomTranslations = (lang= 'en', namespace = '', opts = {}) => {

    const { i18n: _i18n } = useTranslation(namespace, {...opts, i18n: i18nCustom });
  
    useEffect(() => {
        _i18n.changeLanguage(lang)
    }, [lang, _i18n]);
  
    return {i18n: _i18n}
  }
