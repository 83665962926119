import React, { useContext, useEffect, useState } from 'react';
import { StyledBallSelected, StyledContainerInput } from './ActivityOptionChoiceStyles';
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader } from '../XEduActivitiesStyles';
import DOMPurify from 'isomorphic-dompurify';
import { ThemeContext } from 'styled-components';
import OptionsWithButtons from './Types/OptionsWithButtons';
import OptionsChoices from './Types/OptionsChoices';
import { useTranslation } from 'react-i18next';


const ActivityOptionChoice = ({ setStateMicrogame, activity, activityType, client, channel }) => {
    const [answers, setAnswers] = useState([]);
    const typeActivity = activityType === "single-choice" ? "singleChoice" : activityType === "multiple-choice" ? "multipleChoice" : "trueFalse";
    const theme = useContext(ThemeContext);
    const useButtons = theme.themeActivities[typeActivity].option?.useButtons ?? false;
    const [_t] = useTranslation("kakuma_activities");
    
    useEffect(() => {
        setStateMicrogame && setStateMicrogame(activity.options.filter((_, index) => answers.includes(index)))
    }, [answers])

    const handleOnChange = (e, index) => {
        let answersCopy = [...answers]
        if (activity.type === "single-choice" || activity.type === "true-false") {
            answersCopy = []
            answersCopy.push(index)

        } else if (activity.type === "multiple-choice") {
            if (answersCopy.includes(index)) {
                answersCopy = answersCopy.filter(e => e !== index)
            } else {
                answersCopy.push(index)
            }
        }
        setAnswers(answersCopy)
    }


    return (
        <StyledActivityContainer>
			{activity?.title && <StyledActivityHeader>
                <h2 
                    aria-labelledby={`${activity.title}`}
                    tabIndex={0} 
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}
                />
            </StyledActivityHeader>
            }

            {useButtons ? 
                <OptionsWithButtons
                    client={client}
                    activity={activity}
                    typeActivity={typeActivity}
                    handleOnChange={handleOnChange}
                    answers={answers}
                    channel={channel}
                />
            :
                <OptionsChoices
                    client={client}
                    activity={activity}
                    typeActivity={typeActivity}
                    handleOnChange={handleOnChange}
                    answers={answers}
                    channel={channel}
                />
            }
       
        </StyledActivityContainer>
    );
}

export default ActivityOptionChoice;
