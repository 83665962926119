import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, XEduActivityContainer } from "../XEduActivitiesStyles";
import DOMPurify from "isomorphic-dompurify";
import {StyledCardsContainer, StyledOrderChronologyCardOption, StyledOrderChronologyCardTarget, StyledOrderChronologyContainer, StyledOrderChronologyCardInfoContainer } from "./OrderChronolgyStyles";
import useParseUserAnswer from "../../../hooks/useParseUserAnswer";
import { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { useAccessibility } from "../../../hooks/useAccesibility";


const OrderChronology = ({activity, client, setStateMicrogame, getLocalPath, courseCode, channel, ...props}) => {
	const [answer, setAnswer] = useState([])
	const [cards, setCards] = useState([]);
    const [cardsCopy, setCardsCopy] = useState([]);
	const [cardsInTarget, setCardsInTarget] = useState([]);
	const [emptyTargets, setEmptyTargets] = useState([]);
    const [optionSelected, setOptionSelected] = useState(undefined)
	const [cardimgPath, setImgPath] = useState({})
    const {parseAnswer} = useParseUserAnswer();
    const [_t] = useTranslation("kakuma_activities");
    const sanitizeHtml = useAccessibility()

    const shuffleOptions = () => {
        const shuffledOptions = activity?.options?.sort(() => Math.random() - 0.5);
        return(shuffledOptions);
	};

	const getImagePath = async (cards) => {
		let pathsState = {...cardimgPath}
		if(getLocalPath){
			const paths = await Promise.all(cards.map(card => getLocalPath(card.image, courseCode)))
			paths.forEach((path) => {
				pathsState[path.image] = path
			})
		}else{
			cards.forEach((card) => {
				pathsState[card.image] = card.image_url
			})
		}
		setImgPath(pathsState)
	}

	useEffect(()=> {
		if (cards?.length > 0) {
			getImagePath(cards)
		}
	}, [cards])

    
	useEffect(() => {
		setStateMicrogame(parseAnswer(answer));
	}, [answer]);

	useEffect(() => {
		setCards(shuffleOptions(activity.options));
		setCardsInTarget(activity?.targets?.sort((a, b) => a.order - b.order));
		setEmptyTargets(activity?.targets?.sort((a, b) => a.order - b.order));
	}, [activity]);


	const handleOnDragEnd = (result) => {
        setOptionSelected(undefined)
        if (!result.destination || !result.destination?.droppableId.includes("target")) return null;
		if (result.source.droppableId === result.destination?.droppableId && result.source.index === result.destination.index) return null;
        let draggedItem;
		const newCards = [...cards];
		let answerCopy = [...answer]
		let cardstemplateCopy = [...cardsCopy];
		const destinationIndex = result.destination.droppableId.split("-")[1];
        const targetSelected = emptyTargets[destinationIndex]


		if (result.source.droppableId.includes("target")) {
			draggedItem = cardstemplateCopy[result.source.index];
			cardstemplateCopy[result.source.index] = cardstemplateCopy[destinationIndex];
			cardstemplateCopy[destinationIndex] = draggedItem
			let index = answerCopy.findIndex(element => element.option.id === draggedItem.id)
			if(index !== -1) answerCopy[index].target = targetSelected
		} else {
			draggedItem = cards[result.source.index];
            if(cardstemplateCopy[destinationIndex]) newCards.push(cardstemplateCopy[destinationIndex])
			cardstemplateCopy[destinationIndex] = draggedItem;
			newCards.splice(result.source.index, 1);
			answerCopy.push({
				option: draggedItem,
				target: targetSelected
			})
		}

        setCardsCopy(cardstemplateCopy);
		setCards(newCards);
		setAnswer(answerCopy)
	};

    const handleOnDragStart = (result) => {
        setOptionSelected(result.source.droppableId)
    }

	return (
	<StyledActivityContainer>

		{activity?.title && <StyledActivityHeader>
            <h2
                aria-labelledby={`${activity.title}`}
                tabIndex={0}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}/
            ></StyledActivityHeader>
        }

		<StyledActivityContent client={client}>
        <XEduActivityContainer>
			{activity?.text && 
                <>
                <p 
                    className='subtitle'
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }}
                    aria-labelledby={`${sanitizeHtml(activity?.text)}`}    
                />
                </>
                    }
        <StyledCardsContainer channel={channel}>
			<DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart} >
				{cardsInTarget && (
					<StyledOrderChronologyContainer channel={channel} themetype={props.themeType}>
						{cardsInTarget.map((card, index) => {
							return (
								<Droppable key={"chronologyTarget" + index} droppableId={"target-" + index}>
									{(provided) => (
										<StyledOrderChronologyCardTarget 
                                            channel={channel}
											{...provided.droppableProps}
											ref={provided.innerRef}
											className="chronologyTarget"
                                            isSelected={optionSelected ? true : false}
										>
											{cardsCopy[index] !== '' && cardsCopy[index] ? (
												<Draggable
													draggableId={index.toString()}
													key={card.id}
													index={index}
												>
													{(provided) => (
                                                        <StyledOrderChronologyCardInfoContainer
                                                            hasImage={cardsCopy[index]?.image ? true : false}
                                                            hasText={cardsCopy[index]?.title ? true : false}
                                                            channel={channel}
                                                            isInPosition={true}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            {cardimgPath?.[cardsCopy[index].image] ? <img src={cardimgPath?.[cardsCopy[index].image]}/> : <></>}
                                                            {cardsCopy[index]?.title ? <div className="card_text"><p>{cardsCopy[index].title}</p></div> : <></>}
                                                        </StyledOrderChronologyCardInfoContainer>
													)}
												</Draggable>
											)
                                            :
                                            <>
                                                <p className={"orderChronology_card_text"} aria-labelledby={`${index  + 1}`}>
                                                    {index + 1}
                                                </p>
                                            </>
                                        }

											{provided.placeholder}
										</StyledOrderChronologyCardTarget>
									)}
								</Droppable>
							);
						})}
					</StyledOrderChronologyContainer>
				)}
				{cards && (
					<StyledOrderChronologyContainer channel={channel} themetype={props.themeType}>
						{cards.map((card, index) => {
							return (
								<Droppable key={"chronology" + index} droppableId={"origin-" + index}>
									{(provided) => (
										<StyledOrderChronologyCardOption
                                            channel={channel}
											{...provided.droppableProps} 
											ref={provided.innerRef}
                                            isSelected={optionSelected === ("origin-" + index)}
										>
											<Draggable
												draggableId={(index + 10).toString()}
												key={card.id}
												index={index}
											>
												{(provided) => (
                                                    <StyledOrderChronologyCardInfoContainer
                                                        hasImage={card?.image ? true : false}
                                                        hasText={card?.title ? true : false}
                                                        channel={channel}
                                                        isSelected={optionSelected === ("origin-" + index)}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {cardimgPath?.[card.image] ? <img src={cardimgPath?.[card.image]}/> : <></>}
                                                        {card?.title ? 
                                                            <div className="card_text">
                                                                <p aria-labelledby={card.title}>{card.title}</p>
                                                            </div> : <></>}
                                                    </StyledOrderChronologyCardInfoContainer>
                                                )}
											</Draggable>
											{provided.placeholder}
										</StyledOrderChronologyCardOption>
									)}
								</Droppable>
							);
						})}
					</StyledOrderChronologyContainer>
				)}
			</DragDropContext>
            </StyledCardsContainer>
            </XEduActivityContainer>

		</StyledActivityContent>
	</StyledActivityContainer>
	);
};

export default OrderChronology;
