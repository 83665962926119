import BoxText from "./BoxText";
import Lilipad from "./Lilipad";
import { calculePositionToAngle } from "./Vector";
import froggyImage from "./../images/froggy.png"
import Froggy from "./Froggy";

const FristPosition = {
    x: 500,
    y: 770
}
const angleLilipads = 35;
const distanceLilipads = 370;
const lilipadsPerColumn = 4;
const sizeLilipad = 300;
const velocity = 7;


class LilipadsPoll {
    options = [];

    context = null;
    columns = [];
    verticalDistance = 0;
    horizontalDistance = 0;
    froggyImage = null;
    froggy = null;

    inAnimation = false;
    inMovinAnimation = false;
    inAnimationLilipad = false;
    center = 3;

    boxTexts = [];

    selectedOption = null;
    onSelectedOption = null;
    showTexts = false;

    froggyIsVisible = true;

    finishAnimation = () => {
        console.error("Error: no animation function inserted");
    }

    constructor(context, { onSelectedOption }) {
        this.context = context;
        this.onSelectedOption = onSelectedOption;
        const position = calculePositionToAngle(FristPosition, distanceLilipads, angleLilipads);
        this.verticalDistance = (position.y - FristPosition.y) * 2;
        this.horizontalDistance = (position.x - FristPosition.x);

        this.froggyImage = new Image();
        this.froggyImage.src = froggyImage;
        this.froggy = new Froggy(FristPosition, { size: 350 });
    }

    setOptions(options) {
        this.showTexts = true;
        this.options = options;
    }

    initLilipads() {
        //Center Column
        this.columns[this.center] = this.getColumn(FristPosition, 0, true);

        //Left Columns
        this.columns[this.center - 1] = this.getColumn(this.columns[3][0].getPosition(), 180 + angleLilipads);
        this.columns[this.center - 2] = this.getColumn(this.columns[2][0].getPosition(), 180 + angleLilipads);
        this.columns[this.center - 3] = this.getColumn(this.columns[1][0].getPosition(), 180 + angleLilipads);

        //Right Columns
        this.columns[this.center + 1] = this.getColumn(this.columns[3][0].getPosition(), -angleLilipads);
        this.columns[this.center + 2] = this.getColumn(this.columns[4][0].getPosition(), -angleLilipads);
        this.columns[this.center + 3] = this.getColumn(this.columns[5][0].getPosition(), -angleLilipads);

        //Box texts
        this.boxTexts[0] = new BoxText(this.columns[this.center - 1][0].getPosition());
        this.boxTexts[1] = new BoxText(this.columns[this.center][1].getPosition());
        this.boxTexts[2] = new BoxText(this.columns[this.center + 1][0].getPosition());
    }

    draw(damage) {
        this.forLilipad(element => element.draw(this.context));

        this.drawFroggy(damage);

        if (this.inAnimation === false && this.showTexts === true) {
            if (this.options !== null) {
                this.boxTexts.forEach((e, index) => {
                    if (this.options[index]) {
                        e.draw(this.context, this.options[index].title);
                    }
                })
            }
        }
    }

    drawFroggy(damage) {
        if (this.froggyIsVisible) {
            this.froggy.draw(this.context, damage)
        } else {
        }

    }

    update() {
        if (this.inAnimation) {
            if (this.inMovinAnimation) {
                this.forLilipad(element => element.update());
                const positionCenter = this.columns[this.center][0].position;
                const positionFinalCenter = this.columns[this.center][0].positionFinal;
                if (shallowEqual(positionCenter, positionFinalCenter)) {
                    this.inMovinAnimation = false;
                    this.inAnimationLilipad = true;
                }
            }

            if (this.inAnimationLilipad === true) {
                this.froggy.setAngle(0)
                this.columns = this.columns.map((column, columIndex) => {
                    const newColumn = column.filter((element, lilipadIndex) => {
                        return (element.getPosition().y < (FristPosition.y + 10));
                    });
                    return newColumn;
                })

                this.columns = this.columns.map(column => {
                    if (column.length < lilipadsPerColumn) {
                        column.push(
                            this.createLilipad(calculePositionToAngle(column[column.length - 1].position, this.verticalDistance, -90))
                        );
                    }
                    return column;
                });

                this.updateMap();
                this.inAnimationLilipad = false;
                this.inAnimation = false;
                this.onSelectedOption(this.selectedOption);
            }
        } else {

        }
    }

    isOptionSelected(mouse) {
        if (this.showTexts === false) return false;
        for (let box of this.boxTexts) {
            if (box.isWithin(mouse)) {
                return true;
            }
        }
        return false;
    }

    updateMap() {
        if ((this.columns.length - this.center) <= 2) {
            this.columns.push(
                this.getColumn(this.columns[this.columns.length - 1][0].getPosition(), -angleLilipads)
            );
            this.columns.shift();
            this.center -= 1;
        }

        if (this.center <= 2) {
            this.columns.unshift(
                this.getColumn(this.columns[0][0].getPosition(), 180 + angleLilipads)
            );
            this.columns.pop();
            this.center += 1;
        }
    }

    click = (mouse) => {
        if (this.boxTexts[0].isWithin(mouse)) {
            this.setAnimation("right");
        }

        if (this.boxTexts[1].isWithin(mouse)) {
            this.setAnimation("center");
        }

        if (this.boxTexts[2].isWithin(mouse)) {
            this.setAnimation("left");
        }
    }

    forLilipad(func = () => { }) {
        this.columns.forEach((e, column) => {
            e.forEach((element, index) => func(element, column, index));
        })
    }

    animationBlink = (number = 0) => {
        this.froggyIsVisible = false;

        const timeAnimation = 1000 / 25;
        setTimeout(() => {
            this.froggyIsVisible = true;
            if (number > 1) return;
            setTimeout(() => this.animationBlink(number + 1), timeAnimation);
        }, timeAnimation);
    }

    intervalFroggyAnimation = (number = 0, isIncorret) => {
        if (number === 7) {
            if (isIncorret === true) this.animationBlink(0);
            return;
        }
        this.froggy.nexAnimation();
        setTimeout(() => {
            this.intervalFroggyAnimation(number + 1, isIncorret)
        }, 1000 / 7);
    }

    setAnimation(animationType) {
        if (this.inMovinAnimation === true && this.inAnimation === true) return;
        let angle;
        this.inMovinAnimation = true;
        this.inAnimation = true;
        this.showTexts = false;

        switch (animationType) {
            case "right":

                this.center -= 1;
                this.froggy.setAngle(260 + angleLilipads);
                angle = angleLilipads;
                this.forLilipad(element => element.setDirection(angle, distanceLilipads));
                this.selectedOption = this.options[0];
                break;

            case "left":
                this.center += 1;
                this.froggy.setAngle(angleLilipads);
                angle = (180 - angleLilipads);
                this.forLilipad(element => element.setDirection(angle, distanceLilipads))
                this.selectedOption = this.options[2];
                break;

            case "center":
                angle = 90;
                this.forLilipad(element => element.setDirection(angle, this.verticalDistance))
                this.selectedOption = this.options[1];
                break;
        }

        this.intervalFroggyAnimation(0, this.selectedOption.is_right.toUpperCase() === "FALSE");
    }

    getColumn(position, angle, isCenter = false) {
        let column = []
        let firtsPositionLeftColumn = isCenter ? position : calculePositionToAngle(position, distanceLilipads, angle);
        for (let i = 0; i < lilipadsPerColumn; i++) {
            if (i !== 0) {
                firtsPositionLeftColumn = calculePositionToAngle(
                    firtsPositionLeftColumn,
                    this.verticalDistance,
                    -90
                );
            }
            let newLilipad = this.createLilipad(firtsPositionLeftColumn);
            column.push(newLilipad)
        }
        return column;
    }

    createLilipad(position) {
        return new Lilipad({ initialPosition: position, size: sizeLilipad, velocity: velocity });
    }
}

function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}

export default LilipadsPoll;