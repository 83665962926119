import React, {useContext, useEffect, useRef, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import Activities from './components/Activities/Activities';
import useActivities, { ACTIVITIES_STATUS } from './hooks/useActivities';
import AssessmentEnd from './components/AssessmentEnd/AssessmentEnd';
import { Loader } from './components/Loader/Loader';
import { PreviewContainer } from './layout/PreviewContainer/PreviewContainer';
import Header from './layout/Header/Header';
import ThemeContext from './providers/ThemeProvider/ThemeContext';
import i18n from './i18next/i18next'
import AssessmentNoActivities from './components/AssessmentNoActivities/AssessmentNoActivities';
import { useTranslation } from 'react-i18next';



function App() {
  const [searchParams] = useSearchParams()
  const {theme: themeActivity} = useContext(ThemeContext)
  const [DynamicFontSize, setDynamicFontSize] = useState<undefined | Number>(undefined);
  const { activity_user_id, assessment_user_id, target, channel, title=1, readSpeaker=1, theme: themeType, developerMode, activityType, lang } = Object.fromEntries(searchParams)
  const devMode = developerMode === 'true'
  const [paramLanguage, setParamLanguage] = useState<string>(lang?.split('-')[0] === 'cat' ? 'ca' : lang?.split('-')[0])
  const [
    activities,
    activityStep,
    status,
    answerCurrentActivity,
    nexActivity,
    checkAssessment,
    feedbak,
    resetAssessment,
    loadingActivityAnswer
  ] = useActivities({ activity_user_id, assessment_user_id, devMode, activityType, paramLanguage, setParamLanguage })
  const contenedorRef = useRef<HTMLDivElement>(null);
  const [language, setLanguage] = useState(undefined)
  const [_t] = useTranslation("preview")

  useEffect(() => {
    if(language || paramLanguage) i18n.changeLanguage(language ?? paramLanguage)
    document.documentElement.setAttribute('lang', language ?? paramLanguage);
  }, [language])

  // Primer mensaje para actualizar altura del iframe
  useEffect(() => {
    setTimeout(() => {
      setIframeHeight()
    }, 1000)
  }, [])

  // Envia un mensaje a la página principal para ajustar la altura del iframe
  useEffect(() => {
    setTimeout(() => {
      setIframeHeight()
    }, 100)
  }, [status]);

  const setIframeHeight = () => {
    if (contenedorRef.current && document) {
      const rootDiv = document.querySelector('#root > div');
      if (rootDiv) {
        window.parent.postMessage({ height: rootDiv.clientHeight, width: rootDiv.scrollWidth }, '*');
      }
    }
  }

  const showTitle = Number(title) !== 0
  const showReadSpeaker = Number(readSpeaker) !== 0
  const showHeader = themeActivity.header.display;
  const showLoaderPercentge = themeActivity.loaderSpinner.showPercentage;
  
  // Fonts dinamicas por post message
  useEffect(() => {
    // Función de manejo de font dinamica
    const handleWindowMessage = (event: any) => {
      
      if(event?.data?.fontSize){
        setDynamicFontSize(Number(event.data.fontSize))
      }
    };
    
    // Suscribirse al evento de mensaje de la ventana
    window.addEventListener('message', handleWindowMessage);

    // Limpiar la suscripción al desmontar el componente
    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, []); 


  // STATUS = ASSESSMENT WITHOUT ACTIVITIES
  if (status === ACTIVITIES_STATUS.ASSESSMENT_WITHOUT_ACTIVITIES)
    return (
      <PreviewContainer ref={contenedorRef} channel={channel} style={{justifyContent:'flex-start', height: '100%'}} fontSize={DynamicFontSize ?? undefined}>
        {showHeader && <Header
          showTitle={showTitle}
          showReadSpeaker={showReadSpeaker}
        />}
          <AssessmentNoActivities client={target} urlLanguage={paramLanguage}/>
      </PreviewContainer>)

  // STATUS = LOADING / STARTING ACTIVITY
  if (status === ACTIVITIES_STATUS.LOADING || status === ACTIVITIES_STATUS.STARTING_ACTIVITY)
    return (
      <PreviewContainer ref={contenedorRef} channel={channel} style={{justifyContent:'flex-start', height: '100%'}} fontSize={DynamicFontSize ?? undefined}>
        {showHeader && language && <Header
          showTitle={showTitle}
          showReadSpeaker={showReadSpeaker}
        />}
        <Loader activitiesLoaded={activities.length > 0} showPercentage={showLoaderPercentge}/>
      </PreviewContainer>)


// STATUS = ASSESSMENT EDN / ACTIVITY ALREADY DONE
  if (status === ACTIVITIES_STATUS.END || status === ACTIVITIES_STATUS.ACTIVITY_ALREADY_DONE) 
  return (
    <PreviewContainer ref={contenedorRef}  channel={channel} fontSize={DynamicFontSize ?? undefined} style={{height: '100%'}}>
      {showHeader && <Header
        showTitle={showTitle}
        showReadSpeaker={showReadSpeaker}
      />}
      <AssessmentEnd 
        feedback={feedbak ?? undefined} 
        activityStep={activityStep + 1} 
        maxSteps={activities.length} 
        assessmentUserId={assessment_user_id} 
        client={target}
        activityType={activities[activityStep - 1]?.type}
        resetAssessment={resetAssessment}
        />
    </PreviewContainer>
  )

  // STATUS = WATING ANSWER / WAITING NEXT ACTIVITY / LOADING ACTIVITY
  if (
    status === ACTIVITIES_STATUS.WAITING_ANSWER
    || status === ACTIVITIES_STATUS.WAITING_NEXT_ACTIVITY
    || status === ACTIVITIES_STATUS.LOADING_ACTIVITY
  ) {
    return (
      <PreviewContainer ref={contenedorRef} channel={channel} fontSize={DynamicFontSize ?? undefined}>
          {showHeader && <Header
            showTitle={showTitle}
            showReadSpeaker={showReadSpeaker}
          />}
          <Activities
            setLanguage={setLanguage}
            checkAssessment={checkAssessment}
            activities={activities}
            activityStep={activityStep + 1}
            answerCurrentActivity={answerCurrentActivity}
            onNextActivity={nexActivity}
            activityStatus={status}
            feedback={feedbak ?? undefined}
            client={target}
            channel={channel}
            themeType={themeType}
            loadingActivityAnswer={loadingActivityAnswer}
          />
      </PreviewContainer>
    );
  }

  // STATUS ERROR
  if (activities.length <= 0 || activityStep === -1){
    return (
      <PreviewContainer ref={contenedorRef}  channel={channel}  fontSize={DynamicFontSize ?? undefined}>
          {showHeader && <Header
            showTitle={showTitle}
            showReadSpeaker={showReadSpeaker}
          />}
          <div style={{display: 'flex', flexDirection:'column',justifyContent:'center', height: '100%', alignItems:'center'}}>
            <p style={{margin: '1em', textAlign:'center'}}>{_t("app_error_text_1")}</p>
            <p style={{margin: '0 1em', textAlign:'center'}}>{_t("app_error_text_2")}</p>
          </div>
      </PreviewContainer>
      )
  }else{
    return <></>
  }
  
}


export default App;
