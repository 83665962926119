import { USER_ASSESSMENT_END_URI, ONLINE_ACTIVITIES_URI, USER_ACTIVITY_END_URI, USER_ACTIVITY_START_URI, USER_ACTIVITY_URI, USER_ASSESSMENT_START_URI, USER_ASSESSMENT_URI, USER_ASSESSMENT_ATTEMPT_URI } from "../CONSTANT"
import { IActivityOnline, IStartUserAssessment, IUserAssessment } from "./types"

export const getUserActivityById = async (userActivityId: string | number, language: string | undefined) => {
    const response = await fetch(USER_ACTIVITY_URI.replace("{id}", `${userActivityId.toString()}`) + `${language ? `?lang=${language}` : ''}`)
    return await response.json()
}

export const startActivityById = async (userActivityId: string | number): Promise<void> => {
    await fetch(USER_ACTIVITY_START_URI.replace("{id}", userActivityId.toString()), { method: "PUT" })
    return;
}

export const startUserAssessment = async (userAssessmentId: string | number)  => {
    const response = await fetch(USER_ASSESSMENT_START_URI.replace("{id}", userAssessmentId.toString()), { method: "PUT" })
    return await response.json() as IStartUserAssessment;
}

export const getUserAssessmentById = async (userAssessmentId: string | number, language: string | undefined) => {
    const response = await fetch(USER_ASSESSMENT_URI.replace("{id}", `${userAssessmentId.toString()}`) + `${language ? `?lang=${language}` : ''}`)
    return await response.json() as Array<IUserAssessment>
}

export const endUserActivity = async (userActivityId: string | number, data: any, language: string | undefined) => {
    const response = await fetch(USER_ACTIVITY_END_URI.replace("{id}", userActivityId?.toString()) + (language ? `?lang=${language}` : ''), {
        method: "PUT",
        body: JSON.stringify(data)
    })
    return await response.json() as IUserAssessment
}


export const getFinalAssessmentResult = async (userActivityId: string | number , lang = document.documentElement.lang ?? 'es') => {
    const response = await fetch(USER_ASSESSMENT_END_URI.replace("{id}", userActivityId.toString()).replace("{lang}", lang))
    return await response.json()
}

export const getOnlineActivity = async (activityId: string | number, language: string | undefined): Promise<IActivityOnline> => {
    const response = await fetch(ONLINE_ACTIVITIES_URI.replace("{id}", `${activityId.toString()}`) + `${language ? `?lang=${language}` : ''}`)
    const data = await response.json() as IActivityOnline
    return data
}

export const getUserAssessmentAttempt = async (assessmentId : string | number, language: string | undefined) => {
    const response = await fetch(USER_ASSESSMENT_ATTEMPT_URI.replace("{id}", `${assessmentId.toString()}`)+`${language ? `?lang=${language}` : ''}`)
    const data = await response.json()
    return data
}
