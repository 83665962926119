import { useEffect, useState } from 'react'
import { IActivityOnline, IOption, ITarget, IUserAssessment } from '../services/types';
import { getOnlineActivity, getUserActivityById, getUserAssessmentById, startActivityById, endUserActivity, startUserAssessment, getUserAssessmentAttempt } from '../services/xevalApi';
import { IFeedback, IResponseActualActivity, UseActivitiesReturn, IUseActivitiesProps } from './types';
import { ACTIVITIES_INFORMATION_TEMPLATES, SHUFFLE_ASSESSMENT_ACTIVITIES } from '../CONSTANT';


export enum ACTIVITIES_STATUS {
    END = 'END',
    LOADING = 'LOADING',
    LOADING_ACTIVITY = "LOADING_ACTIVITY",
    STARTING_ACTIVITY = 'STARTING_ACTIVITY',
    WAITING_ANSWER = 'WAITING_ANSWER',
    WAITING_NEXT_ACTIVITY = "WAITING_NEXT_ACTIVITY",
    ACTIVITY_ALREADY_DONE = "ACTIVITY_ALREADY_DONE",
    ERROR = "ERROR",
    ASSESSMENT_WITHOUT_ACTIVITIES="ASSESSMENT_WITHOUT_ACTIVITIES"
}

const useActivities = ({ activity_user_id = '0', assessment_user_id, devMode, activityType, paramLanguage: language, setParamLanguage: setLanguage }: IUseActivitiesProps): UseActivitiesReturn => {
    const [activities, setActivities] = useState<Array<IActivityOnline>>([])
    const [assessments, setAssessments] = useState<Array<IUserAssessment>>([])
    const [status, setStatus] = useState<ACTIVITIES_STATUS>(ACTIVITIES_STATUS.LOADING)
    const [currentStep, setCurrentStep] = useState(-1)
    const [feedbak, setFeedbak] = useState<IFeedback | null>(null)
    const [loadingActivityAnswer, setLoadingActivityAnswer] = useState<boolean>(false)
    const [currentAttemptFinished, setCurrentAttemptFinished] = useState<boolean>(false)

    useEffect(() => {
        const init = async () => {
            try {
                //ACTIVITY
                if(activity_user_id !== '0'){
                    getActivity(activity_user_id)
                // ASSESSMENT
                }else if (assessment_user_id) {
                   getAssessment(assessment_user_id)
                } else if (devMode){
                    activateDeveloperMode(activityType)
                }else{  
                    setStatus(ACTIVITIES_STATUS.ERROR)
                    alert("Insert correct params")
                }
            } catch (error) {
                setStatus(ACTIVITIES_STATUS.ERROR)
                return;
            }


        }

        init();
    }, [activity_user_id, assessment_user_id])

    useEffect(() => {
        const init = async () => {
            if (currentStep >= 0 && assessments.length > 0) {
                const currentActivity = assessments[currentStep]
                if (currentActivity?.starts_on === null) {
                    try {
                        await startActivityById(currentActivity.id)
                    } catch (err) {
                        setStatus(ACTIVITIES_STATUS.ERROR)
                        return
                    }
                }

                try {
                    const activityLoading = await getOnlineActivity(currentActivity.activity_id, language ?? currentActivity?.answer_content?.activity_data?.current_language)
                    let data = assessments.map(e => currentActivity.activity_id === e.activity_id ? activityLoading : e.answer_content.activity_data)
                    setActivities(data)
                    setStatus(ACTIVITIES_STATUS.WAITING_ANSWER)
                } catch (err) {
                    setStatus(ACTIVITIES_STATUS.ERROR)
                    return
                }
            }
        }
        if(activity_user_id) init()
    }, [currentStep, assessments])

    const activateDeveloperMode = (activityType: string | undefined) => {
        if(activityType){
            const activity = ACTIVITIES_INFORMATION_TEMPLATES[activityType]
            setActivities([activity])
            setCurrentStep(0)
            setStatus(ACTIVITIES_STATUS.WAITING_ANSWER)
        }
    }

    const getActivity = async(activity_user_id: string) => {
        setStatus(ACTIVITIES_STATUS.LOADING)
        try {
            await startActivityById(activity_user_id)
            const data = await getUserActivityById(activity_user_id, language)
            if(!data.answer_content.activity_data) return setStatus(ACTIVITIES_STATUS.END)
            let activity = await getOnlineActivity(data.activity_id, language ?? data?.answer_content?.activity_data?.current_language )
            activity = {...activity, id: data.activity_id}
            localStorage.setItem('currentActivityId',data.activity_id);
            setActivities([activity])
            setCurrentStep(0)
            setStatus(ACTIVITIES_STATUS.WAITING_ANSWER)
        } catch (error) {
            setStatus(ACTIVITIES_STATUS.ERROR)
            return
        }
    }

    const getAssessment = async(assessment_user_id: string) => {
        setStatus(ACTIVITIES_STATUS.LOADING)

        try {
            const assessment_start = await startUserAssessment(assessment_user_id)
            if (assessment_start.language !== language) {
                setLanguage(assessment_start.language)
            }
            const data = await getUserAssessmentById(assessment_user_id, language)
            const index = data.findIndex((e:any, index: number) => e.ends_on === null && e.answer_content.user_data === undefined)

            if(data.length === 0){
                setStatus(ACTIVITIES_STATUS.ASSESSMENT_WITHOUT_ACTIVITIES)
                return
            }
            // Index -1 y todos los intentos cumplidos
            if (index === -1) {
                setStatus(ACTIVITIES_STATUS.ACTIVITY_ALREADY_DONE)
                return
            }else{
                setAssessments(SHUFFLE_ASSESSMENT_ACTIVITIES ? shuffleAssessmentActivities(data) : data)
                setCurrentStep(index)
            }
        } catch (error) {
            setStatus(ACTIVITIES_STATUS.ERROR)
            return
        }

    }

    const handleFeedbackActivity = (activityFeedback: string, activityScore: number) => {
        const newFeedbackActivity: IFeedback = {
            score: activityScore,
            generic_feedback_title: activityFeedback,
            type: getFeedbackType(Number(activityScore)),
        }

        setFeedbak(newFeedbackActivity)
    }

    const getFeedbackType = (score: number) =>{
        if (score > 66) {
            return 'good'
        } else if (score >= 33 && score < 66) {
            return 'partialy'
        } else {
            return 'bad'
        }
    }

    const getURLQueryParams = () => {
        return Object.fromEntries(new URLSearchParams(window.location.search));
    };

    const answerCurrentActivity = async (response: IResponseActualActivity | Array<ITarget & IOption> | undefined,  activityType: string) => {
        setLoadingActivityAnswer(true)
        let params = getURLQueryParams();
        const answerContent =  response;
        const data = await endUserActivity(assessments[currentStep]?.id || activity_user_id, {...params,...answerContent}, language)

        handleFeedbackActivity(data?.feedback, data.score ?? 0)
        setStatus(ACTIVITIES_STATUS.WAITING_NEXT_ACTIVITY)
        setLoadingActivityAnswer(false)
    }

    const nextActivity = () => {
        setStatus(ACTIVITIES_STATUS.LOADING_ACTIVITY)
        setCurrentStep(currentStep + 1)
    }

    const checkAssessment = () => {
        return setStatus(ACTIVITIES_STATUS.END)
    }

    const resetAssessment = async (assessment_user_id: string | number | null) => {
        if(assessment_user_id){
            await getUserAssessmentAttempt(assessment_user_id, language)
        }
        window.location.reload()
    }

    const cleanLocalStorage = () =>{
        
        localStorage.removeItem('currentAssessmentId');
        localStorage.removeItem('currentActivityId');
        
        let assessmentInStorage = JSON.parse(localStorage.getItem('assessment') || '[]')
        let assessmentStorageCopy = assessmentInStorage
        for (let index = 0; index < assessmentInStorage.length; index++) {
            let activity = assessmentInStorage[index];
            activity.finished = false    
            assessmentStorageCopy[index] = activity        
        }
        localStorage.setItem('assessment', JSON.stringify(assessmentStorageCopy))
    }

    const loadLocalStorage = (assessments: Array<IUserAssessment>) => {
        let localStorageAssessment = JSON.parse(localStorage.getItem("assessment") || '[]')
        if(localStorageAssessment.length === 0){
            assessments.forEach(e => {
                if(e.ends_on !== null && e.answer_content.user_data !== undefined){
                    let activityUpdated = {
                        activity_id: Number(e.activity_id),
                        finished: true
                    }
                    localStorageAssessment.push(activityUpdated)
                }
            });
            localStorage.setItem('assessment', JSON.stringify(localStorageAssessment))
        }
        return localStorageAssessment
    }


    const shuffleAssessmentActivities = (assessment: Array<IUserAssessment>) => {
        let activitiesPendingToComplete = assessment.filter(activity => activity.ends_on === null)
        let activitiesCompleted = assessment.filter(activity => activity.ends_on !== null)
        activitiesPendingToComplete = activitiesPendingToComplete.slice().sort(() => Math.random() - 0.5);
        return activitiesPendingToComplete.concat(activitiesCompleted)
    }

    return [
        activities,
        currentStep,
        status,
        answerCurrentActivity,
        nextActivity,
        checkAssessment,
        feedbak,
        resetAssessment,
        loadingActivityAnswer
    ]
}

export default useActivities
