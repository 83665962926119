import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";



export const StyledContainerOptionsTargets = styled('div')<{theme: IThemeProps, activityName: string}>`
    display: flex;
    justify-content: center;

    .container-droppable {
        position: relative;

        & * {
            margin: 0;
            text-align: center;
            vertical-align: bottom;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 0;
            right: 0;
        }
    }

    .container-droppable,
    .container-draggable,
    .container-answer {
        margin: 10px;
        padding: 3px;
        width: 100%;
        max-height: 130px;
        max-width: 130px;
        border-radius: 10px;
        border: ${({theme, activityName}) => theme.themeActivities[activityName]?.optionsBorder};
        background-color: white;
        aspect-ratio: 1;

        &>img {
            aspect-ratio: 1;
            border-radius: 8px;
            width: 100%;
            object-fit: contain;
        }
    }

    .container-answer {
        position: relative;
        cursor: pointer;

        .img-target,
        .img-answer,
        .text-target,
        .text-answer {
            aspect-ratio: 1;
            width: 50%;
            border-radius: 8px;
            position: absolute;
            margin: 0px;
        }

        .img-target {
            top: 10%;
            left: 10%;
        }

        .img-answer {
            bottom: 10%;
            right: 10%;
        }

        .text-target,
        .text-answer {
            font-size: 10px;
            background-color: white;
            border: ${({theme, activityName}) => theme.themeActivities[activityName].optionsSelected};
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .text-target {
            top: 10%;
            left: 10%;
            overflow: hidden;
        }

        .text-answer {
            bottom: 10%;
            right: 10%;
            overflow: hidden;
        }
    }


    .container-draggable{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

`
