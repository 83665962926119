import styled from "../../themes/styled";
import { IThemeProps } from "../../themes/types";


export const StyledHeaderContainer = styled('div')<{theme: IThemeProps}>`
    color: ${({ theme }) => theme.header?.color ?? theme.color.primary.contrastText};
    font-size: ${({ theme }) => theme.header?.fontSize ?? theme.styles.text.title.size};
    font-weight: ${({theme}) => theme.header?.fontWeight ?? theme.styles.text.title.fontWeight};
    font-family: ${({theme}) => theme.header?.fontFamily ?? theme.styles.text.title.fontFamily};
    line-height: ${({ theme }) => theme.header?.lineHeight ?? theme.styles.text.title.lineHeight};
    width: 100%;
`
    
export const StyledHeaderContent = styled('div')<{theme: IThemeProps}>`
    padding: 1.5em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({theme}) => theme.color.secondary.main};

    .titleContainer{
        display: flex;
        align-items: center;
        padding-right: 0.5em;

        img{
            margin: 0 1.5em;
        }
    }

    .redSpeakerContainer{
        background-color: black;
        margin-right: 1em;
    }
    
    #readspeaker_button1{
        // width: 160px !important;
    }

`
