import styled from "styled-components";
import { IThemeProps } from "../../themes/types"


export const ContainerAssessmentResult = styled('div')<{theme: IThemeProps}>`
    // padding: 1em 1em 0 1em;
    padding-top: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: ${({ theme }) => theme.endUserAssessment.flexPosition ?? 'flex-start'};
    width: 100%;
    height: 100%;
    flex-grow: 1;   


    img{
        -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
        animation: swing-in-top-fwd 2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    }
`

export const ContainerResultInfo = styled('div')<{theme: IThemeProps}>`
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    font-family: ${({theme}) => theme.styles.text.subtitle.fontFamily};
    color: ${({ theme }) => theme.color.primary.main};
    text-align: ${({ theme }) => theme.endUserAssessment.title.align ?? 'left'};
    font-weight: ${({ theme }) => theme.endUserAssessment.title.fontWeight ?? '400'};

    
    h1{
        width: 100%;
        font-size: ${({ theme }) => theme.endUserAssessment.title.fontSize ?? '2em'};
        font-family: ${({ theme }) => theme.endUserAssessment.title.fontFamily ?? 'Roboto'};
        webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

    p {
        width: 100%;
        font-size: ${({ theme }) => theme.endUserAssessment.percentage.fontSize ?? '20px'};
        margin-top: 0.5em;
        webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

    .best-try-score{
        font-size: 18px;
    }
    
    
`


export const StyledProgressBarContainer = styled("div")<{ score: number, theme: IThemeProps}>`
    --background-primary: ${({ theme }) => theme.color.primary.main + '26'};
    --score: ${({score}) => score ? `${score}` : 0};
    --trailColor: ${({theme, score}) => {
        const good = theme.endUserAssessment.good
        const partialy = theme.endUserAssessment.partialy
        const bad = theme.endUserAssessment.bad

        return `${(score > 66)  ? good : (score >= 33 && score <= 66) ? partialy : bad};`
    }}
    width: ${({ theme }) => theme.endUserAssessment.circularProgressBar.circularSize};
    height: ${({ theme }) => theme.endUserAssessment.circularProgressBar.circularSize};

    margin: 2em;
    margin-bottom: 0.5em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(var(--trailColor) calc(var(--progress-value) * 1%), var(--background-primary) 0);
    animation: progress 2.5s 1 forwards;

    &::before {
        counter-reset: percentage var(--progress-value);
        content: ${({ theme }) => theme.endUserAssessment.circularProgressBar.imageInsideProgressBar ? '' : "counter(percentage)'%'" };
        animation: progress 2s 1 forwards;
        font-family: ${({ theme }) => theme.endUserAssessment.circularProgressBar.accurateFont};
        font-size: ${({ theme }) => theme.endUserAssessment.circularProgressBar.accurateSize};
        color: ${({ theme }) => theme.endUserAssessment.circularProgressBar.accurateColor};
        font-weight: ${({ theme }) => theme.endUserAssessment.circularProgressBar.accurateFontWeight};
    }

    @property --progress-value {
        syntax: "<integer>";
        initial-value: 0;
        inherits: false;
    }
  
    @keyframes progress {
        to { --progress-value: var(--score)}
    }

    .accurate-text{
        font-family: ${({ theme }) => theme.endUserAssessment.circularProgressBar.textFont};
        font-size: ${({ theme }) => theme.endUserAssessment.circularProgressBar.textSize};
        color: ${({ theme }) => theme.endUserAssessment.circularProgressBar.textColor};
    }
  
    & img {
        aspect-ratio: 1;
        width: 80%;
        border-radius: 50%;
    }   
`


