import React, { useContext, useEffect } from 'react';
import styled from "styled-components";
import ThemeContext from '../../providers/ThemeProvider/ThemeContext';
import i18n from '../../i18next/i18next';
import { useTranslation } from 'react-i18next';

const StyledNoAssessmentPreview = styled('div')`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    p{
        text-align: center;
    }

`


const AssessmentNoActivities = ({client, urlLanguage}) => {
    const {theme} = useContext(ThemeContext);
    const [_t] = useTranslation("preview");

    useEffect(() => {
        const setScreenLangFromBrowser = () => {
            const lang = urlLanguage ? urlLanguage
             : navigator.languages && navigator.languages.length ? 
             navigator.languages[0] : (navigator.language || navigator.userLanguage);
            i18n.changeLanguage(lang.split('-')[0])
        }
        setScreenLangFromBrowser()
    }, []);

    return (
        <StyledNoAssessmentPreview>
            <img src={`/images/feedbacks/${client}/${client}_partialy${theme.themeType === 'basic' ? '' : '_v2'}.${client === 'mhe' ? 'svg' : 'png'}`}/>
            <p>{_t("assessment_no_activities.text")}</p>
        </StyledNoAssessmentPreview>
    );
}

export default AssessmentNoActivities;
