import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router, } from 'react-router-dom';
import ThemeProvider from './providers/ThemeProvider/ThemeProvider';
import i18n from './i18next/i18next';
import { I18nextProvider } from "react-i18next";


ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>   
        <Router>
          <ThemeProvider client={null} themeType={null}>
            <App />
          </ThemeProvider>
        </Router>
      </I18nextProvider>   
    </React.StrictMode>,
    document.getElementById('root') as HTMLElement
);
