import roundedRect from "./tools";

class BoxText {
    position = null;
    size = {
        width: 280,
        height: 250
    }

    constructor(position) {
        this.position = position;
    }

    draw(context, text) {
        const position = this.getTotalPosition();
        this.drawText(context, position, text);
    }

    drawContainer(context, position) {
        roundedRect(context,
            "#ffffffbb",
            "#ffffffaa",
            position.initial.x,
            position.initial.y,
            this.size.width,
            this.size.height,
            50
        );
    }

    drawText(context, position, text) {
        context.save();
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = "#ffffff";
        context.font = "bold 40px Arial";
        context.fillText(text, position.center.x, position.center.y);
        context.restore();
    }

    getTotalPosition() {
        let halfCoverX = this.size.width / 2;
        let halfCoverY = this.size.height / 2;
        return {
            initial: {
                x: this.position.x - halfCoverX,
                y: this.position.y - halfCoverY
            },
            center: {
                x: this.position.x,
                y: this.position.y
            },
            final: {
                x: this.position.x + halfCoverX,
                y: this.position.y + halfCoverY
            }
        }
    }

    isWithin(mouse) {
        const position = this.getTotalPosition();

        if (
            (position.initial.x < mouse.x && position.final.x > mouse.x) &&
            (position.initial.y < mouse.y && position.final.y > mouse.y)
        ) {
            return true;
        }
        return false;
    }
}

export default BoxText;