class Vector {
    init = {
        x: null,
        y: null
    };

    final = {
        x: null,
        y: null
    };

    constructor(init, final) {
        this.init = init;
        this.final = final;
    }

    nextStep(steps, positionAbsolute = true) {
        const rX = (this.final.x - this.init.x);
        const rY = (this.final.y - this.init.y);
        const distance = Math.sqrt((rX ** 2) + (rY ** 2));

        if (positionAbsolute === true && distance < steps) return this.final;

        let angleRad = -Math.acos(rX / distance);
        if (rY > 0) angleRad = -angleRad;
        const vecX = steps * Math.cos(angleRad);
        const vecY = steps * Math.sin(angleRad);

        return {
            x: this.init.x + vecX,
            y: this.init.y + vecY
        }
    }
}

const calculePositionToAngle = (positionInitial = { x: 0, y: 0 }, step, angle) => {
    let angleRad = angle * Math.PI / 180;
    let stepX = step * Math.cos(angleRad);
    let stepY = step * Math.sin(angleRad);
    return {
        x: positionInitial.x + stepX,
        y: positionInitial.y + stepY
    }
}

const calculeDistance = (firstPosition, secondPosition) => {
    const rX = (firstPosition.x - firstPosition.x);
    const rY = (firstPosition.y - firstPosition.y);
    const distance = Math.sqrt((rX ** 2) + (rY ** 2));

    return distance;
}

export default Vector;

export {
    calculePositionToAngle,
    calculeDistance
} 