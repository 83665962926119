import { DefaultThemeWeb } from "./DefaultThemeWeb"
import { ITheme } from "./types"

export const MheBasicThemeWeb: ITheme = {
    ...DefaultThemeWeb,
    color: {
        primary: {
            main: '#0A223D',
            contrastText: "white",
            dark: '#163250'
        },
        secondary: {
            main: "#EC1F46",
            contrastText: "white"
        },
        background: {
            main: "white",
            paper: "rgb(242, 242, 242)"
        }
    },
    styles:{
        ...DefaultThemeWeb.styles,
        text:{
            ...DefaultThemeWeb.styles.text,
            subtitle: {
                color: '#0A223D',
                size: '1em',
                fontFamily: 'Roboto',
                fontWeight: '400',
                addMargin: true,
                lineHeight: '19px'
            },
            title: {
                color: '#0A223D',
                size: "1.2em",
                fontWeight: 'none',
                fontFamily: 'Roboto',
                backgroundColor: "#f2f2f2",
                borderRadius: "10px",
                lineHeight: '19px',
                addPadding: true
            }
        },
        activityContent:{
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "3px solid rgb(242, 242, 242)"
        }
    },
    bottomBar: {
        ...DefaultThemeWeb.bottomBar,
        button:{
            ...DefaultThemeWeb.bottomBar.button,
            useFeedbackBackgroundColor: false,
            backgroundColor: 'secondary',
            backgroundColorEnabled: '#0A223D',
            backgroundColorDisabled: '#0A223D',
            opacityDisabled: '0.9',
            colorEnabled: 'white',
            colorDisabled: 'white',
            fontSize: '1em',
        },
        feedback:{
            ...DefaultThemeWeb.bottomBar.feedback,
            backgroundColor: '#BCF4F4',
            flexDirection: 'row',
            title:{
                color: "#0A223D",
                fontSize: "1.2em",
                fontWeight: "bold",
            },
            subtitle:{
                color: "grey",
                fontSize: "1em",
            }
        },
    },
    endUserAssessment:{
        ...DefaultThemeWeb.endUserAssessment,
        hasTryAgainButton: true,
        title:{
            fontSize: '2em',
            fontFamily: 'Roboto',
            align: 'center',
            fontWeight: 'bold'
        },
        percentage: {
            fontSize: "1.2em"
        },
        flexPosition:'flex-start',
        good:"#2df286",
        bad: "#df2e24",
        partialy: "#f6ab0e"
    },
    themeActivities:{
        ...DefaultThemeWeb.themeActivities,
    multipleChoice: {
        option: {
            ...DefaultThemeWeb.themeActivities.multipleChoice.option,
            color: "#EC1F46",
            hasMarginHorizontal: true,
            fontFamily: "Roboto",
            border: '1px solid transparent',
            point: {
                ...DefaultThemeWeb.themeActivities.multipleChoice.option.point,
                borderRadius: "100%",
                color: '#EC1F46',
                border: "2px solid #EC1F46",
                type:"filling",
                innerBallBorderRadius: "100%"
            }
        }
    },
    singleChoice: {
        option: {
            ...DefaultThemeWeb.themeActivities.singleChoice.option,
            color: "#EC1F46",
            hasMarginHorizontal: true,
            fontFamily: "Roboto",
            border: '2px solid transparent',
            point: {
                ...DefaultThemeWeb.themeActivities.singleChoice.option.point,
                border: "2px solid #EC1F46",
                color: "#EC1F46",
                borderSelected: "2px solid #EC1F46"
            }
        }
    },
    trueFalse:{
        option: {
            ...DefaultThemeWeb.themeActivities.trueFalse.option,
            hasMarginHorizontal: true,
            fontFamily: "Roboto",
            borderRadius: "10px",
            border: '2px solid transparent',
            point: {
                ...DefaultThemeWeb.themeActivities.trueFalse.option.point,
                border: "2px solid #EC1F46",
                color: "#EC1F46"
            }
        }
    },
    imageChoice: {
        option:{
            ...DefaultThemeWeb.themeActivities.imageChoice.option,
            labelSelectedBorder: "#0A223D dashed 3px",
        }
    },
    textChoice:{
        option:{
            ...DefaultThemeWeb.themeActivities.textChoice.option,
            labelSelectedBorder: "#0A223D dashed 3px",
        }
    },
    imagePairing: {
        ...DefaultThemeWeb.themeActivities.imagePairing,
    },
    textPairing: {
        ...DefaultThemeWeb.themeActivities.textPairing,
    },
    cardChoice: {
        ...DefaultThemeWeb.themeActivities.flashCards,
    },
    froggyJump: {
        ...DefaultThemeWeb.themeActivities.froggyJump,
    },
    flashCards:{
        ...DefaultThemeWeb.themeActivities.flashCards,
        normalCard:{
            ...DefaultThemeWeb.themeActivities.flashCards.normalCard,
            title:{
                ...DefaultThemeWeb.themeActivities.flashCards.normalCard.title,
            },
            subTitle:{
                ...DefaultThemeWeb.themeActivities.flashCards.normalCard.subTitle,
            }
        },
        finalCard:{
            ...DefaultThemeWeb.themeActivities.flashCards.finalCard,
        },
        initialCard:{
            ...DefaultThemeWeb.themeActivities.flashCards.initialCard,
        }
    },
    wordSearch: {
        ...DefaultThemeWeb.themeActivities.wordSearch,
        timer:{
            ...DefaultThemeWeb.themeActivities.wordSearch.timer,
        }
    },
    wordCatcher: {
        ...DefaultThemeWeb.themeActivities.wordCatcher,
        timer:{
            ...DefaultThemeWeb.themeActivities.wordCatcher.timer,
        },
        marker:{
            ...DefaultThemeWeb.themeActivities.wordCatcher.marker,
        }
    },
    seekAndFind: {
        ...DefaultThemeWeb.themeActivities.seekAndFind,
        timer:{
            ...DefaultThemeWeb.themeActivities.seekAndFind.timer,
        },
    },
    fillTheBlanks: {
        ...DefaultThemeWeb.themeActivities.fillTheBlanks,
        input:{
            ...DefaultThemeWeb.themeActivities.fillTheBlanks.input,
            borderBottom: '#0A223D',
        },
        span:{
            ...DefaultThemeWeb.themeActivities.fillTheBlanks.span,
        }
    },
    input: {
        ...DefaultThemeWeb.themeActivities.input,
        text: {
            fontWeight: "400",
            backgroundColor: "white",
            fullwidth: true,
        },
        input: {
            borderRadius: "20px",
            border: "1px solid #0A223D",
            borderOnFocus: "1px solid #0A223D",
            direction: 'column-reverse',
            height: "35px",
            hasMargin: true
        },
        label:{
            color: "#000000",
            fontSize: "12px",
            fontWeight: "600"
        },
        wordCounter:{
            color: "#aeaeae",
            fontSize: "14px",
            fontWeight: "600"
        }
    },
    opinionScale:{
        ...DefaultThemeWeb.themeActivities.opinionScale,
        scaleSentence:{
            ...DefaultThemeWeb.themeActivities.opinionScale.scaleSentence
        },
        scaleValueSelected:{
            ...DefaultThemeWeb.themeActivities.opinionScale.scaleValueSelected
        }
    },
    correspondence: {
        ...DefaultThemeWeb.themeActivities.correspondence,
        webDirection: 'column',
        steps: {
            showDotStepper: false,
        },
        cards:{
            backgroundColor: '#FFFFFF',
            color: "#0A223D",
            separation: "4",
            border: '3px solid #f2f2f2',
            borderRadius: '8px'
        },
        options:{ 
            width: '140px',
            height: '60px',
            backgroundColor: '#FFFFFF',
            color: "#0A223D",

            border: '3px solid #f2f2f2',
            borderRadius: '8px',
            backgroundColorHover: '#06235B',
            colorHover: '#FFFFFF'
        },
        
    },
    deduction: {
        ...DefaultThemeWeb.themeActivities.deduction,
        containerBg: 'unset',
        containerBoxShadow: 'unset',
        containerBorder: '3px solid #f2f2f2',
        containerRadius: '20px',
        option:{
            background: '#0A223D',
            color: 'white',
        },
        optionInput:{ 
            allBorders: false,
            border: '1px solid #e5e5e5',
        },
        text: {
            color: '#0A223D',
            lineHeight: '160%'
        },
    },
    orderChronology:{
        cardWidthMobile: '90px',
        cardHeightMobile: '120px',
        cardWidthWeb: '120px',
        cardHeightWeb: '160px',
        cardRadius: '8px',
        showTargetOnSelect: false,
        option:{
            color: "white",
            border: '2px dashed #8C8C8C',
            backgroundColor: '#0A223D',
            colorOnSelect: 'white',
            backgroundColorOnSelect: '#0A223D',
            backgroundColorEmptyCardSelected: 'transparent',
            borderEmptyCardSelected: '2px dashed #E5E5E5',
        },
        target:{
            border: '2px dashed #0A223D',
            backgroundColorOnDrop: 'transparent',
            backgroundColorOnSelect: 'transparent',
            backgroundColor: 'transparent',
            colorOnSelect: '#0A223D',
        },
        lineSeparator: {
            useLine: false,
            color: '#CCCCCC'
        }
    },
    shortQuestion: {
        ...DefaultThemeWeb.themeActivities.shortQuestion,
        option:{
            ...DefaultThemeWeb.themeActivities.shortQuestion.option
        }
    },
    match:{
        ...DefaultThemeWeb.themeActivities.match,
        cardRadius: '10px',
        showTargetOnSelect: false,
        option:{
            color: 'white',
            border: '2px dashed #0A223D',
            backgroundColor: '#0A223D',
            colorOnSelect: 'white',
            backgroundColorOnSelect: '#0A223D',
            backgroundColorEmptyCardSelected: '#0A223D',
            borderEmptyCardSelected: '2px dashed #0A223D',
            size: '0.8em',
        },
        target:{
            border: '2px dashed #0A223D',
            backgroundColorOnDrop: 'transparent',
            backgroundColorOnSelect: '#0A223D',
            backgroundColor: 'transparent',
            colorOnSelect: 'white',
            color: 'rgba(10, 34, 61, 0.5)',
            size: '0.8em',
        },
    },
    }
}
