import styled from "styled-components";
import MountainBackground from './images/backgrounds/mountain.png';
import SeaBackground from './images/backgrounds/sea.png';
import SpaceBackground from './images/backgrounds/space.png';
import { IThemeProps } from "../../../themes/types";


export const StyledContainerChip = styled('div')`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    padding-top: 3rem;


    .container-draggable-chip {
        width: 80px;

        img {
            border: none;
            background-color: transparent;
            width: 100%;
            max-width: 90px;
        }
    }
`


export const StyledCardChoiceContainer = styled('div')<{theme: IThemeProps, stylegame: string}>`
    height: 100%;
    overflow: hidden;
    background-position: center top;
    background-size: 100% auto;
    padding-bottom: ${({theme}) => theme.margin.default};

    .container-options {
        padding-top: 5px;
        display: flex;

        .container-option {

            width: 33.33%;
            height: 130px;
            max-width: 120px;
            margin: 0 5px;
            border-radius: 10px;
            padding: 5px;
            aspect-ratio: 1/10;
            max-height: 150px;
            border: 1px solid #efefef;
            font-size: 14px;
            box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
            position: relative;
            background-color: rgba(255, 255, 255, 0.6);

            p {
                text-align: center;
                margin: 0;
            }

            &:first-child {
                margin-top: 20px
            }

            &:last-child {
                margin-top: 20px
            }

            .container-draggable-chip {
                width: 60px;
                position: absolute;
                .image-chip {
                    border: none;
                    background-color: transparent;
                    width: 100%;
                    max-width: 90px;
                }
                transform: translate(-50%, 0);
                bottom: -20px;
                left: 50%;
            }
        }
    }

    ${({stylegame}) => {
        const urlBackgroundGame = stylegame === 'SPACE' ? SpaceBackground : stylegame === 'PIRATES' ? SeaBackground : MountainBackground;
        const sizeChip = stylegame === 'SPACE' ? '70px' : stylegame === 'PIRATES' ? '80px' : '80px';
        return `
            background-image: url(${urlBackgroundGame});
            .container-draggable-chip {
                width: ${sizeChip};  
            }
        `
    }}
`

