import React, { useState, useEffect } from 'react';
import {
	StyledScaleSentence,
	StyledScaleValue,
	StyledScaleBox,
	StyledExtraInfoDescriptionContainer,
	StyledScaleFacesBox,
	StyledScaleRange
} from './OpinionScaleStyles';
import DOMPurify from 'isomorphic-dompurify';
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader } from '../XEduActivitiesStyles';
import NeutralFace from './images/Neutral_colour.svg';
import AgreeFace from './images/Agree_Colour.svg';
import DisagreeFace from './images/Disagree_Colour.svg';
import StronglyAgreeFace from './images/StronglyAgree_Colour.svg'
import StronglyDisagreeFace from './images/StronglyDisagree_Colour.svg'




const OpinionScale = ({ activity, setStateMicrogame, client, ...props }) => {

	const [scaleValue, setScaleValue] = useState(2);
	const [scaleRangeName, setScaleRangeName] = useState('neutral');
	const isScaleOfThree = activity.options?.length === 3 ? true : false;
	const ordered_options = activity?.options.sort((optA, optB) => optA.order - optB.order)

	const rangeDictionary = {
        0: "stronglyDisagree",    
        1: "disagree",     
        2: "neutral",
		3: 'agree',
		4: 'stronglyAgree'          
    };

    const onChangeRange = (e) => {
        const newValue =  e?.target?.value ? e.target.value :  e;
        if (rangeDictionary.hasOwnProperty(newValue)) {
            setScaleRangeName(rangeDictionary[newValue]);
			setScaleValue(parseInt(newValue))
        };
    } ;



	useEffect(() => {
		setStateMicrogame(activity.options[scaleValue]?.id);
	}, [scaleValue, setStateMicrogame, activity]);

	return (
        <StyledActivityContainer>

			{activity?.title && <StyledActivityHeader><h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}/></StyledActivityHeader>}

			<StyledActivityContent client={client}>
				{activity?.text && <p className='subtitle'  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }} />}

				<StyledScaleBox>
					<StyledExtraInfoDescriptionContainer>
						<StyledScaleSentence>{"Rate this affirmation:"}</StyledScaleSentence>
						<StyledScaleValue dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ordered_options[scaleValue]?.title) }} />
					</StyledExtraInfoDescriptionContainer>
					<StyledScaleRange 
						name="range"
						id="range"
						type="range"
						min="0"
						max= {isScaleOfThree ? '2' : '4'}  
						list="markers" 
						value={Object.keys(rangeDictionary).find((key) => rangeDictionary[key] === scaleRangeName)}
						onChange={(e) => onChangeRange(e)}
					/>

					{!isScaleOfThree
						? <StyledScaleFacesBox scaleValue={scaleValue + 1}>
							<img src={StronglyDisagreeFace} alt="Scale Option" onClick={() => onChangeRange(0)}/>
							<img src={DisagreeFace} alt="Scale Option" onClick={() => onChangeRange(1)}/>
							<img src={NeutralFace} alt="Scale Option" onClick={() => onChangeRange(2)}/>
							<img src={AgreeFace} alt="Scale Option" onClick={() => onChangeRange(3)}/>
							<img src={StronglyAgreeFace} alt="Scale Option" onClick={() => onChangeRange(4)}/>
						</StyledScaleFacesBox>
						: <StyledScaleFacesBox scaleValue={scaleValue + 1}>
							<img src={StronglyDisagreeFace} alt="Scale Option" onClick={() => onChangeRange(0)}/>
							<img src={NeutralFace} alt="Scale Option" onClick={() => onChangeRange(1)}/>
							<img src={StronglyAgreeFace} alt="Scale Option" onClick={() => onChangeRange(2)}/>
						</StyledScaleFacesBox>
					}
				</StyledScaleBox>
            </StyledActivityContent>
        </StyledActivityContainer>
	);
};

export default OpinionScale;
