import React from "react";
import LetterSoupClass from "./WordSearchClass";
import Tools from "./Tools";
import { withTranslation } from 'react-i18next';
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, XEduActivityContainer } from "../XEduActivitiesStyles";
import HTMLReactParser from 'html-react-parser'
import { StyledAlphabetTableGame, StyledGame, StyledGameInfoContainer, StyledWordsCounterContainer } from "./WordSearchStyles";
import DOMPurify from "isomorphic-dompurify";
import { withTheme } from 'styled-components'

class WordSearch extends React.Component {
	canvasRef;
	canvas;
	context;
	animationFrameId;
	frameCount = 0;
	wordsQuantity = 0
	channel = "web"
	themeConfig;

	currentStep;
	config;

	scaleRatio = {
		xRatio: 0,
		YRatio: 0
	};

	soup;

	//Mouse variables
	mouse = {
		x: 0,
		y: 0,
		click: false,
		enter: false
	};

	intervalTimer = null;

	constructor(props) {
		super(props);
		this.i18n = props.i18n
		this.canvasRef = React.createRef();
		this.config = {
			width: 1000,
			height: 1000,
			size: null,
			alphabet: null,
			rules: null
		};

		this.renderCanvas = this.renderCanvas.bind(this);

		var minutes = "00"
		var seconds = "00"
		// If existe time : set timer
		if (props.data?.props?.time) {
			minutes = Math.floor(props.data.props?.time / 60);
			seconds = props.data.props?.time - minutes * 60;
		}

		this.state = {
			timer: {
				minutes: minutes === "00" ? "00" : `0${minutes}`,
				seconds: seconds === "00" ? "00" : seconds < 10 ? `0${seconds}` : seconds,
			},
			words: {
				wordsToSearch: [],
				wordsSearched: []
			},
			start: false,
			gameEnd: false
		};
	}

	componentDidMount() {
		//this.currentStep = this.props.currentStep;
		let matrix = this.props.data.matrix.props;
		let config = {
			size: matrix.size, alphabet: matrix.alphabet,
			"rules": matrix.rules
		}
		this.config = {
			width: 1000,
			height: 1000,
			size: config.size.width,
			alphabet: config.alphabet,
			rules: config.rules
		};

		this.startGame();
	}

	startGame = () => {
		this.setState({ start: true });
		this.canvas = this.canvasRef.current;
		this.context = this.canvasRef.current.getContext("2d");
		
		if (this.context) {
			this.themeConfig = this.props.theme.themeActivities.wordSearch
			let letters = Array.from(this.props.data?.accepted_words ?? this.props.data?.targets?.[0]?.accepted_values ?? [], (element) => element.toLowerCase());
			let findedWordColor = this.themeConfig.wordFindedColor || '#000000';
			let findedWordColorBackground = this.themeConfig.wordFindedBackgroundColor || '#006B6C';
			let wordColorSelector = this.themeConfig.wordColorSelector || '#F58000';
			let wrongWordColorfade = this.themeConfig.wrongColorFade || '#BA0F46';
			this.channel = this.props.channel;
			this.wordsQuantity = letters.length
			this.soup = new LetterSoupClass(this.context, this.config, letters, this.gameEnd, findedWordColorBackground, findedWordColor, wordColorSelector, wrongWordColorfade);
			this.soup.drawLettersSearched(this.toFindedWord);
			//We generate the boxes of the alphabet soup
			let lettersBoxes = Tools.generateBoxes(this.config, letters);

			this.soup.intertLetters(lettersBoxes);

			//We launch the thread to render the canvas
			this.renderCanvas();
			this.initTimer();
		}
	};

	componentWillUnmount() {
		if (this.intervalTimer) {
			clearInterval(this.intervalTimer);
		}
	}

	initTimer() {
		this.intervalTimer = setInterval(() => {
			const { timer } = this.state;
			if (this.props?.data?.props?.time) {
				if (parseInt(timer.minutes) !== 0 || parseInt(timer.seconds) !== 0) {
					var minutes = parseInt(timer.seconds) === 0 ? parseInt(timer.minutes) - 1 : parseInt(timer.minutes)
					var seconds = parseInt(timer.minutes) !== 0 && parseInt(timer.seconds) === 0 ? 59 : (parseInt(timer.seconds) - 1);

					if (seconds === 0 && minutes === 1) {
						minutes = 1
					}
					
					if(seconds === 0 && minutes === 0){
						this.setState({
							timer: {
								seconds: seconds,
								minutes: minutes
							}
						})
						this.gameEnd()
					}
				}
			} else {
				var seconds = (parseInt(timer.seconds) + 1) % 60;
				var minutes = seconds === 0 ? parseInt(timer.minutes) + 1 : parseInt(timer.minutes);
			}

			if (seconds < 10) seconds = "0" + seconds;
			if (minutes < 10) minutes = "0" + minutes;

			this.setState({
				timer: {
					seconds: seconds,
					minutes: minutes
				}
			});

		}, 1000);
	}

	toFindedWord = (wordsToSearch = [], wordsSearched = []) => {
		this.setState({
			words: {
				wordsToSearch,
				wordsSearched
			}
		});
	};

	renderCanvas() {
		this.frameCount++;
		//this.clearCanvas(this.context);

		// check if time is over
		if (this.props.data.props.time) {
			if (parseInt(this.state.timer.seconds) === 0 && parseInt(this.state.timer.minutes) === 0) {
				return
			}
		}
		//Calculamos Xratio
		const rect = this.canvas.getBoundingClientRect();
		this.scaleRatio.xRatio = this.canvas.width / this.config.width;
		this.scaleRatio.yRatio = this.canvas.width / this.config.width;

		//We update the canvas
		this.soup.updateStatus(this.mouse, this.scaleRatio);

		//We draw the canvas
		this.soup.draw();

		//We call the main Canvas loop again
		if (this.state.gameEnd) return;
		this.animationFrameId = window.requestAnimationFrame(this.renderCanvas);
	}

	handleMouseDown = (event) => {
		event.preventDefault();
		this.mouse.click = true;
	};

	handleMouseUp = () => {
		this.mouse.click = false;
	};

	handleMouseMove = (event) => {
		const rect = this.canvas.getBoundingClientRect();

		const x = ((event.clientX - rect.left) / rect.width) * this.config.width;
		const y = ((event.clientY - rect.top) / rect.height) * this.config.height;

		this.mouse.x = x;
		this.mouse.y = y;
	};

	gameEnd = () => {
		this.setState({ gameEnd: true });
		clearInterval(this.intervalTimer);
		this.props.setStateMicrogame([{accepted_values: this.state.words.wordsSearched}])
	};

	mouseOnEnter = () => {
		this.mouse.enter = true;
	};

	mouseOnLeave = () => {
		this.mouse.enter = false;
	};

	smartphoneTouchStart = (e) => {
		e.preventDefault();
	};

	smartphoneTouchEnd = (e) => {
		e.preventDefault();
		this.mouse.click = false;
		this.mouse.enter = false;
	};

	smartphoneTouchMove = (e) => {
		e.preventDefault();
		this.mouse.click = true;
		this.mouse.enter = true;
		this.handleMouseMove({
			clientX: e.touches[0].clientX,
			clientY: e.touches[0].clientY
		});
	};

	scroll = (e) => {
		e.preventDefault();
		if (this.mouse.enter) e.preventDefault();
	};

	renderHeader = (display) =>{
		if(display) 
			return(
				<>
				{this.props?.data?.title && 
				<StyledActivityHeader>
					<h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props?.data?.title) }}/>
				</StyledActivityHeader>
				}
				</>
			)
		return <></>
	}

	renderSubtitle = (display) => {
		if(display) 
		return(
			<>
			{this.props?.data?.text && 
				<p className='subtitle'  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props?.data?.text) }} />
			}
			</>
		)
	return <></>
	}

	render() {
		return (
			<StyledActivityContainer>
				{this.renderHeader(this.props.channel === "mobile" ? true : this?.themeConfig?.titilesWithActivityInfoWeb ? false : true)}

				<StyledActivityContent client={this.props?.client}>
					<XEduActivityContainer>
					{this.renderSubtitle(this.props.channel === "mobile" ? true : this?.themeConfig?.subtitlesWithActivityInfoWeb ? false : true)}

						<StyledAlphabetTableGame onScroll={this.scroll}>
							<StyledGame 
								channel={this.channel}
								background={this.props.data.props.background_image.image || undefined}>
								<div className='gameCanvas'>
									<canvas
										ref={this.canvasRef}
										width={this.config.width}
										height={this.config.height}
										onMouseDown={this.handleMouseDown}
										onMouseUp={this.handleMouseUp}
										onMouseMove={this.handleMouseMove}
										onMouseLeave={this.mouseOnLeave}
										onMouseEnter={this.mouseOnEnter}
										onTouchStart={this.smartphoneTouchStart}
										onTouchEnd={this.smartphoneTouchEnd}
										onTouchMoveCapture={this.smartphoneTouchMove}
										className='canvasRef'
									></canvas>
								</div>
								
								<StyledGameInfoContainer>
									{this.renderHeader( this.props.channel === "mobile" ? false : this.themeConfig?.titilesWithActivityInfoWeb ? true : false)}
									{this.renderSubtitle( this.props.channel === "mobile" ? false : this.themeConfig?.subtitlesWithActivityInfoWeb ? true : false)}

									{this.props?.data?.props?.time > 0 ? 
											<div className='timer'>
												{this.themeConfig?.timer?.useIcon && <img src={'/icons/timerIcon.svg'}/>}
												{parseInt(this.state.timer.seconds) === 0 && parseInt(this.state.timer.minutes) === 0
													?
														<samp>{this.i18n.exists("kakuma_activities:common.times_up") ? this.i18n.t("kakuma_activities:common.times_up") : "Time's up"}</samp>
													:
													<>
														<samp className='mitutes'>{this.state.timer.minutes}</samp>:<samp className='seconds'>{this.state.timer.seconds}</samp>
													</>
												}
											</div>
									: <></> 
									}
									{
										<StyledWordsCounterContainer wordFinded={this.state.words.wordsSearched.length > 0}>
											<samp className="words-founded-text">{this.i18n.exists("kakuma_activities:common.words_finded_text") ? this.i18n.t("kakuma_activities:common.words_finded_text") : "Words finded:"}</samp>
											<samp className='words-founded-counter'>{`${this.state.words.wordsSearched.length >= 10 ? this.state.words.wordsSearched.length : `0${this.state.words.wordsSearched.length}`}/${this.wordsQuantity >= 10 ? this.wordsQuantity : `0${this.wordsQuantity}`}`}</samp>
										</StyledWordsCounterContainer>
									}
								</StyledGameInfoContainer>

								
								
							</StyledGame>
						</StyledAlphabetTableGame>
						</XEduActivityContainer>
					</StyledActivityContent>
					
			</StyledActivityContainer>
		);
	}
}

export default withTheme(withTranslation('kakuma_activities')(WordSearch));
