import froggyImage1 from "./../images/froggy/froggy.png";
import froggyImage2 from "./../images/froggy/froggy2.png";
import froggyImage3 from "./../images/froggy/froggy3.png";
import froggyImage4 from "./../images/froggy/froggy4.png";
const sprites = 7;

const froggySprites = [
    froggyImage1,
    froggyImage2,
    froggyImage3,
    froggyImage4
]

class Froggy {
    images = [];
    position;
    size;
    animation = 0;
    angle = 0;

    constructor(position, config = { size: 200, velocity: 8, distance: 50 }) {
        this.position = position;
        this.size = config.size;

        froggySprites.forEach((element, index) => {
            const newImage = new Image();
            newImage.src = element;
            this.images.push(newImage);
        })
    }

    draw(context, damage) {
        const position = this.getFullPosition();

        context.save();
        if (this.angle !== 0) {
            const tranlateX = position.initial.x + this.size / 2;
            const tranlateY = position.initial.y + this.size / 2;
            context.translate(tranlateX, tranlateY);
            context.rotate((Math.PI * this.angle) / 180);
            context.translate(-tranlateX, -tranlateY);
        }

        const targetImage = this.images[damage >= this.images.length ? this.images.length - 1 : damage];
        let positionImageSeledted = this.animation * targetImage.height;
        context.drawImage(
            targetImage,
            positionImageSeledted, 0,
            targetImage.height, targetImage.height,
            position.initial.x, position.initial.y,
            this.size, this.size
        );
        context.restore();
    }

    nexAnimation() {
        this.animation += 1;
        this.animation = this.animation % sprites;
    }

    setAngle(angle) {
        this.angle = angle;
    }

    getFullPosition() {
        let halfCover = this.size / 2;
        return {
            initial: {
                x: this.position.x - halfCover,
                y: this.position.y - halfCover
            },
            center: {
                x: this.position.x,
                y: this.position.y
            },
            final: {
                x: this.position.x + halfCover,
                y: this.position.y + halfCover
            }
        }
    }
}

export default Froggy;