import React, { useEffect, useState } from 'react';
import {useCustomTranslations} from '../../i18next/useCustomTranslations';
import i18next from 'i18next';
import { MEDIA_PREVIEW } from "../../CONSTANT";
import useParseUserAnswer from '../../hooks/useParseUserAnswer';

// Activities import
import SeekAndFind from './SeekAndFind/SeekAndFind';
import ActivityChoice from './ActivityChoice/ActivityChoice';
import ActivityOptionPairing from './ActivityOptionPairing/ActivityOptionPairing';
import ActivityCardChoice from './ActivityCardChoice/ActivityCardChoice';
import FlashCard from './FlashCard/FlashCard';
import FroggyJump from './FroggyJump/FroggyJump';
import ActivityOptionChoice from './ActivityOptionChoice/ActivityOptionChoice';
import WordCatcher from './HutWords/WordCatcher';
import WordSearch from './WordSearch/WordSearch';
import FillTheBlanks from './FillTheBlanks/FillTheBlanks';
import Input from './Input/Input';
import OpinionScale from './OpinionScale/OpinionScale';
import OrderChronology from './OrderChronology/OrderChronology';
import ShortQuestion from './ShortQuestion/ShortQuestion';
import Deduction from './Deduction/Deduction';
import Correspondence from './Correspondence/Correspondence';
import Match from './Match/Match';
import ThemeProvider from '../../providers/ThemeProvider/ThemeProvider';

const XEduActivitiesWithTranslations = ({ setStateMicrogame, activityType, testData, currentStep, isMobile, client, channel, ...props }) => {
    const [answerUserData, setAnswerUserData] = useState(undefined);
    const currentTestData = testData[currentStep - 1];
    const {i18n: customI18n} = useCustomTranslations(currentTestData?.language?.split('-')[0])
    const {buildUserAnswer} = useParseUserAnswer();

    useEffect(() => {
        const finalUserResponse = buildUserAnswer(answerUserData,activityType)
        setStateMicrogame(finalUserResponse)
    }, [answerUserData])

    // SINGLE CHOICE - MULTIPLE CHOICE - TRUE FALSE
    if(activityType === "single-choice" || activityType === "multiple-choice" || activityType === "true-false"){
        return <ActivityOptionChoice
            client={client}
            setStateMicrogame={setAnswerUserData}
            activityType={activityType}
            activity={currentTestData}
            channel={channel}
        />
    }
    // IMAGE CHOICE - TEXT CHOCIE
    if (activityType === "image-choice" || activityType === "text-choice") {
        return <ActivityChoice
                client={client}
                activityType={activityType}
                activity={currentTestData}
                optionList={currentTestData.options}
                setStateMicrogame={setAnswerUserData}
            />
    } 
    // IMAGE PAIRING - TEXT PAIRING
    if (activityType === "image-pairing" || activityType === "text-pairing") {
        return <ActivityOptionPairing
            client={client}
            activity={currentTestData}
            activityType={activityType}
            targetsList={currentTestData.targets}
            optionsList={currentTestData.options}
            setStateMicrogame={setAnswerUserData}
        />

    } 
    // CARD CHOICE
    if (activityType === "card-choice") {
        return <ActivityCardChoice
            client={client}
            activity={currentTestData}
            activityType={activityType}
            optionList={currentTestData.options}
            setStateMicrogame={setAnswerUserData}
            background={currentTestData.background}
        />

    } 
    // FROGGY JUMP
    if (activityType === "froggy-jump") {
        let options = currentTestData.targets.map((element) => {
            return {
                target: element,
                options: currentTestData.options.filter(option => option.target_order === element.order)
            }
        })
        return <FroggyJump
            client={client}
            activity={currentTestData}
            listOptions={options}
            setStateMicrogame={setAnswerUserData}
            i18n={customI18n}
        />
    }
    // FLASH CARDS
    if (activityType === "flash-cards") {
        return <FlashCard
            client={client}
            activity={currentTestData}
            optionList={currentTestData.options}
            setStateMicrogame={setAnswerUserData}
            isMobile={isMobile ?? false}
            i18n={customI18n}
        />

    }
    // WORD SEARCH
    if (activityType === "word-search") {
        return <WordSearch
            client={client}
            data={currentTestData}
            currentStep={currentStep}
            setStateMicrogame={setAnswerUserData}
            // end={props.setWordSearchAnswer} EDIT ON KAKUMA FRONT
            i18n={customI18n}
            channel={channel}
        />
    } 
    // WWORD CATCHER
    if (activityType === "word-catcher") {
        return <WordCatcher
            client={client}
            data={currentTestData}
            currentStep={currentStep}
            optionList={currentTestData.options}
            setStateMicrogame={setAnswerUserData}
            i18n={customI18n}
            channel={channel}
        />
    } 
    // SEEK AND FIND
    if (activityType === "seek-and-find") {
        const targetPoint = testData[currentStep - 1].targets[0].area;
        const options = testData[currentStep - 1].options;


        if (options[0].is_right === "PARTIALLY") {
            testData[currentStep - 1].options = [
                options[1],
                options[0]
            ]
        }

        const onSelectAnsWers = (cords) => {
            const distanceX = Math.abs(cords.x - targetPoint.x);
            const distanceY = Math.abs(cords.y - targetPoint.y);
            const hipotenusa = Math.hypot(distanceX, distanceY)

            const area = {
                x: cords.x,
                y: cords.y,
                radius: hipotenusa,
            }
            setAnswerUserData([{
                area:area
                }]
            );
        }

        return <SeekAndFind
                client={client}
                data={currentTestData}
                onedit={(e) => { }}
                setStateMicrogame={onSelectAnsWers}
                editionMode={false}
                mediaPreview={MEDIA_PREVIEW}
                channel={channel}
                i18n={customI18n}
            />

    }
    if (activityType === "fill-the-blanks") {
        return <FillTheBlanks
            client={client}
            activity={currentTestData}
            setStateMicrogame={setAnswerUserData}
            channel={channel}
        />
    }
    if (activityType === "input" || activityType === "input-long") {
        return <Input
            channel={channel}
            client={client}
            activity={currentTestData}
            setStateMicrogame={setAnswerUserData}
            isLong={activityType === 'input-long' ? true : false}
            
        />
    }
    if(activityType === "opinion-scale"){
        return <OpinionScale
            client={client}
            activity={currentTestData}
            setStateMicrogame={setAnswerUserData} 
        />
    }
    if(activityType === "order-chronology"){
        return <OrderChronology
            client={client}
            activity={currentTestData}
            setStateMicrogame={setAnswerUserData} 
            getLocalPath={props?.getLocalPath}
            courseCode={props?.courseCode}
            channel={channel}
            themeType={props.themeType}
        />
    }
    if(activityType === "short-question"){
        return <ShortQuestion
            client={client}
            activity={currentTestData}
            setStateMicrogame={setAnswerUserData} 
            getLocalPath={props?.getLocalPath}
            courseCode={props?.courseCode}
        />
    }
    if(activityType === "match" || activityType === 'rank'){
        return <Match
            client={client}
            activity={currentTestData}
            setStateMicrogame={setAnswerUserData} 
            getLocalPath={props?.getLocalPath}
            courseCode={props?.courseCode}
        />
    }
    if(activityType === "deduction" || activityType === "fit-word"){
        return <Deduction
            client={client}
            activity={currentTestData}
            setStateMicrogame={setAnswerUserData} 
        />
    }
    if(activityType === "correspondence"){
        return <Correspondence
            client={client}
            activity={currentTestData}
            setStateMicrogame={setAnswerUserData} 
            channel={channel}
        />
    }
    

    return <p>There is no <strong>"{activityType}"</strong> in xeval microgames</p>
}

const XEduActivities = (props) => {
    const [load,setLoad] = useState(false);

    useEffect(() => {
        if(!i18next.isInitialized){
            import('../../i18next/i18next')
            .then(_ => setLoad(true))
        }else{
            setLoad(true)
        }
    }, []);

    if(!load){
        return null
    }

    return (
        <ThemeProvider client={props.client} themeType={props.themeType}>
            <XEduActivitiesWithTranslations {...props} />
        </ThemeProvider>
    )
}



export default XEduActivities
