import styled from "styled-components";
import { IThemeProps } from "../../themes/types"

export const LoaderStyled = styled('div')<{theme: IThemeProps}>`
    position: absolute;
    --background-primary: ${({ theme }) => theme.color.primary.main};
    --background-paper: ${({ theme }) => theme.color.background.paper};
    display: inline-block;
    width: 80px;
    height: 80px;

    :after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid red;
        border-color: var(--background-primary) var(--background-paper) var(--background-primary) var(--background-paper);
        animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

export const LoaderContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;


    h2{
        margin-top: 15px;
        margin-left: 10px;
    }

`
