function roundedRect(context, fillColor, lineColor, x, y, width, height, radius) {
    context.save();
    context.strokeStyle = fillColor;
    context.fillStyle = lineColor;
    context.beginPath();
    context.lineWidth = 5;
    context.moveTo(x, y + radius);
    context.lineTo(x, y + height - radius);
    context.quadraticCurveTo(x, y + height, x + radius, y + height);
    context.lineTo(x + width - radius, y + height);
    context.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
    context.lineTo(x + width, y + radius);
    context.quadraticCurveTo(x + width, y, x + width - radius, y);
    context.lineTo(x + radius, y);
    context.quadraticCurveTo(x, y, x, y + radius);
    context.fill();
    context.stroke();
    context.restore();
}

export default roundedRect;