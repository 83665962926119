import React, { useEffect, useState, useContext } from 'react'
import { IAssessmentResultProps, IAssessmentResult } from './type'
import { getFinalAssessmentResult } from '../../services/xevalApi'
import { ContainerAssessmentResult, ContainerResultInfo } from './AssessmentEndStyle';
import TopBar from '../../layout/TopBar/TopBar';
import { useTranslation } from 'react-i18next';
import CircularProgressBar from './CircularProgressBar';
import ThemeContext from '../../providers/ThemeProvider/ThemeContext';
import { ButtonCheckResult, ContainerButton } from '../../layout/BottomBar/BottomBarStyles';
import { Loader } from '../Loader/Loader';
import { StyledKakumaGamesContainer } from '../Activities/Activities';
import { ACTIVITIES_STATUS } from '../../hooks/useActivities';



const AssessmentEnd = ({activityStep, maxSteps, assessmentUserId, client, feedback, activityType, resetAssessment}: IAssessmentResultProps) => {
    const [imageSrc, setImageSrc] = useState('');
    const [assessmentScore, setAssessmentScore] = useState(0);
    const [assessmentLastScore, setAssessmentLastScore] = useState(0);
    const [disableButton, setDisableButton] = useState(false);
    const {theme} = useContext(ThemeContext);
    const useRandomImages = theme.endUserAssessment.useRandomFeedbackImages ?? false
    const FEEDBACK_IMAGES_GOOD = [
        `/images/feedbacks/${client}/${client}_good${theme.themeType === 'basic' ? '' : '_v2'}.${client === 'mhe' ? 'svg' : 'png'}`,
        `/images/feedbacks/${client}/${client}_good2${theme.themeType === 'basic' ? '' : '_v2'}.${client === 'mhe' ? 'svg' : 'png'}`,
    ]
    const FEEDBACK_IMAGES_BAD = [
        `/images/feedbacks/${client}/${client}_bad${theme.themeType === 'basic' ? '' : '_v2'}.${client === 'mhe' ? 'svg' : 'png'}`,
        `/images/feedbacks/${client}/${client}_bad2${theme.themeType === 'basic' ? '' : '_v2'}.${client === 'mhe' ? 'svg' : 'png'}`,
    ]
    const FEEDBACK_IMAGES_PARTIALY = [
        `/images/feedbacks/${client}/${client}_partialy${theme.themeType === 'basic' ? '' : '_v2'}.${client === 'mhe' ? 'svg' : 'png'}`,
        `/images/feedbacks/${client}/${client}_partialy2${theme.themeType === 'basic' ? '' : '_v2'}.${client === 'mhe' ? 'svg' : 'png'}`,
    ]
    const [_t] = useTranslation("preview");

    useEffect(() => {
        if(assessmentUserId){
            getFinalAssessmentResult(assessmentUserId).then(res =>{
                setAssessmentScore(Math.floor(res.score))
                setAssessmentLastScore(Math.floor(res.last_score))
            }).catch(err =>{
                console.log("Err getting assessment result", err);
            })
        }else{
            setAssessmentScore(Math.floor(feedback.score))
        }
    }, [])

    const selectImageByScore = () => {
        if(assessmentScore < 33) setImageSrc(FEEDBACK_IMAGES_BAD[0])
        if(assessmentScore >= 33) setImageSrc(FEEDBACK_IMAGES_PARTIALY[0])
        if(assessmentScore > 66) setImageSrc(FEEDBACK_IMAGES_GOOD[0])
    }

    const selectRandomImageByScore = () => {
        if(assessmentScore < 33) setImageSrc(FEEDBACK_IMAGES_BAD[Math.floor(Math.random()*FEEDBACK_IMAGES_BAD.length)])
        if(assessmentScore >= 33) setImageSrc(FEEDBACK_IMAGES_PARTIALY[Math.floor(Math.random()*FEEDBACK_IMAGES_PARTIALY.length)])
        if(assessmentScore > 66) setImageSrc(FEEDBACK_IMAGES_GOOD[Math.floor(Math.random()*FEEDBACK_IMAGES_GOOD.length)])
    }

    useEffect(() => {
        if(useRandomImages){
            selectRandomImageByScore()
        }else{
            selectImageByScore()
        }
    }, [assessmentScore])

    const handleNewTry = (assessmentUserId: string|number) => {
        setDisableButton(true)
        resetAssessment(assessmentUserId)
    }
    
    return (<StyledKakumaGamesContainer
        loadingactivityanswer={false}
        activitystatus={ACTIVITIES_STATUS.END}
        id="highlightable"
    >
        <TopBar max={maxSteps} actual={activityStep} activityType={activityType} isAssessmentEnd={true}/>
        <ContainerAssessmentResult>
                {imageSrc === '' ?
                    <Loader/>
                :
                <>
                    {!theme.endUserAssessment.circularProgressBar.imageInsideProgressBar && 
                        <img 
                            src={imageSrc} 
                            alt={`${assessmentScore < 33 ? _t("feedback_assessment_end.alt_img_feedback_bad") 
                            : (assessmentScore >= 33 ? _t("feedback_assessment_end.alt_img_feedback_partialy") 
                            : _t("feedback_assessment_end.alt_img_feedback_good"))}`} 
                            tabIndex={0}
                        />}
                    <div>
                        <CircularProgressBar
                            assessmentScore={assessmentScore}
                            client={client}
                            imageSrc={imageSrc}
                        />

                        <ContainerResultInfo>
                            <h2
                                tabIndex={0}
                            >
                                {(assessmentScore > 66)  ? _t("feedback_assessment_end.good")
                                : (assessmentScore >= 33) ? _t("feedback_assessment_end.partialy")  
                                : _t("feedback_assessment_end.bad")}
                            </h2>
                            {/* <p tabIndex={0} className='best-try-score'>{`${_t("feedback_assessment_end.best_try_text")}: ${assessmentScore}`}</p> */}
                            {theme.endUserAssessment.circularProgressBar.imageInsideProgressBar && <p>{assessmentScore > 0 ? assessmentScore : '0'}%</p>}
                        </ContainerResultInfo>
                        
                    </div>
                </>
                }
        </ContainerAssessmentResult>
        {theme.endUserAssessment.hasTryAgainButton &&
        <ContainerButton>
            <ButtonCheckResult
                onClick={() => handleNewTry(assessmentUserId)}
                disabled={disableButton}
                feedback={"none"}
            >
                {_t("bottom_bar.button_option4")} {/* check results */}
            </ButtonCheckResult>
        </ContainerButton>}
        </StyledKakumaGamesContainer>
    )
}

export default AssessmentEnd
