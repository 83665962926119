import React, { useEffect, useState } from 'react';
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader } from '../XEduActivitiesStyles';
import HTMLReactParser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import { StyledShortQuestionContainer, StyledShortQuestionOptionContainer } from './ShortQuestionStyles';
import DEFAULT_IMAGE from '../images/placeholder-image.jpg';


const ShortQuestion = ({ setStateMicrogame, activity, client, getLocalPath, courseCode }) => {
    const [answers, setAnswers] = useState([]);
    const [cardimgPath, setImgPath] = useState({})


    const getImagePath = async (cards) => {
		let pathsState = {...cardimgPath}
		if(getLocalPath){
			const paths = await Promise.all(cards.map(card => getLocalPath(card.image, courseCode)))
			paths.forEach((path) => {
				pathsState[path.image] = path
			})
		}else{
			cards.forEach((card) => {
				pathsState[card.image] = card.image_url
			})
		}
		setImgPath(pathsState)
	}

	useEffect(()=> {
		if (activity.options.length > 0) {
			getImagePath(activity.options)
		}
	}, [activity.options])

    useEffect(() => {
        setStateMicrogame && setStateMicrogame({
            options: activity.options.filter((_, index) => answers.includes(index))
        })
    }, [answers])

    const handleOnChange = (e, index) => {
        let answersCopy = []
        answersCopy.push(index)
        setAnswers(answersCopy)
    }

    return (
        <StyledActivityContainer>
			{activity?.title && <StyledActivityHeader><h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}/></StyledActivityHeader>}

            <StyledActivityContent client={client}>
                {activity?.text && <p className='subtitle'  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }} />}

                <StyledShortQuestionContainer>
                    {activity.options.map((option, index) => (
                        <StyledShortQuestionOptionContainer client={client} key={index} activityName={"shortQuestion"} onClick={e => handleOnChange(e,index)} className={answers.includes(index) ? "selected" : ""}>
                            <img className='short-question_img'
                                    alt={"Option Image"}
                                    src={cardimgPath?.[option.image] ?? DEFAULT_IMAGE}
                                    style={{ objectFit: 'fill' }}
                                    onError={(e) => { e.target.onError = null; e.target.src = "/assets/images/default_image.png" }}
                                />
                            <p>{HTMLReactParser(String(option?.title))}</p>
                        </StyledShortQuestionOptionContainer>
                    ))}
                </StyledShortQuestionContainer>
            </StyledActivityContent>

       
        </StyledActivityContainer>
    );
}

export default ShortQuestion;
