import React, { useContext, useState } from "react";
import DOMPurify from "isomorphic-dompurify";
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, StyledFlexWrapActivity, XEduActivityContainer } from "../XEduActivitiesStyles";
import { StyledInputContainer, StyledInput, StyledInputArea, StyledWordCountLabel, StyledTextContainer } from "./InputStyles";
import { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { useAccessibility } from "../../../hooks/useAccesibility";

const Input = ({ activity, setStateMicrogame, client, isLong, channel, ...props }) => {
    const [_t] = useTranslation("kakuma_activities");
    const wordLimit = isLong ? 255 : 36;
    const emptyAnswerStatus = {
        accepted_values: []
    }
	const [answer, setAnswer] = useState([]);
    const theme = useContext(ThemeContext);
    const hasLabel = theme.themeActivities.input.label?.hasLabel;
    const hasLabelTranslation = _t("input.labelInput") === "" ? false : true;
    const showWordCounter = theme.themeActivities.input.wordCounter?.show ? true : false;
    const sanitizeHtml = useAccessibility()

	const saveStudentAnswer = (e) => {
		const updatedAnswer = [...answer];
		const studentAnswer = e.target.value;

        // If answer exceeds limit, do nothing
        if (studentAnswer?.length > wordLimit) return;

		// Update answer written by the student
		updatedAnswer[0] = checkValidWord(studentAnswer);
        setAnswer(updatedAnswer);

        // Check if an answer has been filled before sending it
        if (updatedAnswer?.[0]?.accepted_values) setStateMicrogame(updatedAnswer);
	};

	const checkValidWord = (studentAnswer) => {
		const target = activity?.targets?.[0]
        const validity = { 
			id: target?.id, 
			accepted_values: [studentAnswer],
            order: target?.order
        };

		return validity;
	};

	return (
		<StyledActivityContainer>
			{activity?.title && 
				<StyledActivityHeader>
					<h2 
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}
                        aria-labelledby={`${activity.title}`}
                        tabIndex={0}
                    />
				</StyledActivityHeader>
			}
            <StyledActivityContent client={client} className="subtitle">
                <XEduActivityContainer>

                <StyledFlexWrapActivity channel={channel}>
                {activity?.text && (
                    <>
                        <StyledTextContainer 
                            channel={channel} 
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }} 
                            role="region"
                            aria-labelledby={`${sanitizeHtml(activity?.text)}`}
                        />
                    </>
                )}                    <StyledInputContainer channel={channel} >
                        <StyledWordCountLabel>
                            {hasLabel && hasLabelTranslation && <label htmlFor="inputField" aria-labelledby={_t("input.labelInput")}>{_t("input.labelInput")}</label>}
                            {showWordCounter && 
                                <label aria-live="polite">
                                    {answer[0]?.accepted_values[0]?.length ?? 0}/{wordLimit}
                                </label>}
                        </StyledWordCountLabel>
                        <StyledInput
                            id="inputField"
                            themeActivity={activity.type}
                            channel={channel} 
                            value={answer?.[0]?.accepted_values[0]}
                            onChange={(e) => saveStudentAnswer(e)}
                            aria-labelledby={hasLabel && hasLabelTranslation ? "inputFieldLabel" : undefined}
                            aria-required={true}
                            aria-live="assertive"
                            aria-label={`${answer?.[0]?.accepted_values[0] ? _t("input.aria_label_input") : ''} ${answer?.[0]?.accepted_values[0] ?? ''}`}
                            type="text"
                        />
                    </StyledInputContainer>
                </StyledFlexWrapActivity>
                </XEduActivityContainer>
            </StyledActivityContent>
		</StyledActivityContainer>
	);
};

export default Input;
