import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";

export const StyledScaleRange = styled('input')`
	cursor: pointer;
	width: 100%;
	background: transparent;
	border-radius: 0.1rem;
`

export const StyledExtraInfoDescriptionContainer = styled('div')`
	display: flex;
`;

export const StyledScaleBox = styled('div')<{theme: IThemeProps}>`
	background-color: ${({theme}) => theme.themeActivities.opinionScale?.backgroundColor};
	border-radius: ${({theme}) => theme.themeActivities.opinionScale?.backgroundRadius};
	padding: 16px;
`;

export const StyledScaleFacesBox = styled('div')<{scaleValue: string}>`

	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px auto;
	
	img{
		-webkit-filter: grayscale(100%);
		cursor: pointer;
	}
	img:nth-child(${({scaleValue}) => scaleValue}) {
		-webkit-filter: grayscale(0%);
	}
`;

export const StyledScaleSentence = styled('span')<{theme: IThemeProps}>`
	color: ${({theme}) => theme.themeActivities.opinionScale?.scaleSentence?.color};
	font-family: ${({theme}) => theme.themeActivities.opinionScale?.scaleSentence?.fontFamily};
	font-size: ${({theme}) => theme.themeActivities.opinionScale?.scaleSentence?.fontSize};
	margin-right: 10px;
`;

export const StyledScaleValue = styled('span')<{theme: IThemeProps}>`
	color: ${({theme}) => theme.themeActivities.opinionScale?.scaleValueSelected?.color};
	font-family: ${({theme}) => theme.themeActivities.opinionScale?.scaleValueSelected?.fontFamily};
	font-size: ${({theme}) => theme.themeActivities.opinionScale?.scaleValueSelected?.fontSize};
`;
