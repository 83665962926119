import React from 'react';
import { StyledActivityContent, XEduActivityContainer } from '../../XEduActivitiesStyles';
import { StyledBallSelected, StyledContainerInput, StyledTextContainer } from '../ActivityOptionChoiceStyles';
import DOMPurify from 'isomorphic-dompurify';
import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useAccessibility } from '../../../../hooks/useAccesibility';

const OptionsChoices = ({client, activity, typeActivity, handleOnChange, answers, channel}) => {
    const [_t] = useTranslation("kakuma_activities");
    const sanitizeHtml = useAccessibility();

    return (
        <StyledActivityContent client={client}>
            <XEduActivityContainer channel={channel}>
            {activity?.text && 
                <>
                    <StyledTextContainer 
                    tabIndex={0} 
                    activityName={typeActivity}
                    channel={'mobile'}
                    style={{width: '100%'}}
                    className='subtitle'
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }}
                    role="region"
                    aria-labelledby={`${sanitizeHtml(activity?.text)}`}
                    />
                </>
            }


            {activity.options.map((option, index) => (
                <StyledContainerInput 
                    client={client} 
                    key={index} 
                    activityName={typeActivity} 
                    onClick={e => handleOnChange(e, index)} 
                    className={answers.includes(index) ? "selected" : ""}               
                    onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault(); 
                            handleOnChange(e, index);
                        }
                    }}
                >
                    <StyledBallSelected
                        activityName={typeActivity}
                        className={answers.includes(index) ? "selected" : ""}
                    >
                        <input 
                            aria-label={`${_t("common.aria_label_option")} ${index + 1}:`}
                            type="checkbox" 
                            tabIndex="0"
                            id={`option${index}`} 
                            name="option" 
                            onChange={e => handleOnChange(e, index)} 
                            checked={answers.includes(index)} 
                            aria-live='assertive'
                            role="checkbox"  // Asegura que el contenedor sea reconocido como una casilla de verificación
                            aria-checked={answers.includes(index)}      
                        />
                    </StyledBallSelected>
                    <label 
                        className='optionText'
                        htmlFor={`option${index}`} 
                        id={`label${index}`}
                        onClick={e => handleOnChange(e, index)}
                    >
                        {HTMLReactParser(String(option?.title))}
                    </label>
                </StyledContainerInput>
            ))}
            </XEduActivityContainer>
        </StyledActivityContent>
    );
}

export default OptionsChoices;
