import React from 'react'
import { ContainerProgressBar, ProgressBarComplete, ProgressBarNotComplete } from './ProgressBarStyles'
import { ProgressBarProps } from './types'



const ProgressBar = ({ actual, max }: ProgressBarProps) => {
    return (
        <ContainerProgressBar>
            {[...Array(actual)].map((_, index) => <ProgressBarComplete key={index} />)}
            {[...Array(max - actual)].map((_, index) => <ProgressBarNotComplete key={index} />)}
        </ContainerProgressBar>
    )
}

export default ProgressBar