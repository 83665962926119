const {
    REACT_APP_CLIENT,
    REACT_APP_XEVAL_URL
} = process.env

export enum IClient {
    'mhe' = 'mhe',
    'sek' = 'sek',
    'default' = 'default'
}

export interface _IConfig {
    client: IClient,
    xevalUrl: string
}

let config: _IConfig = {
    client: IClient.default,
    xevalUrl: ""
}

if (REACT_APP_CLIENT) {
    const key = REACT_APP_CLIENT as unknown as IClient;
    if (IClient[key]) {
        config.client = IClient[key];
    }
}

if (REACT_APP_XEVAL_URL) {
    let url = REACT_APP_XEVAL_URL;
    if (url[url.length - 1] === "/") {
        url = url.slice(0, -1)
    }
    config.xevalUrl = url
}

export default config