import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";


export const StyledShortQuestionContainer = styled('div')<{theme: IThemeProps}>`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    .selected{	
        background: ${({theme}) => theme.color[theme?.themeActivities?.shortQuestion?.option?.backgroundSelected].main} !important;
        color: ${({theme}) => theme.themeActivities.shortQuestion?.option?.colorSelected};
    }	
    

`

export const StyledShortQuestionOptionContainer = styled('div')<{theme: IThemeProps}>`
    width: 33%;
    height: 100%;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    margin: 11px;
    padding: 1em;
    border-radius: ${({theme}) => theme.themeActivities.shortQuestion?.option?.borderRadius};
    text-align: center;
    overflow: hidden;
    background: ${({theme}) => theme.themeActivities.shortQuestion?.option?.background};
    color: ${({theme}) => theme.color[theme?.themeActivities?.shortQuestion?.option?.color].main};
    font-size: ${({theme}) => theme?.themeActivities?.shortQuestion?.option?.fontSize};

    img{
        flex-shrink: 0;
        width: 100px;
        height: 100px;
    }

    &:hover{
        cursor: pointer;
    }
    

`;
