import * as React from 'react';
import { ContainerFeedbackBar } from '../BottomBarStyles';
import { useTranslation } from 'react-i18next';
import { IFeedbackProps } from '../types';


export default function FeedbackBar ({client, feedback}: IFeedbackProps) {
    const [_t] = useTranslation("preview");
  return (
        <>
            <ContainerFeedbackBar
                feedback={feedback.type}
            >
                <p className="title-feedback" tabIndex={0} aria-live="assertive">
                    {_t(`feedback_answer_activity_title.${feedback.type}`)}
                </p>
                <p className="generic-feedback" tabIndex={0} aria-live="assertive">
                    {feedback?.generic_feedback_title ?? _t(`feedback_answer_activity_generic.${feedback?.type}`)}
                </p>
            </ContainerFeedbackBar>
        </>
  );
}
