import styled from "styled-components";
import { ITheme, IThemeProps } from "../../../themes/types";
import { XEduActivityContainer } from "../XEduActivitiesStyles";


export const StyledOrderChronologyContainer = styled('div')<{channel: string, theme: IThemeProps, themetype:string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0.5em;
    width: ${({channel, theme, themetype}) => channel === "web" &&  themetype === 'mhe_2023' ? '50%  !important' : channel === "web" && themetype === 'mhe_2023' ? '50%' : '100%'};
    flex-direction: ${({channel, theme}) => channel === "mobile" ? 'row' : theme.themeActivities.orderChronology.webDirection ? 'column' : 'row'};
    flex-wrap: ${({channel, theme}) => channel === "mobile" ?'wrap' : 'nowrap'};
`


export const StyledOrderChronologyCard = styled('div')<{ channel: string, theme: IThemeProps}>`
    width: ${({theme, channel}) => channel === "web" && theme.themeActivities.orderChronology.cardWidthWeb ? theme.themeActivities.orderChronology.cardWidthWeb : theme.themeActivities.orderChronology.cardWidthMobile} !important;
    height: ${({theme, channel}) => channel === "web" && theme.themeActivities.orderChronology.cardHeightWeb ? theme.themeActivities.orderChronology.cardHeightWeb : theme.themeActivities.orderChronology.cardHeightMobile}!important;
    margin: ${({theme}) => theme.margin.short};
    border-radius:  ${({theme}) => theme.themeActivities.orderChronology?.cardRadius};
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

`

export const StyledOrderChronologyCardOption = styled(StyledOrderChronologyCard)<{ isSelected: boolean, theme: IThemeProps}>`
    background-color: ${({theme, isSelected}) => isSelected && theme.themeActivities.orderChronology.option.backgroundColorEmptyCardSelected ? theme.themeActivities.orderChronology.option.backgroundColorEmptyCardSelected : theme.themeActivities.orderChronology.option.backgroundColor};
    border: ${({theme, isSelected}) => isSelected && theme.themeActivities.orderChronology.option.borderEmptyCardSelected ? theme.themeActivities.orderChronology.option.borderEmptyCardSelected : '2px solid transparent'};
`

export const StyledOrderChronologyCardTarget = styled(StyledOrderChronologyCard)<{ isSelected: boolean, theme: IThemeProps }>`
    border: ${({theme}) => theme.themeActivities?.orderChronology?.target?.border};
    width: 100%;

    .orderChronology_card_text {
        width: 100%;
        font-size: ${({ theme }) => theme.themeActivities.orderChronology.target?.size ?? theme.styles.text.subtitle.size};
        font-weight: ${({theme}) => theme.themeActivities.orderChronology.target?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
        font-family: ${({theme}) => theme.themeActivities.orderChronology.target?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
        line-height: ${({ theme }) => theme.themeActivities.orderChronology.target?.lineHeight ?? theme.styles.text.subtitle.lineHeight};
        text-align: center;
        background-color: ${({theme}) => theme.themeActivities.orderChronology.target?.backgroundColor};
        color: ${({theme}) => theme.themeActivities.orderChronology.target?.colorOnSelect};
        border-radius:  ${({theme}) => theme.themeActivities.orderChronology?.cardRadius};
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &:hover{
        background-color: ${({theme, isSelected}) => isSelected ? theme.themeActivities.orderChronology.target.backgroundColorOnDrop : ''}
    }
`

export const StyledSeparatorLine = styled('div')<{theme: IThemeProps}>`
    height: 1px;
    width: 100%;
    background-color: ${({theme}) => theme.themeActivities.orderChronology?.lineSeparator?.useLine ? theme.themeActivities.orderChronology?.lineSeparator?.color : 'transparent'};
    margin: ${({theme}) => theme.margin.short} 0;
`


export const StyledOrderChronologyCardInfoContainer = styled('div')<{ isSelected: boolean, channel: string, hasText: boolean, hasImage: boolean, isInPosition: boolean, theme: IThemeProps}>`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: ${({channel, theme})=> channel === "mobile" ? 'column' : theme.themeActivities?.orderChronology?.webDirection ? 'row' : 'column'};
    width: 100%;
    height: 100%;
    border-radius:  ${({theme, channel}) => {
        const cardRadius = theme.themeActivities.orderChronology?.cardRadius ?? "0"
        if(channel === "mobile") return `${cardRadius}  ${cardRadius} 0 0 `
        if(channel === "web") return `${cardRadius} 0 0 ${cardRadius}`
    }};    

    background-color: ${({theme, isSelected,isInPosition}) => isInPosition ? theme.themeActivities.orderChronology.option.backgroundColorOnSelect  : isSelected ? theme.themeActivities.orderChronology.option.backgroundColorOnSelect : theme.themeActivities.orderChronology.option.backgroundColor};
    color: ${({theme, isSelected, isInPosition}) => isInPosition ? theme.themeActivities.orderChronology.option.colorOnSelect : isSelected ? theme.themeActivities.orderChronology.option.colorOnSelect : theme.themeActivities.orderChronology.option.color} !important;
    
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        p{
            border-radius:  ${({theme}) => theme.themeActivities.orderChronology?.cardRadius};
            font-size: ${({ theme }) => theme.themeActivities.orderChronology.option?.size ?? theme.styles.text.subtitle.size};
            font-weight: ${({theme}) => theme.themeActivities.orderChronology.option?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
            font-family: ${({theme}) => theme.themeActivities.orderChronology.option?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
            line-height: ${({ theme }) => theme.themeActivities.orderChronology.option?.lineHeight ?? theme.styles.text.subtitle.lineHeight};
            border-radius:  ${({theme, channel}) => {
                const cardRadius = theme.themeActivities.orderChronology?.cardRadius ?? "0"
                if(channel === "mobile") return `${cardRadius} ${cardRadius} 0 0 `
                if(channel === "web") return `0 ${cardRadius} 0 ${cardRadius}`
            }};   

            display: -webkit-box !important;
            -webkit-line-clamp: ${({channel, hasText}) =>
            channel === 'mobile' && hasText ? '2'
            : channel === 'mobile' && !hasText ? '5' 
            : '5' } !important;
            -webkit-box-orient: vertical !important;  
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            word-wrap: break-word;    
        }
    }
    

    img{
        border-radius:  ${({theme, channel}) => {
            const cardRadius = theme.themeActivities.orderChronology?.cardRadius ?? "0";
            if(channel === "mobile") return `${cardRadius}  ${cardRadius} 0 0 `
            if(channel === "web") return `${cardRadius} 0 0 ${cardRadius}`
        }};
        width: ${({channel, hasText, theme})=> 
            channel === 'mobile' ? '100%' 
            : theme.themeActivities.orderChronology.webDirection && hasText ? '50%' 
            : theme.themeActivities.orderChronology.webDirection && !hasText ? '100%' 
            : '100%'  };
        height: ${({hasText, channel, theme})=> 
            channel === 'mobile' && hasText ? '50%' 
            : channel === 'web' && theme.themeActivities.orderChronology.webDirection ? '100%'
            : channel === 'web' && !theme.themeActivities.orderChronology.webDirection && hasText ? '50%' :
            '100%' };
        flex-shrink: 0;
        object-fit: contain;

    }
`


export const StyledCardsContainer = styled('div')<{channel: string, theme: IThemeProps}>`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    min-height: 150px !important;
    overflow: hidden;
    padding-bottom: 2em;
    overflow-x: hidden;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex-direction: ${({channel, theme}) => channel === "mobile" ? 'column' : theme.themeActivities?.orderChronology?.webDirection ?? 'column'};
`
