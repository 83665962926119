import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";

export const StyledActivityOptionContainer = styled('div')<{theme: IThemeProps, activityName: string}>`
    ${({theme, activityName}) => {
        const labelBorder =  theme.themeActivities[activityName].option.labelBorder
        const labelSelectedBorder =  theme.themeActivities[activityName].option.labelSelectedBorder

        return`
            --option-label-border: ${labelBorder};
            --option-label-selected-border: ${labelSelectedBorder};
        
        `
    }}
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    input {
        display: none;
    }

    label{
        cursor: pointer;
        padding: 3px;
        margin: 2px;
        width: calc(100% - 4px - 6px - 6px);
        height: 150px;
        border: var(--option-label-border);
        border-radius: 8px;
        max-height: 200px;

        img{
            width: calc(100%);
            max-height: 140px;
        }

        &.checked {
            border: var(--option-label-selected-border);
        }
    }
`

export const StyledMaxOptions = styled('p')`
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: 2rem;
    color: #a0a0a0; 
`
