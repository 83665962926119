import React, { useEffect, useState } from 'react';
import { LoaderStyled, LoaderContainer } from "./LoaderStyles"
import { Box, LinearProgress, Typography } from '@mui/material';

export interface ILoaderProps {
    activitiesLoaded?: boolean
    showPercentage?: boolean
}

export const LinearProgressWithLabel = ({value} : any) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    );
  }



export const Loader = ({ activitiesLoaded, showPercentage }: ILoaderProps) => {
    const [progress, setProgress] = React.useState(10);
    
    
    useEffect(() => {
        if(!activitiesLoaded){
            const timer = setInterval(() => {
              setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
            }, 800);
            return () => {
              clearInterval(timer);
            };
        }
    }, [activitiesLoaded]);
  
    

    return(
        <>
        <LoaderContainer>
            <LoaderStyled/>
            {showPercentage && <h2>%{progress}</h2>}
        </LoaderContainer>
        </>
    )
}



