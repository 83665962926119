import React,{  useContext } from 'react'
import { StyledProgressBarContainer } from './AssessmentEndStyle'
import { ICircularProgressBarProps } from './type'
import ThemeContext from '../../providers/ThemeProvider/ThemeContext';
import { useTranslation } from 'react-i18next';

export default function CircularProgressBar({assessmentScore, imageSrc}: ICircularProgressBarProps) {
    const {theme} = useContext(ThemeContext);
    const [_t] = useTranslation("preview");
  return (
        <StyledProgressBarContainer score={assessmentScore} tabIndex={0}>
            {!theme.endUserAssessment.circularProgressBar.imageInsideProgressBar && <p className="accurate-text">{_t("assessment_end.accurate")}</p>}
            {theme.endUserAssessment.circularProgressBar.imageInsideProgressBar && 
            <img 
              src={imageSrc}                             
              alt={`${assessmentScore < 33 ? _t("feedback_assessment_end.alt_img_feedback_bad") 
                  : (assessmentScore >= 33 ? _t("feedback_assessment_end.alt_img_feedback_partialy") 
                  : _t("feedback_assessment_end.alt_img_feedback_good"))}`} 
            />}
        </StyledProgressBarContainer>
  )
}
