import { ITheme } from "./types"

export const DefaultThemeWeb: ITheme = {
    themeType: 'basic',
    header: {
        display: false
    },
    loaderSpinner: {
        showPercentage: false
    },
    topBar: {
        direction: "row-reverse",
        croos: {
            display: true
        },
        progressBar: {},
        progressDetails: {
            hasSlashBetweenCounter: false
        }
    },
    containerActivity: {
        paddingX: "none",
        paddingY: "short",
        border: '3px solid',
    },
    color: {
        primary: {
            main: '#43a1a2',
            contrastText: "white",
            dark: '#163250'
        },
        secondary: {
            main: "#214f61",
            contrastText: "white"
        },
        background: {
            main: "white",
            paper: "rgb(242, 242, 242)"
        }
    },
    margin: {
        short: "0.5rem",
        default: "1rem",
        long: "1.5rem",
        superLong: "2rem"
    },
    styles:{
        text: {
            subtitle: {
                color: '#214f61',
                size: '1em',
                fontFamily: 'Roboto',
                fontWeight: '400',
                addMargin: true
            },
            title: {
                color: '#214f61',
                size: "1.2em",
                fontWeight: 'none',
                fontFamily: 'Roboto',
                backgroundColor: "#f2f2f2",
                borderRadius: "10px",
                addPadding: true
            }
        },
        activityContent:{
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "3px solid rgb(242, 242, 242)"
        }
    },
    bottomBar: {
        button:{
            useFeedbackBackgroundColor: true,
            backgroundColor: 'primary',
            backgroundColorEnabled: '#43a1a2',
            backgroundColorDisabled: '#43a1a2d1',
            colorEnabled: 'white',
            colorDisabled: 'white',
            width: '90%',
            borderRadius: '10px',
            opacityDisabled: '0.9',
            fontSize: '1em',
        },
        feedback:{
            borderRadius: "10px",
            flexDirection: "column",
            title:{
                color: "#0A223D",
                fontSize: "1.2em",
                fontWeight: "bold",
            },
            subtitle:{
                color: "grey",
                fontSize: "1em",
            },
            good:"#2df286",
            bad: "#df2e24",
            partialy: "#f6ab0e"
        },
        useFeedbackImage: true,
        useFeedbackBar: false,
    },
    endUserAssessment:{
        hasTryAgainButton: false,
        circularProgressBar:{
            imageInsideProgressBar: true,
            circularSize: '159px',
            textFont: 'Roboto',
            textSize: '3.25px',
            accurateSize: '39.75px',
            accurateFont: 'Roboto',
            accurateFontWeight: '700',
            accurateColor: '#0A0A0A',
            textColor: 'Gray'
        },
        title:{
            fontSize: '2em',
            fontFamily: 'Roboto',
            align: 'left'
        },
        percentage: {
            fontSize: '20px',
        },
        good:"#2df286",
        bad: "#df2e24",
        partialy: "#f6ab0e"
    },
    themeActivities:{
        multipleChoice: {
            option: {
                hasMarginHorizontal: true,
                fontFamily: "Roboto",
                borderRadius: "10px",
                fontSize: "1em",
                border: '0px',
                background: "transparent",
                color: "white",
                point: {
                    size: "22px",
                    type: "check",
                    borderRadius: "20%",
                    innerBallSize: '16px',
                    color: "#43a1a2",
                    border: "2px solid #43a1a2",
                    innerBallBorderRadius: "20%"
                }
            }
        },
        singleChoice: {
            option: {
                fontFamily: "Roboto",
                background: "transparent",
                color: "#43a1a2",
                fontSize: "1em",
                borderRadius: "10px",
                border: '0px',
                fontWeightHover: '600',
                hasMarginHorizontal: true,
                point: {
                    size: "22px",
                    type: "filling",
                    borderRadius: "50%",
                    innerBallSize: '16px',
                    color: "#43a1a2",
                    border: "2px solid #AEAEAE",
                    innerBallBorderRadius: "50%",
                    borderSelected: "2px solid #214f61"
                },
            }
        },
        trueFalse: {
            option: {
                fontFamily: "Roboto",
                background: "transparent",
                color: "#43a1a2",
                fontSize: "1em",
                borderRadius: "10px",
                border: '0px',
                hasMarginHorizontal: true,
                point: {
                    size: "22px",
                    type: "filling",
                    borderRadius: "50%",
                    innerBallSize: '16px',
                    color: "#43a1a2",
                    innerBallBorderRadius: "50%",
                    border: "2px solid #214f61"
                }
            }
        },
        imageChoice: {
            option:{
                labelBorder: "solid #e0e0e0 3px",
                labelSelectedBorder: "red dashed 3px",
            }
        },
        textChoice:{
            option:{
                labelBorder: "solid #e0e0e0 3px",
                labelSelectedBorder: "red dashed 3px",
            }
        },
        imagePairing: {
            optionsBorder: "4px solid #e1e1e1",
            optionsSelected: "1px solid #efefef",
        },
        textPairing: {
            optionsBorder: "4px solid #e1e1e1",
            optionsSelected: "1px solid #efefef",
        },
        cardChoice: {},
        froggyJump: {},
        flashCards: {
            normalCard: {
                fontFamily: "Roboto",
                border: '1px solid #ececec',
                backgroundColor: '#FFFFFF',
                title:{
                    color: '#385985',
                    fontSize: '25px',
                    fontWeight: 'bold',
                },
                subTitle:{
                    color: '#385985',
                    fontSize: '18px',
                    fontWeight: '400',
                }
            },
            initialCard: {
                fontFamily: "Roboto",
                color: '#385985',
                fontSize: '18px',
                fontWeight: '400',
                backgroundColor: '#FFFFFF',
            },
            finalCard:{
                fontFamily: "Roboto",
                color: '#9f9f9f',
                fontSize: '18px',
                fontWeight: '400',
                backgroundColor: '#fafafa'
            }
        },
        wordSearch: {
            timer:{
                backgroundColor: 'rgb(0,126,123)',
                color: 'white',
                fontSize: '20px',
                borderRadius: '15px',
            },
            wordCounter:{
                useWordCounter: false
            }
        },
        wordCatcher: {
            timer:{
                backgroundColor: 'rgb(0,126,123)',
                color: 'white',
                fontSize: '20px',
                borderRadius: '15px',
            },
            marker:{
                findedWordColor: '#006B6C',
                notFindedWordColor: '#F58000'
            }
        },
        seekAndFind: {
            timer: {
                backgroundColor: 'rgb(0,126,123)',
                color: 'white',
                fontSize: '20px',
                borderRadius: '15px',
            }
        },
        fillTheBlanks: {
            input:{
                backgroundColor: '#fbfbfb',
                color: '#818a96',
                fontFamily: 'PoppinsRegular',
                borderBottom: '#385985',
                fontSize: '1.2em',
                maxWidth: '150px',
                fontWeight: '600',
            },
            span: {
                color: '#818a96',
                fontFamily: 'PoppinsRegular',
                fontSize: '1.1em',
                fontWeight: '400',
            },
            paragraph: {
                padding: '1em 1em 2em 1em',
                backgroundColor: 'white',
                borderRadius: '8.08px'
            }
        },
        input: {
            text: {
                fontWeight: "400",
                backgroundColor: "white",
                fullwidth: true,
            },
            input: {
                borderRadius: "20px",
                border: "1px solid #43a1a2",
                borderOnFocus: "1px solid #214f61",
                direction: 'column-reverse',
                height: "30px",
                hasMargin: true
            },
            label:{
                color: "#000000",
                fontSize: "12px",
                fontWeight: "600"
            },
            wordCounter:{
                color: "#214f61",
                fontSize: "14px",
                fontWeight: "600"
            }
        },
        opinionScale: {
            backgroundColor: '#f2f2f2',
            backgroundRadius: '8px',
            scaleSentence: {
                color: '#D5D5D5',
                fontFamily: 'PoppinsMedium',
                fontSize: '15px'    
            },
            scaleValueSelected:{
                color: '#818a96',
                fontFamily: 'PoppinsRegular',
                fontSize: '15px'    
            }
        },
        correspondence: {
            webDirection: 'column',
            steps: {
                showDotStepper: true,
                color: '#43a1a2'
            },
            cards:{
                backgroundColor: '#FFFFFF',
                color: "#000000",
                fontSize: "1.5em",
                fontWeight: "600",
                lineHeight: '22.61px',
                separation: "8",
                shadow: "0px 0px 15px 0px #0000000D",
                border: '1px solid transparent',
                borderRadius: '11.9px'
            },
            options:{ 
                width: '253px',
                height: '45px',
                backgroundColor: '#FFFFFF',
                color: "#214f61",
                fontSize: "1.2em",
                fontWeight: "400",
                lineHeight: '19px',
                border: '1px solid #43a1a2',
                borderRadius: '70px',
                backgroundColorHover: '#43a1a2',
                colorHover: '#FFFFFF'
            },
        },
        deduction: {
            containerBg: 'unset',
            containerBoxShadow: 'unset',
            containerBorder: '3px solid #f2f2f2',
            containerRadius: '20px',
            option:{
                background: '#214f61',
                color: 'white',
            },
            optionInput:{ 
                allBorders: false,
                border: '1px solid #e5e5e5',
            },
            text: {
                color: '#0A223D',
                lineHeight: '16px'
            },
            
        },
        orderChronology: {
            cardWidthMobile: '90px',
            cardHeightMobile: '120px',
            cardWidthWeb: '120px',
            cardHeightWeb: '160px',
            cardRadius: '8px',
            showTargetOnSelect: false,
            option:{
                color: "white",
                border: '2px dashed #8C8C8C',
                backgroundColor: '#214f61',
                colorOnSelect: 'white',
                backgroundColorOnSelect: '#43a1a2',
                backgroundColorEmptyCardSelected: 'transparent',
                borderEmptyCardSelected: '2px dashed #E5E5E5',
            },
            target:{
                border: '2px dashed #214f61',
                backgroundColorOnDrop: '#43a1a2',
                backgroundColorOnSelect: 'transparent',
                backgroundColor: 'transparent',
                colorOnSelect: '#214f61',
            },
            lineSeparator: {
                useLine: false,
                color: '#CCCCCC'
            }
        },
        shortQuestion: {
            option: {
                colorSelected: 'white',
                background: "transparent",
                backgroundSelected: "background",
                color: "primary",
                fontSize: "14px",
                borderRadius: "8px",
                border: '0px',
            }
        },
        match:{
            cardWidth: '100px',
            cardHeight: '150px',
            cardRadius: '10px',
            showTargetOnSelect: false,
            option:{
                color: 'white',
                border: '2px dashed #214f61',
                backgroundColor: '#43a1a2',
                colorOnSelect: 'white',
                backgroundColorOnSelect: '#43a1a2',
                borderEmptyCardSelected: '2px dashed #214f61',
            },
            target:{
                border: '2px dashed #214f61',
                backgroundColorOnDrop: 'transparent',
                backgroundColorOnSelect: '#43a1a2',
                backgroundColor: 'transparent',
                colorOnSelect: 'white',
                color: 'rgba(10, 34, 61, 0.5)',
                size: '16px',
            },
        }
    }
}
