import React from 'react'
import Canvas from './Canvas'
import "./FroggyJump.scss";
import Game from './gameObjects/Game';
import back from "./images/fondo2.png"

import { IonAlert } from '@ionic/react';
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader } from '../XEduActivitiesStyles';
import HTMLReactParser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';

export default class FroggyJump extends React.Component {
    game = null;
    canvas = null;
    mouse = {
        x: 0,
        y: 0
    };

    pressedKeys = [];

    constructor(props) {
        super(props);
        this.state = {
            isRendering: true,
            inAnimation: false,
            inInerOption: false,
            title: null,
            showAlert: false
        };
        this.i18n = props.i18n
    }


    drawTick = (context, canvas) => {
        if (this.game === null) return;

        this.game.draw();
    }

    gameTick = () => {
        if (this.game === null) return;
        this.game.update(this.mouse, this.pressedKeys);
        this.setState({
            ...this.state,
            inInerOption: this.game.isOptionSelected(this.mouse)
        });
    }

    handleOnInitCanvas = (context, canvas) => {
        this.canvas = canvas;
        this.game = new Game(
            context,
            canvas,
            {
                setInAnimationFunc: this.setInAnimation,
                setTitle: this.setTitle,
                onGameFinish: this.onGameFinish
            },
            this.i18n
        );
        this.game.setOptions(this.props.listOptions)

        this.setState({
            ...this.state,
            isRendering: true
        })
    }

    onGameFinish = (data) => {
        this.props.setStateMicrogame(data);
        setTimeout(() => {
            this.setState({
                ...this.state,
                isRendering: false
            })
        })
        this?.props?.end && this.props.end(data)
    }

    setInAnimation = (inAnimation) => {
        this.setState({
            ...this.state,
            inAnimation: inAnimation
        });
    }

    setTitle = (title) => {
        setTimeout(() => {
            this.setState({
                ...this.state,
                title: title
            })
        })

    }

    handleOnClick = () => {
        this.game.click(this.mouse);
    }

    handleOnMouseMove = (mouse) => {
        this.mouse = mouse;
    }

    handleOnKeyPress = (key) => {
        if (!this.pressedKeys.includes(key)) {
            this.pressedKeys.push(key)
        }
    }

    handleOnKeyUp = (key) => {
        if (this.pressedKeys.includes(key)) {
            this.pressedKeys.pop(key)
        }
    }

    render() {
        return <StyledActivityContainer>

            {this.props?.activity?.title && <StyledActivityHeader><h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props?.activity?.title) }}/></StyledActivityHeader>}

            <StyledActivityContent client={this.props?.client}>
                {this.props?.activity?.text && <p className='subtitle'  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props?.activity?.text) }} />}
            
                <div className='container-froggy'>
                    <div className="title" style={{ backgroundImage: back }}>
                        <h3>{this.state.title}</h3>
                    </div>
                    <div className='container-canvas'>
                        <Canvas
                            className={"canvas-froggy-jump" + (this.state.inInerOption ? " pointer" : "")}
                            drawTick={this.drawTick}
                            gameTick={this.gameTick}
                            isRendering={this.state.isRendering}
                            onInitCanvas={this.handleOnInitCanvas}
                            onClick={this.handleOnClick}
                            onMoveMouse={this.handleOnMouseMove}
                            onKeyPress={this.handleOnKeyPress}
                            onKeyUp={this.handleOnKeyUp}
                            pather={this}
                        />
                        <button className='information' onClick={() => this.setState(() => ({ showAlert: true }))}>i</button>
                    </div>


                </div>
                <IonAlert
                    isOpen={this.state.showAlert}
                    onDidDismiss={() => this.setState(() => ({ showAlert: false }))}
                    header={'Copyright'}
                    message={'Art from GameArtGuppy.com'}
                    buttons={['OK']}
                >
                </IonAlert>
            </StyledActivityContent>

        </StyledActivityContainer>
    }
}

