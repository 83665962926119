import React from 'react'
import ProgressBar from '../../components/ProgressBar/ProgressBar'
import { ContainerProgressBar, ContainerProgressBarDetails, ImageCrossProgressBar, IndexActualAnswer, IndexProgressBar } from './TopBarStyles'
import { ITopBarProps } from './types'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'



const TopBar = ({ actual, max, activityType, isAssessmentEnd }: ITopBarProps) => {
    const [_t] = useTranslation("preview");
    const theme = useContext(ThemeContext);
    const hasTitle = theme?.topBar.progressDetails?.hasTitle ??  false;
    const hasCounter = theme?.topBar.progressDetails?.hasCounter ?? false;
    const hasSlashBetweenCounter = theme?.topBar.progressDetails?.hasSlashBetweenCounter ??  false;
    return (
        <ContainerProgressBar>
            <ContainerProgressBarDetails>   
                <IndexActualAnswer tabIndex={0}>
                    <p>{`${hasCounter ? `0${actual}. ` : ''}`}</p>
                    <p>{`${hasTitle && !isAssessmentEnd && activityType ?  _t(`progress_bar_title.${activityType}`) : ''}`}</p>
                </IndexActualAnswer>
                <IndexProgressBar  tabIndex={0} aria-label={`${_t(`progress_bar_title.aria_label_progressbar_activity1`)} ${actual} ${_t(`progress_bar_title.aria_label_progressbar_activity2`)} ${max}}`}>
                    {hasSlashBetweenCounter ? `${actual > 9 ? actual : `0${actual}`} / ${max > 9 ? max : `0${max}`}`
                    :`${actual} of ${max}`}
                </IndexProgressBar>  
            </ContainerProgressBarDetails>
            <ProgressBar actual={actual} max={max} />
            <ImageCrossProgressBar src="https://xeval2-backend.mhe.ximdex.net/lib/images/close-24px.svg" />

        </ContainerProgressBar>
    )
}

export default TopBar
