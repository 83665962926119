import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";


export const StyledAlphabetTableGame = styled('div')`
    width: 100%;
    height: fit-content !important;
    margin-top: 15px;
    display:flex; 
    align-items: center; 
    flex-direction: column;
    position: relative;

    @media (max-width: 992px) {
        margin-top: 09px;
    }


`

export const StyledGame = styled('div')<{theme: IThemeProps, channel: string}>`
    width: 100%;
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: row;
    width:80%;
    
    @media (max-width: 992px) {
        flex-direction: ${(({theme, channel}) => channel === 'web' && theme.themeActivities.wordSearch?.webDirection ? theme.themeActivities.wordSearch?.webDirection : 'column')};
        width: 100%;
    }
    
    .gameCanvas {
        display: flex;
        align-items: center; 
        justify-content: center; 
        width: 100%;
        
        canvas {
            width: 85% !important;
            @media (min-width: 992px) {
                width: 90% !important;
            }
        }
    }
`

export const StyledGameInfoContainer = styled('div')<{theme: IThemeProps}>`
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;            

    @media (max-width: 992px) {
        width: 100%;
    }

    p {
        margin: 0;
    }

    .timer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px;
        background-color: ${({theme}) => theme.themeActivities.wordSearch.timer?.backgroundColor};
        color: ${({theme}) => theme.themeActivities.wordSearch.timer?.color};
        font-size: ${({theme}) => theme.themeActivities.wordSearch.timer?.fontSize};
        border-radius: ${({theme}) => theme.themeActivities.wordSearch.timer?.borderRadius};
        text-align: center;
        width: 90%;

        @media (max-width: 992px) {
            width: 60%;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        img{
            margin-right: 5px;
        }
    }


}
`


export const StyledWordsCounterContainer = styled('div')<{theme: IThemeProps, wordFinded: string}>`
    display: ${({theme}) => theme.themeActivities.wordSearch.wordCounter.useWordCounter ? 'flex' : 'none'};
    align-items: center;

    .words-founded-text{
        color: ${({theme}) => theme.themeActivities.wordSearch.wordCounter.text?.color ?? theme.styles.text.subtitle.color};
        font-weight: ${({theme}) => theme.themeActivities.wordSearch.wordCounter.text?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
        font-size: ${({theme}) => theme.themeActivities.wordSearch.wordCounter.text?.fontSize ?? theme.styles.text.subtitle.size};
        line-height: ${({theme}) => theme.themeActivities.wordSearch.wordCounter.text?.lineHeight ?? theme.styles.text.subtitle.lineHeight};
        font-family: ${({theme}) => theme.themeActivities.wordSearch.wordCounter.text?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
    }

    .words-founded-counter{
        margin-left: 10px;
        padding: ${({theme}) => theme.margin.short};
        color: ${({theme}) => theme.themeActivities.wordSearch.wordCounter?.counter?.color ?? theme.styles.text.subtitle.color};
        font-weight: ${({theme}) => theme.themeActivities.wordSearch.wordCounter?.counter?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
        font-size: ${({theme}) => theme.themeActivities.wordSearch.wordCounter?.counter?.fontSize ?? theme.styles.text.subtitle.size};
        line-height: ${({theme}) => theme.themeActivities.wordSearch.wordCounter?.counter?.lineHeight ?? theme.styles.text.subtitle.lineHeight};
        font-family: ${({theme}) => theme.themeActivities.wordSearch.wordCounter.counter?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
        border-radius: ${({theme}) => theme.themeActivities.wordSearch.timer?.borderRadius};
        background-color: ${({theme, wordFinded}) => wordFinded ? theme.themeActivities.wordSearch.wordCounter?.counter?.backgroundColorWordFinded : theme.themeActivities.wordSearch.wordCounter?.counter?.backgroundColor};

    }



`
