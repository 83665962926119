import React from 'react';
import { StyledActivityContent, StyledFlexWrapActivity, XEduActivityContainer } from '../../XEduActivitiesStyles';
import { StyledBallSelected, StyledContainerInput, StyledTextContainer, StyledOptionButton, StyledButtonsContainer } from '../ActivityOptionChoiceStyles';
import DOMPurify from 'isomorphic-dompurify';
import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useAccessibility } from '../../../../hooks/useAccesibility';


const OptionsWithButtons = ({channel, client, activity, typeActivity, handleOnChange, answers}) => {
    const [_t] = useTranslation("kakuma_activities");
    const sanitizeHtml = useAccessibility()

    return (
        <StyledActivityContent client={client}>
            <XEduActivityContainer channel={channel}>
                <StyledFlexWrapActivity channel={channel}>
                    {activity?.text && 
                        <>
                            <StyledTextContainer 
                                tabIndex={0} 
                                activityName={typeActivity} 
                                channel={channel} 
                                className='subtitle'  
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }} 
                                role="region"
                                aria-labelledby={`${sanitizeHtml(activity?.text)}`}
                            />
                        </>
                    }

                    <StyledButtonsContainer channel={channel}>
                        {activity.options.map((option, index) => (
                            <StyledOptionButton 
                                key={index} 
                                activityName={typeActivity} 
                                onClick={e => handleOnChange(e, index)} 
                                checked={answers.includes(index)}
                                role="button" 
                                aria-live='assertive'
                                aria-label={`${_t("common.aria_label_option")} ${index + 1}: ${sanitizeHtml(option?.title || '')}`}
                                tabIndex="0" 
                                onKeyDown={e => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        handleOnChange(e, index);
                                    }
                                }}
                            >
                                {HTMLReactParser(String(option?.title))}
                            </StyledOptionButton>
                        ))}
                    </StyledButtonsContainer>
                </StyledFlexWrapActivity>
            </XEduActivityContainer>
        </StyledActivityContent>

    );
}

export default OptionsWithButtons;
