import styled from "styled-components";
import { IThemeProps } from "../../themes/types";


export const StyledActivityContainer = styled('div')`	
    display: flex;
    flex-direction: column;
    width: 100%;	
    height: 90%;
    margin: var(--margin-long);	
    flex-grow: 1;
`	

export const StyledActivityHeader = styled('div')<{theme: IThemeProps}>`
    h2 {
        color: ${({ theme }) => theme.styles.text.title.color};
        font-size: ${({ theme }) => theme.styles.text.title.size};
        background-color: ${({ theme }) => theme.styles.text.title.backgroundColor};
        font-weight: ${({theme}) => theme.styles.text.title.fontWeight};
        font-family: ${({theme}) => theme.styles.text.title.fontFamily};
        line-height: ${({ theme }) => theme.styles.text.title.lineHeight};
        margin-bottom: ${({ theme }) => theme.margin.short};
        padding:  ${({ theme }) => theme.styles.text.title.addPadding ? 'var(--margin-default)' : '0'};
        border-radius: ${({ theme }) => theme.styles.text.title.borderRadius ?? "0px"};
        text-align: left;
        vertical-align: middle;
    }
`


export const StyledActivityContent = styled('div')<{theme: IThemeProps, client: string, channel: string}>`
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.styles?.activityContent?.borderRadius ?? "0px"};
    border: ${({theme})=> theme.styles?.activityContent?.border ?? 'none'};
    margin: 0;
    padding: 0 0.1em;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: unset;

    @media screen and (max-width: 600px) {
        height: 5px;
    }

    .subtitle{
        font-family: ${({theme}) => theme.styles.text.subtitle.fontFamily};
        font-size: ${({ theme }) => theme.styles.text.subtitle.size};
        font-weight: ${({ theme }) => theme.styles.text.subtitle.fontWeight};
        line-height: ${({ theme }) => theme.styles.text.subtitle.lineHeight};
        color: ${({ theme }) => theme.styles.text.subtitle.color};
        text-align: left;
        padding:  ${({ theme }) => theme.margin.short};
        margin-bottom: ${({theme}) => theme.margin.default};
        font-weight: ${({client}) => client === "sek" ? 600 : 0};
        background-color: white;
    }

    .optionText{
        span{
            background-color: transparent !important;
        }
        font-family: ${({theme}) => theme.styles.text.subtitle.fontFamily};
        font-size: ${({ theme }) => theme.styles.text.subtitle.size};
        font-weight: ${({ theme }) => theme.styles.text.subtitle.fontWeight};
        color: ${({ theme }) => theme.styles.text.subtitle.color};
        text-align: left;
        margin: ${({ theme }) => theme.margin.short};
    }


`


export const StyledFlexWrapActivity = styled('div')<{channel: string}>`
    display: flex;
    flex-direction: row;
    flex-wrap: ${({channel}) => channel === "mobile" ? 'wrap' : 'nowrap'};
    width: 100%;
    justify-content: space-evenly;

 `;


 export const XEduActivityContainer = styled('div')<{theme: IThemeProps, channel: string}>`
    min-height: 100% !important;
    overflow-y: scroll;
    padding-bottom: 2em;
    overflow-x: hidden;
    box-sizing: border-box;


    @media screen and (max-width: 600px) {
        min-height: 150px !important;
    }


    /* Width */
    &::-webkit-scrollbar {
        width: 6px;
        margin: 1em;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 4px ${({theme}) => theme.color.primary.main};
        border-radius: 0px;
    }

    /* Handle #43a1a2 or #214F61 or #ADADAD */
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 4px 2px 12px 2px ${({theme}) => theme.color.primary.main};
        border-radius: 4px;
    }
    
 
 `
