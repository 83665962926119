import { DefaultThemeWeb } from "./DefaultThemeWeb"
import { ITheme } from "./types"

export const SekBasicThemeWeb: ITheme = {
    ...DefaultThemeWeb,
    themeType:'basic',
    containerActivity: {
        ...DefaultThemeWeb.containerActivity,
        border: 'none',
    },
    topBar:{
        ...DefaultThemeWeb.topBar,
        direction: 'row-reverse',
        croos:{
            display: true,
        }
    },
    color: {
        ...DefaultThemeWeb.color,
        primary: {
            main: '#1773e6',
            contrastText: "white",
            dark: '#3286ed'
        },
        secondary: {
            main: "#385985",
            contrastText: "white"
        },
        background: {
            main: "#1773e6",
            paper: "#f2f2f2"
        }
    },
    styles:{
        ...DefaultThemeWeb.styles,
        text:{
            ...DefaultThemeWeb.styles.text,
            subtitle: {
                color: '#385985',
                size: '16px',
                fontFamily: 'Poppins',
                fontWeight: 'bold',
            },
            title: {
                color: '#385985',
                size: "16px",
                fontWeight: 'bold',
                fontFamily: 'Montserrat',
                backgroundColor: "#f2f2f2",
                borderRadius: "10px",
            }
        },
        activityContent:{
            backgroundColor: "none",
            borderRadius: "",
            border: "none"
        }
    },
    bottomBar: {
        ...DefaultThemeWeb.bottomBar,
        button:{
            ...DefaultThemeWeb.bottomBar.button,
            useFeedbackBackgroundColor: true,
            borderRadius: '30px',
            opacityDisabled: '0.8',
            backgroundColorEnabled: '#1773e6',
            colorEnabled: 'white',
            backgroundColorDisabled: '#1773e6',
            colorDisabled: 'white',
            border: '0',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: 'normal'
        },
        feedback:{
            ...DefaultThemeWeb.bottomBar.feedback,
            title:{
                color: "#385985",
                fontSize: "18px",
                fontWeight: "bold",
            },
            subtitle:{
                color: "#818a96",
                fontSize: "16px",
                fontWeight: "100",
            },
            good:"#77c800",
            bad: "#df2e24",
            partialy: "#f6ab0e"
        },
    },
    endUserAssessment:{
        ...DefaultThemeWeb.endUserAssessment,
        circularProgressBar:{
            imageInsideProgressBar: true,
            circularSize: '300px',
            textFont: 'Roboto',
            textSize: '3.25px',
            accurateSize: '39.75px',
            accurateFont: 'Roboto',
            accurateFontWeight: '700',
            accurateColor: '#0A0A0A',
            textColor: 'Gray'
        },
        title:{
            fontWeight: 'bold',
            fontSize: '32px',
            fontFamily: 'Poppins',
            align: 'left'
        },
        percentage: {
            fontSize: '20px',
        },
        good:"#77c800",
        bad: "#df2e24",
        partialy: "#f6ab0e"
    },
    themeActivities: {
        ...DefaultThemeWeb.themeActivities,
        multipleChoice: {
            option: {
                ...DefaultThemeWeb.themeActivities.multipleChoice.option,
                color:"#1773e6",
                fontFamily: "poppins",
                fontSize: "14px",
                padding: "11px",
                border: '2px solid #e5e5e5',
                justifyContent: "space-between",
                flexDirection: 'row-reverse',
                backgroundHover: '#385985',
                colorHover: 'white',
                point: {
                    ...DefaultThemeWeb.themeActivities.multipleChoice.option.point,
                    type: "check",
                    innerBallSize: '22px',
                    color: "white",
                    border: '2px solid #e5e5e5',
                    colorCheck: '#385985'
                }
            }
        },
        singleChoice: {
            option: {
                ...DefaultThemeWeb.themeActivities.singleChoice.option,
                color: "#1773e6",
                fontFamily: "poppins",
                fontSize: "14px",
                padding: "11px",
                border: '2px solid #e5e5e5',
                justifyContent: "space-between",
                flexDirection: 'row-reverse',
                backgroundHover: '#385985',
                colorHover: 'white',
                point: {
                    ...DefaultThemeWeb.themeActivities.singleChoice.option.point,
                    type: "check",
                    innerBallSize: '22px',
                    color: "white",
                    border: '2px solid #e5e5e5',
                    colorCheck: '#385985'
                }
            }
        },
        trueFalse: {
            option: {
                ...DefaultThemeWeb.themeActivities.trueFalse.option,
                fontFamily: "poppins",
                fontSize: "14px",
                padding: "11px",
                border: '2px solid #e5e5e5',
                justifyContent: "space-between",
                flexDirection: 'row-reverse',
                backgroundHover: '#385985',
                colorHover: 'white',
                point: {
                    ...DefaultThemeWeb.themeActivities.trueFalse.option.point,
                    type: "check",
                    innerBallSize: '22px',
                    color: "white",
                    border: '2px solid #e5e5e5',
                    colorCheck: '#385985'
                }
            }
        },
        imageChoice: {
            option:{
                ...DefaultThemeWeb.themeActivities.imageChoice.option,
                labelSelectedBorder: "#4a9ff5 dashed 3px",
            }
        },
        textChoice:{
            option:{
                ...DefaultThemeWeb.themeActivities.textChoice.option,
                labelSelectedBorder: "#4a9ff5 dashed 3px",
            }
        },
        imagePairing: {
            ...DefaultThemeWeb.themeActivities.imagePairing,
        },
        textPairing: {
            ...DefaultThemeWeb.themeActivities.textPairing,
        },
        cardChoice: {
            ...DefaultThemeWeb.themeActivities.flashCards,
        },
        froggyJump: {
            ...DefaultThemeWeb.themeActivities.froggyJump,
        },
        flashCards:{
            ...DefaultThemeWeb.themeActivities.flashCards,
            normalCard:{
                ...DefaultThemeWeb.themeActivities.flashCards.normalCard,
                title:{
                    ...DefaultThemeWeb.themeActivities.flashCards.normalCard.title,
                },
                subTitle:{
                    ...DefaultThemeWeb.themeActivities.flashCards.normalCard.subTitle,
                }
            },
            finalCard:{
                ...DefaultThemeWeb.themeActivities.flashCards.finalCard,
            },
            initialCard:{
                ...DefaultThemeWeb.themeActivities.flashCards.initialCard,
            }
        },
        wordSearch: {
            ...DefaultThemeWeb.themeActivities.wordSearch,
            timer:{
                ...DefaultThemeWeb.themeActivities.wordSearch.timer,
            }
        },
        wordCatcher: {
            ...DefaultThemeWeb.themeActivities.wordCatcher,
            timer:{
                ...DefaultThemeWeb.themeActivities.wordCatcher.timer,
            },
            marker:{
                ...DefaultThemeWeb.themeActivities.wordCatcher.marker,
            }
        },
        seekAndFind: {
            ...DefaultThemeWeb.themeActivities.seekAndFind,
            timer:{
                ...DefaultThemeWeb.themeActivities.seekAndFind.timer,
            },
        },
        fillTheBlanks: {
            ...DefaultThemeWeb.themeActivities.fillTheBlanks,
            input:{
                ...DefaultThemeWeb.themeActivities.fillTheBlanks.input,
            },
            span:{
                ...DefaultThemeWeb.themeActivities.fillTheBlanks.span,
            }
        },
        input: {
            ...DefaultThemeWeb.themeActivities.input,
        },
        opinionScale:{
            ...DefaultThemeWeb.themeActivities.opinionScale,
            scaleSentence:{
                ...DefaultThemeWeb.themeActivities.opinionScale.scaleSentence
            },
            scaleValueSelected:{
                ...DefaultThemeWeb.themeActivities.opinionScale.scaleValueSelected
            }
        },
        correspondence: {
            ...DefaultThemeWeb.themeActivities.correspondence
        },
        deduction: {
            ...DefaultThemeWeb.themeActivities.deduction
        },
        orderChronology:{
            ...DefaultThemeWeb.themeActivities.orderChronology,
            cardBackground: "background"
        },
        shortQuestion: {
            ...DefaultThemeWeb.themeActivities.shortQuestion,
            option:{
                ...DefaultThemeWeb.themeActivities.shortQuestion.option
            }
        }
    }
}

