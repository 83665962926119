import React, { useState, useEffect } from 'react'
import { ACTIVITIES_STATUS } from '../../hooks/useActivities'
import BottomBar from '../../layout/BottomBar/BottomBar'
import TopBar from '../../layout/TopBar/TopBar'
import { IActivitiesProps } from './type'
import { Loader } from '../Loader/Loader'
import { IDataAnswer, IResponseActualActivity } from '../../hooks/types'
import { IOption, ITarget } from '../../services/types'
import styled from "../../themes/styled";
import XEduActivities from '../XEduActivities/XEduActivities'
import { useParams } from 'react-router-dom'

export const StyledKakumaGamesContainer = styled('div')<{activitystatus: ACTIVITIES_STATUS, loadingactivityanswer: boolean}>`
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    flex-grow: 1;
    & > div:nth-child(2){
        pointer-events: ${({activitystatus, loadingactivityanswer}) => (activitystatus === ACTIVITIES_STATUS.WAITING_NEXT_ACTIVITY || loadingactivityanswer) ? 'none' : 'auto'}}
    }
`



const Activities = ({ 
    answerCurrentActivity,
    setLanguage,
    activities,
    activityStep,
    activityStatus,
    onNextActivity,
    feedback,
    checkAssessment,
    client,
    channel,
    themeType,
    loadingActivityAnswer
}: IActivitiesProps) => {
    const currentActivity = activities[activityStep - 1]
    const [activityState, setActivityState] = useState<IDataAnswer | undefined>()
    const [buttonDisabled, setButtonDisabled] = useState(true);
    // HANDLE PREVIEW LANGUAGE BY CLIENT
    
    const activityCurrentLanguage = currentActivity?.current_language.split('-')[0]
    setLanguage(activityCurrentLanguage)

    const setStateMicrogame = (newState: IDataAnswer) => {
        setActivityState(newState)
        if(((newState?.answer_content.user_data?.options !== undefined) && (newState?.answer_content.user_data?.options?.length > 0)) 
        || ((newState?.answer_content.user_data?.targets !== undefined) && (newState?.answer_content.user_data?.targets?.length > 0))){
            setButtonDisabled(false)
        }else{
            setButtonDisabled(true)
        }
            
    }

    const onClickCheckResult = (type: "checkResult" | "nextActivity" | "checkAssessment") => {
        if (type === "checkResult") answerCurrentActivity(activityState, currentActivity?.type)
        if (type === "nextActivity") onNextActivity()
        if (type === "checkAssessment") checkAssessment()
    }
    return(
    <StyledKakumaGamesContainer
        loadingactivityanswer={loadingActivityAnswer}
        activitystatus={activityStatus}
        id="highlightable"
    >   
        <TopBar actual={activityStep} max={activities.length} activityType={currentActivity?.type} isAssessmentEnd={false}/>
        {activityStatus === ACTIVITIES_STATUS.LOADING_ACTIVITY ? (
            <Loader />
            ) : (
                <XEduActivities
                    channel={channel}
                    client={client}
                    activityType={currentActivity.type}
                    testData={activities}
                    currentStep={activityStep}
                    setStateMicrogame={setStateMicrogame}
                    themeType={themeType}
                    />
        )}
        {activityStatus !== ACTIVITIES_STATUS.LOADING_ACTIVITY && <BottomBar
            actualStep={activityStep}
            maxSteps={activities.length}
            feedback={feedback}
            disabled={buttonDisabled}
            onClickCheckResult={onClickCheckResult}
            activityStatus={activityStatus}
            client={client}
            loadingActivityAnswer={loadingActivityAnswer}
            />}
    </StyledKakumaGamesContainer>)
}

export default Activities
