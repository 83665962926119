import styled from "styled-components"
import { IThemeProps } from "../../themes/types";

export const ContainerProgressBar = styled('div')<{theme: IThemeProps}>`
    height: ${({ theme }) => theme.topBar.progressBar.height ?? "8px"};
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    background-color: ${({ theme }) => theme.topBar.progressBar.backgroundColor ?? "#dddddd"};
    overflow: hidden;
    border-radius: ${({ theme }) => theme.topBar.progressBar.borderRadius ?? "10px"};
`

const ItemProgressBar = styled('div')`
    height: 100%;
    background-color: $background-cards;
    width: 100%;
`

export const ProgressBarComplete = styled(ItemProgressBar)<{theme: IThemeProps}>`
    background-color: ${({ theme }) => theme.topBar.progressBar.color ?? theme.color.primary.main};
`
export const ProgressBarNotComplete = styled(ItemProgressBar)`
    background-color: ${({ theme }) => theme.topBar.progressBar.notCompletedColor ?? "transparent"};
`
