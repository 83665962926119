import styled from "styled-components";
import { IThemeProps } from "../../themes/types";

export const ContainerProgressBar = styled('div')<{theme: IThemeProps}>`
    margin-top: 1em;
    display: flex;
    flex-direction: ${({ theme }) => theme.topBar.direction};
    justify-content: center;
    width: 100%;
    align-items: center;
    background-color: ${({ theme }) => theme.topBar.backgroundColor ?? "transparent"};
`
export const ContainerProgressBarDetails = styled('div')<{theme: IThemeProps}>`
    display: flex;
    justify-content: ${({ theme }) => theme.topBar.direction === "column" ? "space-between" : "flex-start"};
    width: ${({ theme }) => theme.topBar.direction === "column" ? "100%" : "auto"};
    align-items: center;
    margin: ${({theme}) => theme.topBar.direction === 'column' ? theme.margin.short : '0'};
`

export const ImageCrossProgressBar = styled('img')<{theme: IThemeProps}>`
    margin-right:  ${({ theme }) => theme.margin.short};
    display: ${({ theme }) => {
        return theme.topBar.croos.display === undefined || theme.topBar.croos.display === true
            ? "block"
            : "none"
    }};
`

export const IndexProgressBar = styled('p')<{theme: IThemeProps}>`
    font-family: ${({ theme }) => theme.styles.text.subtitle.fontFamily};
    color: #687079;
    height: fit-content;
    display: inline;
    margin: 0;
    margin-right: ${({ theme }) => theme.topBar.direction === "row" ? theme.margin.short : "0px"};
    margin-left: ${({ theme }) => theme.topBar.direction === "row-reverse" ? theme.margin.short : "0"};
    white-space: nowrap;
    font-weight: 400;
`

export const IndexActualAnswer = styled('div')<{theme: IThemeProps}>`
    display: flex;

    p{
        font-family: ${({ theme }) => theme.styles.text.subtitle.fontFamily};
        color: ${({ theme }) => theme.topBar.progressDetails?.activityTextColor ?? '#818a96'};
        height: fit-content;
        display: inline;
        margin: 0 !important;
        margin-left: ${({ theme }) => theme.topBar.direction === "row" ? theme.margin.short : "0px"};
        margin-bottom: ${({ theme }) => theme.topBar.direction !== "row" ? theme.margin.default : "0px"};
        white-space: nowrap;
        font-weight: 400;
    
    }


`
