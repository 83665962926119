import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";

export const DeductionText = styled('span')<{theme: IThemeProps}>`
    color: ${({theme}) => theme.themeActivities?.deduction?.text?.color};
    font-size: ${({theme}) => theme.themeActivities?.deduction?.text?.fontSize ?? theme?.styles?.text?.subtitle?.size};
    line-height: ${({theme}) => theme.themeActivities?.deduction?.text?.lineHeight ?? theme?.styles?.text?.subtitle?.lineHeight};
    font-family: ${({theme}) => theme.themeActivities?.deduction?.text?.fontFamily ?? theme?.styles?.text?.subtitle?.fontFamily};
    font-weight: ${({theme}) => theme.themeActivities?.deduction?.text?.fontWeight ?? theme?.styles?.text?.subtitle?.fontWeight};
`;

export const DeductionBox = styled('span')<{theme: IThemeProps, hasOption: true}>`
    display: -webkit-inline-flex;
	-webkit-justify-content: center;
	-webkit-align-items: center;
	display: inline-flex;
	justify-content: center;
    align-items: center;
    text-align: center;
	width: 100%;
    padding: ${({hasOption}) => hasOption ? '5px' : '0'};
    border-radius: ${({theme}) => theme.themeActivities?.deduction?.containerRadius};
    background-color: ${({theme}) => theme.themeActivities.deduction.optionInput?.backgroundOption ?? theme.themeActivities.deduction.option?.background};
	color: ${({theme}) => theme.themeActivities?.deduction?.optionInput?.colorOption ?? theme.themeActivities?.deduction?.option?.color};
	font-size: ${({theme}) => theme?.styles?.text?.subtitle?.size};
`;

export const DeductionOption = styled('p')<{theme: IThemeProps, isSelected: boolean}>`

background: ${({theme, isSelected}) => isSelected && theme.themeActivities?.deduction?.option?.backgroundOnDrag ? theme.themeActivities?.deduction?.option?.backgroundOnDrag : theme.themeActivities?.deduction?.option?.background};
	color: ${({theme, isSelected}) => isSelected && theme.themeActivities?.deduction?.option?.colorOnDrag ? theme.themeActivities?.deduction?.option?.colorOnDrag : theme.themeActivities?.deduction?.option?.color};
	font-size: ${({theme}) => theme.themeActivities?.deduction?.option?.fontSize ?? theme?.styles?.text?.subtitle?.size};
    line-height: ${({theme}) => theme.themeActivities?.deduction?.option?.lineHeight ?? theme?.styles?.text?.subtitle?.lineHeight};
    font-family: ${({theme}) => theme.themeActivities?.deduction?.option?.fontFamily ?? theme?.styles?.text?.subtitle?.fontFamily};
    font-weight: ${({theme}) => theme.themeActivities?.deduction?.option?.fontWeight ?? theme?.styles?.text?.subtitle?.fontWeight};
    border-radius: ${({theme}) => theme.themeActivities?.deduction?.option?.borderRadius ?? '16px'};
    -webkit-font-smoothing: antialiased;
    margin: 4px;
    padding: 7px 12px;
    display: inline-flex;
    position: relative;
    align-items: center;
    height: auto;
    cursor: pointer;
    overflow: hidden;
    vertical-align: middle;
    box-sizing: border-box;
`;

export const SentenceContainer = styled('div')<{theme: IThemeProps}>`
    margin: 2rem auto 10px auto;
    background: ${({theme}) => theme.themeActivities?.deduction?.containerBg};
    box-shadow: ${({theme}) => theme.themeActivities?.deduction?.containerBoxShadow};
    border: ${({theme}) => theme.themeActivities?.deduction?.containerBorder};
    border-radius: ${({theme}) => theme.themeActivities?.deduction?.containerRadius};
    min-height: 64px;
    width: 90vw;
    max-width: 96%;
`;

export const FragmentContainer = styled('div')`
    padding: 0 8px;
`;

export const DeductionSpan = styled('span')<{theme: IThemeProps, hasOption: boolean, isSelected: boolean}>`
    display: inline-block;
    position: relative !important;
    min-width: 80px;
    
    margin: 5px;
    margin-right: 8px;
    ${({theme, hasOption}) => {
        const allBorders = theme.themeActivities.deduction.optionInput?.allBorders;
        if(allBorders){
            return `border: ${hasOption ? '0' : theme.themeActivities.deduction.optionInput?.border};`
        }else{
            return `border-bottom: ${hasOption ? '0' : theme.themeActivities.deduction.optionInput?.border};`
        }
    }}
    border-radius: ${({theme}) => theme.themeActivities.deduction.optionInput?.allBorders ? theme.themeActivities?.deduction?.containerRadius : '0'};
    [data-rbd-placeholder-context-id] {
        position: absolute;
        min-width: 80px;
        left: 0px;
        top: -5px;
    }
    &:hover {
        background-color: ${({theme, isSelected})=> theme.themeActivities?.deduction?.optionInput?.backgroundHoverOnDrop && isSelected ? theme.themeActivities.deduction.optionInput.backgroundHoverOnDrop : 'transparent'};
    }



`;



export const StyledSeparatorLine = styled('div')<{theme: IThemeProps}>`
    height: 1px;
    width: 100%;
    background-color: ${({theme})=> theme.themeActivities.deduction?.lineSeparator?.useLine ? theme.themeActivities.deduction?.lineSeparator?.color : 'transparent'};
    margin: ${({theme}) => theme.margin.short} 0;

`
