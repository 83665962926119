import React,{useContext} from 'react'
import { ACTIVITIES_STATUS } from '../../hooks/useActivities'
import { ButtonCheckResult, ContainerBottomBar, ContainerButton, ContainerFeedback, ContainerFeedBackInfo } from './BottomBarStyles'
import { useTranslation } from 'react-i18next';
import { IBottomBarProps } from './types';
import FeedbackImage from './Feedbacks/FeedbackImage';
import FeedbackBar from './Feedbacks/FeedbackBar';
import ThemeContext from '../../providers/ThemeProvider/ThemeContext';
import { CircularProgress } from '@mui/material';


const BottomBar = ({ onClickCheckResult, activityStatus, disabled, feedback, actualStep, maxSteps, client, loadingActivityAnswer }: IBottomBarProps) => {
    const [_t] = useTranslation("preview");
    const {theme} = useContext(ThemeContext);

    return (
        <ContainerBottomBar hasFeedback={feedback ? true : false}>
            <ContainerFeedback hasFeedback={feedback ? true : false}>
                {theme.bottomBar.useFeedbackImage && feedback && activityStatus === ACTIVITIES_STATUS.WAITING_NEXT_ACTIVITY &&
                    <FeedbackImage client={client} feedback={feedback}/>
                }
                {theme.bottomBar.useFeedbackBar && feedback && activityStatus === ACTIVITIES_STATUS.WAITING_NEXT_ACTIVITY &&
                    <FeedbackBar client={client} feedback={feedback}/>
                }
            </ContainerFeedback>

            <ContainerButton>
                {activityStatus === ACTIVITIES_STATUS.WAITING_ANSWER 
                ?  
                    <ButtonCheckResult
                        aria-live='assertive'
                        aria-label={loadingActivityAnswer ? _t("bottom_bar.aria_label_button_loading") : _t("bottom_bar.button_option1")}
                        onClick={() => onClickCheckResult('checkResult')}
                        disabled={disabled ? disabled : loadingActivityAnswer}
                        feedback={"none"}
                    >
                        {loadingActivityAnswer ? <CircularProgress size={20} style={{color: theme.color.primary.main}} /> : _t("bottom_bar.button_option1")  } {/* check results */}
                    </ButtonCheckResult>
                : 
                    <>
                        {actualStep === maxSteps 
                        ? 
                            <ButtonCheckResult
                                aria-live='assertive'
                                aria-label={_t("bottom_bar.button_option2")}
                                disabled={false}
                                feedback={feedback?.type || "none"}
                                onClick={() => onClickCheckResult('checkAssessment')}
                            >
                                {_t("bottom_bar.button_option2")} {/* See results */}
                            </ButtonCheckResult>
                        : 
                            <ButtonCheckResult
                                aria-live='assertive'
                                aria-label={_t("bottom_bar.button_option3")}
                                feedback={"none"}
                                disabled={false}
                                onClick={() => onClickCheckResult('nextActivity')}
                            >
                            {_t("bottom_bar.button_option3")} {/* Next activity */}
                            </ButtonCheckResult>
                        }
                    </>
                }
            </ContainerButton>
        
        </ContainerBottomBar>
    )
}

export default BottomBar
