import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DOMPurify from "isomorphic-dompurify";
import { StyledCardChoiceContainer, StyledContainerChip } from './ActivityCardChoiceStyles';
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, XEduActivityContainer } from '../XEduActivitiesStyles';
import BoatChip from './images/pointers/barco.png';
import ShipChip from './images/pointers/nave.png';
import CarChip from './images/pointers/coche.png';
import DefaultChip from './images/pointers/ficha.png';
import HTMLReactParser from 'html-react-parser';

const ActivityCardChoice = ({ client, activity, optionList, setStateMicrogame, background, end = () => { } }) => {
    const [selectedOption, setSelectedOption] = useState([]);
    const [positionRandom, setPositionRandom] = useState(null);


    useEffect(() => {
        setStateMicrogame(selectedOption);
    }, [selectedOption])

    const onDragEnd = (event) => {
        if (!event.destination) return;
        if (selectedOption === event.destination.droppableId) {
            return
        }
        if (event.destination.droppableId === 'dropableChip') {
            setSelectedOption([]);
            return;
        }
        const numberRandom = Math.floor(Math.random() * (8 - 0) + 0);
        setPositionRandom(numberRandom);
        setSelectedOption([{id:event.destination.droppableId}]);
        endEvent(event.destination.droppableId)
    }

    const endEvent = (idSelected) => {
        end({
            id: idSelected
        })
    }

    return (
        <StyledActivityContainer>

			{activity?.title && <StyledActivityHeader><h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}/></StyledActivityHeader>}

            <StyledActivityContent client={client}>
                <XEduActivityContainer>
                {activity?.text && <p className='subtitle'  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }} />}
            <StyledCardChoiceContainer stylegame={background}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className='container-options'>
                            {optionList.map((element, index) => {
                                return <Droppable key={index} droppableId={"" + element.id}>
                                    {(provider) => (
                                        <div
                                        ref={provider.innerRef}
                                        {...provider.draggableProps}
                                        {...provider.dragHandleProps}
                                        className="container-option"
                                        key={index}
                                        >
                                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.title) }}></p>
                                            {selectedOption[0]?.id === ("" + element.id) &&
                                                <DraggableChip background={ background } positionRandom={positionRandom} />
                                            }
                                            {provider.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            })}
                        </div>
                        <Droppable key={""} index={1} droppableId={"dropableChip"} direction="horizontal">
                            {(provider) => (
                                <StyledContainerChip ref={provider.innerRef}
                                {...provider.draggableProps}
                                {...provider.dragHandleProps}
                                >
                                    {selectedOption.length === 0 &&
                                        <DraggableChip background={background} />
                                    }

                                    {provider.placeholder}
                                </StyledContainerChip>
                            )}
                        </Droppable>

                    </DragDropContext>
                </StyledCardChoiceContainer>
                </XEduActivityContainer>
            </StyledActivityContent>
        </StyledActivityContainer>
    )
}

const DraggableChip = ({background, positionRandom=""}) => {
    const urlChip = background === 'SPACE' ? ShipChip :
    background === 'PIRATES' ? BoatChip :
    background === 'MOUNTAINS' ? CarChip :
    DefaultChip;
    
    return <Draggable index={0} draggableId={"chip" + ""}>
        {(provided, snapshot) => (
            <div
                className="container-draggable-chip"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{ ...provided.draggableProps.style }}
                random={positionRandom + ""}
            >
                <img className="image-chip" src={urlChip} alt={"Game Chip"}/>
                {provided.placeholder}
            </div>
        )}
    </Draggable>
}

export default ActivityCardChoice
