import LilipadsPoll from "./LilipadsPoll";
import fondo from "./../images/fondo2.png";

class Game {
    context;
    canvas;
    poll;
    setInAnimationFunc = null;
    background = null;
    options = [];
    positionCurrentOption = null;

    listAnswers = [];

    isGameFinish = false;
    legent = false;
    setTitle;

    damage = 0;

    constructor(context, canvas, config = {
        setInAnimationFunc: () => { },
        onGameFinish: () => { },
        setTitle: () => { }
        },
        i18n
    ) {
        this.i18n = i18n
        this.context = context;
        this.canvas = canvas;
        this.setInAnimationFunc = config.setInAnimationFunc;
        this.setTitle = config.setTitle;
        this.onGameFinish = config.onGameFinish;
        this.poll = new LilipadsPoll(context, {
            onSelectedOption: (selectedOption) => this.onSelectedOption(selectedOption)
        });
        this.poll.initLilipads();
        this.background = new Image();
        this.background.src = fondo;
    }

    onSelectedOption = (optionSelected) => {
        this.finishAnimation();
        this.listAnswers.push({
            target: this.options[this.positionCurrentOption].target,
            option: optionSelected
        });

        if (optionSelected.is_right.toLowerCase() === "false") {
            this.damage += 1;
        }

        //Next option
        this.nextOption();
    }

    finishAnimation = () => {
        this.setInAnimationFunc(false);
    }

    initAnimation(animationType) {
        this.inAnimation = true;
        this.poll.setAnimation(animationType);
    }

    getInAnimation() {
        return this.inAnimation;
    }

    setOptions(options) {
        this.options = options;
        this.nextOption();
    }

    nextOption() {

        if (this.positionCurrentOption === null) {
            this.positionCurrentOption = 0;
        } else if (this.options.length <= (this.positionCurrentOption + 1)) {
            //Finish function 
            this.isGameFinish = true;
            this.onGameFinish(this.listAnswers)
            return;
        } else {
            this.positionCurrentOption += 1;
        }

        this.setTitle(this.options[this.positionCurrentOption].target.title)
        this.poll.setOptions(this.options[this.positionCurrentOption].options);
    }

    update(mouse, pressedKeys) {
        if (this.isGameFinish === false) {
            this.poll.update();
        }

        if (pressedKeys.includes(17) && pressedKeys.includes(83) && pressedKeys.includes(71)) {
            this.legent = true;
        }
    }

    click(mouse) {
        if (this.isGameFinish) return;
        this.poll.click(mouse);

    }

    isOptionSelected(mouse = { x: 0, y: 0 }) {
        return this.poll.isOptionSelected(mouse)
    }

    draw = () => {
        this.drawBackground();
        this.poll.draw(this.damage);

        if (this.isGameFinish) {
            this.context.save();
            this.context.fillStyle = "#00000055";
            this.context.fillRect(0, 0, 1000, 1000);
            this.context.textAlign = "center";
            this.context.textBaseline = "middle";
            this.context.fillStyle = "#ffffff";
            this.context.font = "bold 100px Arial";
            this.context.fillText(this.i18n.exists("microgames:froggy_jump") ? this.i18n.t("microgames:froggy_jump.game_end") : "GAME END", 500, 500);
            this.context.restore();
        }
        if (this.legent) {
            this.drawLegent();
        }
    }

    drawLegent = () => {
        this.context.save();
        this.context.textAlign = "right";
        this.context.textBaseline = "middle";
        this.context.fillStyle = "#000000";
        this.context.font = "bold 20px Arial";
        this.context.fillText('00430072006500610074006500640020006200790020004100640072006900730067'.hexDecode(), 985, 980);
        this.context.restore();
    }

    drawBackground() {
        this.context.save();
        this.context.drawImage(
            this.background,
            0, 0, this.canvas.current.width, this.canvas.current.height
        );
        this.context.restore();
    }
}

export default Game;
