import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";

export const QuestionSubtitle = styled('div')<{theme: IThemeProps}>`
	line-height: 21px;
	font-size: 14px;
    color: ${({theme}) => theme.themeActivities?.correspondence?.captionPrimary};
	font-family: ${({theme}) => theme.themeActivities?.styles?.text?.fontFamily?.primaryFont};
	padding: 0 12px;
	text-align: center;
`;

export const CorrespondenceAnswer = styled('span')<{theme: IThemeProps}>`
    font-size: ${({ theme }) => theme.themeActivities.correspondence?.options?.fontSize ?? theme.styles.text.subtitle.size};
    font-weight: ${({ theme }) => theme.themeActivities.correspondence?.options?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
    line-height: ${({ theme }) => theme.themeActivities.correspondence?.options?.lineHeight ?? theme.styles.text.subtitle.lineHeight};
    font-familty: ${({ theme }) => theme.themeActivities.correspondence?.options?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
	// position: absolute;
	text-align: center;

	p {
		margin: 0;
	}
`;

export const CorrespondenceAnswers = styled('button')<{theme: IThemeProps}>`
    background-color: ${({theme}) => theme.themeActivities?.correspondence?.options?.backgroundColor ?? 'transparent'};
    border: ${({theme}) => theme.themeActivities?.correspondence?.options?.border ?? `1px solid ${theme.color.primary.main}`};
    border-radius: ${({theme}) => theme.themeActivities?.correspondence?.options?.borderRadius ?? '8px'};
    color: ${({theme}) => theme.themeActivities?.correspondence?.options?.color ?? theme.styles.text.subtitle.color};
    &:hover{
        color: ${({theme}) => theme.themeActivities?.correspondence?.options?.colorHover ??  theme.styles.text.subtitle.color};
        background-color: ${({theme}) =>  theme.themeActivities?.correspondence?.options?.backgroundColorHover ?? 'transparent'};

    }
    overflow: hidden;
	width: ${({theme}) => theme.themeActivities?.correspondence?.options?.width ?? '140px'};
	height: ${({theme}) => theme.themeActivities?.correspondence?.options?.height ?? '60px'};
	display: -webkit-flex;
	-webkit-justify-content: center;
	-webkit-align-items: center;
	display: flex;
	justify-content: center;
	align-items: center;
	// position: relative;
	margin-right: 0.25rem;
    cursor: pointer;
    margin: 0.5em;


`;

export const StyledCorrespondenceContainers = styled('div')<{theme: IThemeProps, channel: string}>`
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: ${({channel, theme}) => channel === 'web' && theme.themeActivities.correspondence?.webDirection ? theme.themeActivities.correspondence.webDirection : 'column'};
`

export const CorrespondenceCard = styled('div')<{theme: IThemeProps, cardNumber: number}>`
    margin: ${({cardNumber, theme}) => `-${cardNumber * theme.themeActivities.correspondence?.cards?.separation ?? 2}px 0 0 -${cardNumber * theme.themeActivities.correspondence?.cards?.separation ?? 2}px`};


	color: ${({theme}) => theme.themeActivities?.correspondence?.cards?.color ?? theme.styles.text.subtitle.color};
    font-size: ${({ theme }) => theme.themeActivities.correspondence?.cards?.fontSize ?? theme.styles.text.subtitle.size};
    font-weight: ${({ theme }) => theme.themeActivities.correspondence?.cards?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
    line-height: ${({ theme }) => theme.themeActivities.correspondence?.cards?.lineHeight ?? theme.styles.text.subtitle.lineHeight};
    font-familty: ${({ theme }) => theme.themeActivities.correspondence?.cards?.fontFamily ?? theme.styles.text.subtitle.fontFamily};

	background-color: ${({theme}) => theme.themeActivities?.correspondence?.cards?.backgroundColor ?? 'transparent'};
	border: ${({theme}) => theme.themeActivities?.correspondence?.cards?.border ?? `1px solid ${theme.color.primary.main}`};
    border-radius: ${({theme}) => theme.themeActivities?.correspondence?.cards?.borderRadius ?? '8px'};
    box-shadow: ${({theme}) => theme.themeActivities.correspondence?.cards?.shadow ?? 'none'};

	width: 90%;
	height: 90%;
	position: absolute;
	left: 0;
	display: -webkit-flex;
	-webkit-justify-content: center;
	-webkit-align-items: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	text-align: center;
    transform: translateX(0%);
	&.rotate-right {
		transition-duration: 1s;
        transform: rotate(3.142rad) translateX(100vw);
	};

	&.rotate-left {
		transition-duration: 1s;
        transform: rotate(-3.142rad) translateX(-100vw);

	};

    &.to-left {
		transition-duration: 1s;
        transform: translateX(-100vw);
	};

	&.to-right {
		transition-duration: 1s;
        transform: translateX(100vw);
	};

    p{
        display: -webkit-box !important;
        -webkit-line-clamp: 6 !important;
        -webkit-box-orient: vertical !important;  
        text-overflow: ellipsis !important;
        overflow: hidden !important;
    }

`;

export const CorrespondenceContainer1 = styled('div')<{channel: string}>`
    position: relative;
    display: -webkit-flex;
    -webkit-justify-content: center;
    display: flex;
    justify-content: center;
    height: 150px;
    width: ${({channel}) => channel === 'web' ? '400px' : '250px'};
    margin: 32px 20px 24px 10px;

`;

export const CorrespondenceContainer2 = styled('div')`
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -webkit-justify-content: center;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 1em;
    height: auto;
    `;
