import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

// Definimos el tipo de la función sanitizeHtml
type SanitizeHtmlFunction = (htmlString: string) => string;

export const useAccessibility = (): SanitizeHtmlFunction => {
    // Función sanitizeHtml con tipos explícitos
    const sanitizeHtml: SanitizeHtmlFunction = (htmlString: string) => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        return doc.body.textContent || "";
    };

    return sanitizeHtml;
};
