import React, { useState, useEffect, useContext } from "react";
import {
    QuestionSubtitle,
    CorrespondenceAnswer,
    CorrespondenceAnswers,
    CorrespondenceCard,
    CorrespondenceContainer1,
    CorrespondenceContainer2,
    StyledCorrespondenceContainers
} from "./CorrespondeneStyles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DOMPurify from "isomorphic-dompurify";
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, XEduActivityContainer } from "../XEduActivitiesStyles";
import useParseUserAnswer from "../../../hooks/useParseUserAnswer";
import DotsStepper from "./Stepper/DotsStepper";
import { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { useAccessibility } from "../../../hooks/useAccesibility";
import HTMLReactParser from "html-react-parser";

const Correspondence = ({
    client,
    setStateMicrogame,
    activity,
    channel
}) => {
    const [cards, setCards] = useState([]);
    const [options, setOptions] = useState([]);
    const [currentOption, setCurrentOption] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [idOfAnimationToLeft, setIdOfAnimationToLeft] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const { parseAnswer } = useParseUserAnswer();
    const theme = useContext(ThemeContext)
    const showDotStepper = theme.themeActivities.correspondence.steps.showDotStepper;
    const allowDragAndDrop = theme.themeActivities.correspondence.steps?.allowDragAndDrop ?? true;
    const allowClickeable = theme.themeActivities.correspondence.steps?.allowClickeable ?? true;
    const [_t] = useTranslation("kakuma_activities");   
    const sanitizeHtml = useAccessibility()

    const [steps, setSteps] = useState(0)

    useEffect(() => {
        setCards(activity?.targets);
        setSteps(activity?.targets.length);
        setCurrentOption(activity?.targets[activity?.targets.length - 1].id)
        setOptions(activity?.options);
    }, []);

    // Set final answer
    useEffect(() => {
        if (answers?.length === activity?.targets?.length) setStateMicrogame(parseAnswer(answers));
    }, [answers]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const destinationDroppableId = result.destination.droppableId;
        if (result.source.droppableId === destinationDroppableId && result.source.index === result.destination.index)
            return;

        let answerCopy = [...answers]
        // Include value with the id of the option selected by the student to be able to check results later on
        const newItems = [...cards];
        const draggedItem = newItems[result.source.index];

        // get option to match
        let optionSelected = activity?.options.filter(element => element.paired_option_id === draggedItem.id)[0];
        optionSelected.target_order = parseInt(draggedItem.order)

        // Update parent state
        answerCopy.push({
            option: optionSelected, 
            target: draggedItem
        })
        setAnswers(answerCopy);

        // Remove card that has already been completed
        newItems.splice(result.source.index, 1);
        setCards(newItems);

        // Update current option
        if (newItems.length > 0) {
            setActiveStep(activeStep + 1);
            setCurrentOption(newItems[0].id)};
    };

    const handleClickResponse = (option) => {
        let answerCopy = [...answers]
        const newItems = [...cards];

        const card = {...newItems[newItems.length - 1]};     
        
        // get option to match
        let optionSelected = {
            ...option,
            target_order: parseInt(card.order) 
        }
        
        // Update parent state
        answerCopy.push({
            option: optionSelected, 
            target: card
        })
        setAnswers(answerCopy)

        setIdOfAnimationToLeft(card.id);
        // Add remove animation
        setTimeout(() => {
            // Remove card that has already been answered
            newItems.splice(newItems.length -1, 1);

            setCards(newItems);
            // Update current option
            if (newItems.length > 0) {
                setActiveStep(activeStep + 1);
                setCurrentOption(newItems[newItems.length - 1].id)
            };
        }, [500]);  
    };
    
    return (
        <StyledActivityContainer>
            <StyledActivityHeader className='question' style={{ marginBottom: "1em" }}>
                <h2
                    tabIndex={0}
                    aria-labelledby={`${activity.title}`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}
                />    
            </StyledActivityHeader>
            <StyledActivityContent client={client}>
                <XEduActivityContainer>
                {activity?.text && (
                    <>
                        <p 
                            role="region"
                            aria-labelledby={`${sanitizeHtml(activity?.text)}`}
                            className="subtitle" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }}
                        />
                    </>
                    )
                }
                <DragDropContext onDragEnd={handleOnDragEnd}>
                
                        <StyledCorrespondenceContainers channel={channel}>
                        <div style={{display:'flex'}}>
                        {showDotStepper && 
                                <DotsStepper 
                                    activeStep={activeStep} 
                                    stepperSteps={steps}    
                                />}
                            <CorrespondenceContainer1 channel={channel}>

                                {cards.length > 0 ? cards.map((card, index) => {
                                    return (
                                        <Droppable key={"correspondence" + index} droppableId={"correspondence-" + index}>
                                            {(provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    <Draggable isDragDisabled={!allowDragAndDrop} draggableId={card.id.toString()} key={card.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <CorrespondenceCard
                                                                cardNumber={index < 3 ? index : 2}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={
                                                                    client?.toLowerCase() === 'mhe' && idOfAnimationToLeft === card.id && currentOption % 2 === 0
                                                                    ? 'rotate-left'
                                                                    : client?.toLowerCase() === 'mhe' && idOfAnimationToLeft === card.id && currentOption % 2 !== 0
                                                                    ? 'rotate-right'
                                                                    : idOfAnimationToLeft === card.id && currentOption % 2 === 0
                                                                    ? 'to-right'
                                                                    : idOfAnimationToLeft === card.id && currentOption % 2 !== 0
                                                                    ? 'to-left'
                                                                    : ''
                                                                }
                                                            >
                                                                 {index === cards.length - 1 && ( 
                                                                    <p aria-live={`${_t("common.aria_label_option")}${index + 1} ${card.title}`}>
                                                                        {card.title}
                                                                    </p>
                                                                )}
                                                            </CorrespondenceCard>
                                                        )}
                                                    </Draggable>
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    );
                                })
                                :
                                    <CorrespondenceCard>
                                     <p aria-label={_t("correspondence.no_more_cards")}>{_t("correspondence.no_more_cards")}</p>
                                    </CorrespondenceCard>
                                }
                            </CorrespondenceContainer1>
                            </div>
                            {cards.length > 0 && <CorrespondenceContainer2 channel={channel}>
                            {options.map((option, index) => {
                                if (option.paired_option_id === currentOption) {
                                        return (
                                            <Droppable key={"correspondenceBlank-" + index} droppableId={"correspondenceBlank-" + option.id}>
                                                {(provided, snapshot) => (
                                                    <CorrespondenceAnswers
                                                        disabled={cards.length === 0}
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        onClick={() => allowClickeable && handleClickResponse(option)}
                                                        aria-label={`${sanitizeHtml(option?.title || '')}`}
                                                    >
                                                        {HTMLReactParser(String(option?.title))}
                                                        {provided.placeholder}
                                                    </CorrespondenceAnswers>
                                                )}
                                            </Droppable>
                                        );
                                    } else return null;
                                })}
                            </CorrespondenceContainer2>}
                        </StyledCorrespondenceContainers>
                </DragDropContext>
            </XEduActivityContainer>
            </StyledActivityContent>
        </StyledActivityContainer>
    );
};

export default Correspondence;
