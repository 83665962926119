import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import ThemeContext from './ThemeContext';
import { THEME_CONFIG } from '../../themes';

const ThemeProvider = ({ children, client, themeType }) => {
    const [searchParams] = useSearchParams();
    const [clientTheme, setClientTheme] = useState(searchParams?.get('target')?.toUpperCase() || client?.toUpperCase())
    const [clientThemeType, setClientThemeType] = useState(searchParams?.get('theme') || themeType)
    const [themeActivity, setThemeActivity] = useState(THEME_CONFIG.XIMDEX.default)

    useEffect(() => {
        const newTheme = THEME_CONFIG?.[clientTheme]?.[clientThemeType] ?? THEME_CONFIG?.[clientTheme]?.['basic'] ?? THEME_CONFIG['XIMDEX']['default']
        setThemeActivity(newTheme)
    }, [clientTheme])


    const changeTheme = (client) => {
        setClientTheme(client)
    }

    return (
        <ThemeContext.Provider
            value={{
                theme: themeActivity,
                type: clientTheme,
                changeTheme: changeTheme
            }}
        >
            <ThemeProviderStyled theme={themeActivity}>
                {children}
            </ThemeProviderStyled>
        </ThemeContext.Provider>
    )
}

export default ThemeProvider
