import React, { useState, useEffect } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
	DeductionText,
	DeductionBox,
	DeductionOption,
    FragmentContainer,
    DeductionSpan,
    StyledSeparatorLine
} from './DeductionStyles';
import { 
    StyledActivityContainer, 
    StyledActivityHeader, 
    StyledActivityContent, 
    XEduActivityContainer
} from '../XEduActivitiesStyles';
import useParseUserAnswer from '../../../hooks/useParseUserAnswer';
import { useTranslation } from 'react-i18next';

const Deduction = ({
    client,
    setStateMicrogame,
    activity,
    ...props
}) => {
    const [paragraphs, setParagraphs] = useState(undefined);
    const [blanks, setBlanks] = useState(undefined);
	const [options, setOptions] = useState('');
    const [targets, setTargets] = useState([]);
	const [isDragging, setIsDragging] = useState(false);
    const [answers, setAnswers] = useState(undefined);
    const {parseAnswer} = useParseUserAnswer();
    const [optionSelected, setOptionSelected] = useState(undefined)
    const [_t] = useTranslation("kakuma_activities");

    
	useEffect(() => {
        initActivity();
	}, [activity?.id]);

    useEffect(() => {
        // Check if the deduction assessment has been completed
        if (answers) {
            const answersCompleted = [];
            for (let index = 0; index < answers.length; index++) {
                const answer = answers[index];
                if(answer.title)
                    answersCompleted.push({
                        option: answer,
                        target: targets.filter(element => element.order === answer.target_order)[0]
                    });
            }
            if (answersCompleted?.length === activity?.options?.length) setStateMicrogame(parseAnswer(answersCompleted));
        };	
    }, [answers]);


    const initActivity = () => {
		setParagraphsAndBlanks();
		shuffleOptions();
        setTargets(activity?.targets)
    };

	const setParagraphsAndBlanks = () => {
		const originalSentence = activity?.text 
		let matches = []

		// Split paragraphs from text
        const regexParagraphs = /<p>(.*?)<\/p>/g;
        matches = Array.from(originalSentence.matchAll(regexParagraphs));
        let hasOnlyOne
        
        // No matches == text without tag <p></p>, so we add the tag
		if(matches.length === 0) {
            const text = `<p>${activity.text}</p>`
            matches[0] = text;
            hasOnlyOne = true
        }else{
            hasOnlyOne = false
        }

        const initParagraph = matches.map((match) => {
            const mergedParagraph = [];
            
            const innerHTML = hasOnlyOne ? match : match[1];
            const parts = innerHTML.split(/(\{[\d]+\})/);
    
            parts.forEach((part) => {
                if (part.startsWith("{") && part.endsWith("}")) {
                const placeholder = parseInt(part.slice(1, -1));
                if (!isNaN(placeholder)) {
                    mergedParagraph.push({ placeholder });
                }
                } else {
                    mergedParagraph.push({ text: part });
                }
            });
            
            return mergedParagraph;
        });
        setParagraphs(initParagraph);
    
        const blankOrder = initParagraph?.flatMap(arr => {
            return arr.reduce((result, item) => {
              if (item.placeholder) {
                result.push({ "placeholder": item.placeholder });
              }
              return result;
            }, []);
        });
        setBlanks(blankOrder);
        setAnswers(blankOrder);
	};

	const shuffleOptions = () => {
		const shuffledOptions = activity?.options?.sort(() => Math.random() - 0.5);
		setOptions(shuffledOptions);
	};

	const handleOnDragEnd = (result) => {
        setOptionSelected(undefined)
		if (!result.destination) return null;

		const destinationIndex = result.destination.index;
		const sourceIndex = result.source.index;
		const destinationDroppableId = result.destination.droppableId;
		const sourceDroppableId = result.source.droppableId;
		const newOptions = [...options];
		const draggedItemFromOptions = {...newOptions[result.source.index], target_order: blanks[destinationIndex].placeholder };
		const draggedItemFromBlank = { ...answers[sourceIndex], target_order: blanks[destinationIndex].target_order };

		if (result.source.droppableId === destinationDroppableId && result.source.index === result.destination.index) return null;

		if (destinationDroppableId.includes('deductionBlank')) {
			const newAnswers = [...answers];

			if (newAnswers[destinationIndex].title) {
				// If you try to move a word to a position in the sentence where another word has been placed already...
				if (sourceDroppableId.includes('deductionBlank')) {
					// ...and dragged word comes from another sentence position
					newOptions.push({ ...newAnswers[destinationIndex], placeholder: undefined });
					newAnswers[destinationIndex] = draggedItemFromBlank;
					newAnswers[sourceIndex] = blanks[sourceIndex];
				} else {
					// ...and dragged word comes from available options
                    newOptions[sourceIndex] = { ...newAnswers[destinationIndex], placeholder: undefined };
					newAnswers[destinationIndex] = draggedItemFromOptions;
				};
			} else {
				// If no word has been placed in the sentence position yet...
				if (sourceDroppableId.includes('deductionBlank')) {
					// and dragged word comes from another sentence position
					newAnswers[destinationIndex] = draggedItemFromBlank;
					newAnswers[sourceIndex] = blanks[sourceIndex];
				} else {
					// and dragged word comes from available options
					newAnswers[destinationIndex] = draggedItemFromOptions;
					newOptions.splice(sourceIndex, 1);
				};
			};
			// update all options and answers
			setOptions(newOptions);
			setAnswers(newAnswers);
		};
	};

    const handleOnDragStart = (result) => {
        setOptionSelected(result.source.droppableId)
    }

	return (
		<StyledActivityContainer>
			<StyledActivityHeader>
                <h2
                    tabIndex={0}
                    aria-labelledby={`${activity.title}`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}
                />    
            </StyledActivityHeader>

			<StyledActivityContent client={client} >
                {paragraphs && 
                    <DragDropContext  onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
                        <XEduActivityContainer>
                            {paragraphs.map((paragraph, index) => {
                                return(
                                    <p key={'paragraph' + index} style={{padding: 0}}>
                                        {paragraph.map((fragment, fragmentIndex) => {
                                            if (fragment.hasOwnProperty('text')) {
                                                return <DeductionText key={'paragraph-' + index + ' fragment-' + fragmentIndex} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fragment.text) }} />;
                                            } else if (fragment.hasOwnProperty('placeholder')) {
                                                const placeholderIndex = blanks.findIndex(item => item?.placeholder === fragment?.placeholder)
                                                return (
                                                    <Droppable key={'deductionBlank' + placeholderIndex} droppableId={'deductionBlank-' + placeholderIndex} >
                                                        {(provided) => (
                                                            <DeductionSpan 
                                                            {...provided.droppableProps} 
                                                            ref={provided.innerRef}
                                                            className='deduction-span'
                                                            hasOption={answers?.[placeholderIndex]?.title ? true : false}
                                                            isSelected={optionSelected ? true : false}
                                                            >
                                                                <Draggable draggableId={placeholderIndex.toString()} key={placeholderIndex} index={placeholderIndex} >
                                                                    {(provided, snapshot) => (
                                                                        <DeductionBox
                                                                            aria-label={`${_t("fill_the_blanks.aria_label_option")} ${placeholderIndex + 1}`}
                                                                            aria-describedby={`instruction-${placeholderIndex}`}
                                                                            hasOption={answers?.[placeholderIndex]?.title ? true : false}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={{...provided.draggableProps.style}}
                                                                        >
                                                                            {answers?.[placeholderIndex]?.title}
                                                                            {provided.placeholder}
                                                                        </DeductionBox>
                                                                    )}
                                                                </Draggable>
                                                                {provided.placeholder}
                                                            </DeductionSpan>
                                                        )}
                                                    </Droppable>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </p>
                                )
                            })}
                              <StyledSeparatorLine></StyledSeparatorLine>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {options?.map((option, index) => {
                                return (
                                    <Droppable key={'deduction' + option.order} droppableId={'deduction-' + index}>
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                <Draggable draggableId={DOMPurify.sanitize(option?.title) || index.toString()} key={option.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <DeductionOption
                                                            aria-label={`${_t("common.aria_label_option")} ${index + 1} ${option?.title}`}
                                                            isSelected={optionSelected === ("deduction-" + index)}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{...provided.draggableProps.style}}
                                                        >
                                                            {option?.title}
                                                        </DeductionOption>
                                                    )}
                                                </Draggable>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                );
                            })}
                        </div>
                        </XEduActivityContainer>      
                      
                    </DragDropContext>
                }
			</StyledActivityContent>
		</StyledActivityContainer>
	);
};

export default Deduction;
