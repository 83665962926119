import React, { useEffect, useRef } from 'react'
import PropsTypes from "prop-types";

const Canvas = ({
    drawTick = () => { },
    onInitCanvas = () => { },
    gameTick = () => { },
    onClick = () => { },
    onMoveMouse = () => { },
    onKeyPress = () => { },
    onKeyUp = () => { },
    pather,
    isRendering,
    height = 1000,
    width = 1000,
    ...props
}) => {
    const canvas = useRef();
    let gameTickInterval = null;
    let idAnimationFrame = null;

    useEffect(() => {
        const context = canvas.current.getContext('2d');
        onInitCanvas(context, canvas);
        idAnimationFrame = requestAnimationFrame(() => handleDrawTick(context, canvas))
        gameTickInterval = setInterval(() => handleGameTick(this, context, canvas), 1000 / 60);

        window.addEventListener("keydown", handleOnkeyPress)
        window.addEventListener("keyup", handleOnkeyUp)
        return () => {
            if (gameTickInterval !== null) clearInterval(gameTickInterval);
            if (gameTickInterval !== null) cancelAnimationFrame(idAnimationFrame);
        }
    }, []);

    const handleDrawTick = (context, canvas) => {
        if (isRendering === false) return;
        if (canvas.current !== null) clear(context, canvas);
        if (canvas.current !== null) drawTick(context, canvas);
        idAnimationFrame = requestAnimationFrame(() => handleDrawTick(context, canvas));
    }

    const handleGameTick = (context, canvas) => {
        if (isRendering === false) return;
        if (canvas.current === null) return;
        gameTick(context, canvas);
    }

    const clear = (context, canvas) => {
        canvas.current.width = 1000;
        canvas.current.height = 1000;
    }

    //Mouse And Touch events
    const mouseOnEnter = () => {
        pather.mouse.enter = true;
    };

    const mouseOnLeave = () => {
        pather.mouse.enter = false;
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
        //pather.mouse.click = true;
        onClick();
    };

    const handleMouseUp = () => {
        pather.mouse.click = false;
    }

    const calculeMouse = (event) => {
        const rect = canvas.current.getBoundingClientRect();
        const x = ((event.clientX - rect.left) / rect.width) * width;
        const y = ((event.clientY - rect.top) / rect.height) * height;
        return {
            x,
            y
        }
    }

    const handleMouseMove = (event) => {
        onMoveMouse(calculeMouse(event))
    };

    const smartphoneTouchStart = (e) => {
        handleMouseMove({
            clientX: e.touches[0].clientX,
            clientY: e.touches[0].clientY
        });
        e.preventDefault();
    };


    const smartphoneTouchEnd = (e) => {
        e.preventDefault();
        onClick()
    };

    const smartphoneTouchMove = (e) => {
        e.preventDefault();
        handleMouseMove({
            clientX: e.touches[0].clientX,
            clientY: e.touches[0].clientY
        });
    };

    const handleOnkeyPress = (e) => {
        onKeyPress(e.keyCode)
    }

    const handleOnkeyUp = (e) => {
        onKeyUp(e.keyCode)
    }

    return (
        <canvas
            ref={canvas}
            height={height}
            width={width}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onMouseLeave={mouseOnLeave}
            onMouseEnter={mouseOnEnter}
            onTouchStart={smartphoneTouchStart}
            onTouchEnd={smartphoneTouchEnd}
            onTouchMoveCapture={smartphoneTouchMove}
            {...props}
        />
    );
}

Canvas.propTypes = {
    drawTick: PropsTypes.func.isRequired,
    onInitCanvas: PropsTypes.func.isRequired,
    gameTick: PropsTypes.func.isRequired,
    isRendering: PropsTypes.bool.isRequired,
    height: PropsTypes.number,
    width: PropsTypes.number,
    className: PropsTypes.string
}

export default Canvas