class SeekAndFindClass {
    ctx = null;
    config = null;
    imageBackground = null;
    targetPoint = null;
    pointSelected = null;

    endGame = null;
    pointEditedFunc = null;
    showResult = false;

    imagenCargada = false;

    targetRadiusEditionMode = {
        firstRatio: 0,
        secondRatio: 0
    }

    constructor(ctx, config = { width: 1000, height: 1000 }, targetPoint = {x: 500, y: 500, firstRatio: 50, secondRatio: 30}, endGame, pointEdited) {
        this.ctx = ctx;
        this.config = config;
        this.targetPoint = targetPoint;
        this.pointEditedFunc = pointEdited;

        this.imageBackground = new Image();
        this.imageBackground.src = config.imageBackground;
        this.imageBackground.onload = () => {
            this.imagenCargada = true;

            let scaleW = this.imageBackground.width / this.config.width;
            let scaleH = this.imageBackground.height / this.config.height;
            if (scaleW > scaleH) {
                this.imageBackground.width = this.imageBackground.width / scaleW;
                this.imageBackground.height = this.imageBackground.height / scaleW;
            } else {
                this.imageBackground.width = this.imageBackground.width / scaleH;
                this.imageBackground.height = this.imageBackground.height / scaleH;
            }

        }
        this.imageBackground.onerror = () => {
            console.error("An error occurred while loading the image.")
        }
        this.endGame = endGame;
    }

    clearCanvas() {
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
        this.ctx.fillStyle = '#000000'
        this.ctx.beginPath()
        this.ctx.fill();
    }

    updateStatus(mouse, scaleRatio, showResult, newsRadius) {
        if (this.gameEnd === true) return;

        this.config.width *= scaleRatio.xRatio;
        this.config.height *= scaleRatio.yRatio;

        this.mouse = mouse;

        this.mouse.x *= scaleRatio.xRatio;
        this.mouse.y *= scaleRatio.yRatio;

        this.showResult = showResult;

        if (this.mouse.click === true && !this.showResult) {

            this.pointSelected = {
                x: this.mouse.x,
                y: this.mouse.y
            };



            if (this.config.editionMode) {
                this.targetPoint.x = this.mouse.x;
                this.targetPoint.y = this.mouse.y;

                this.pointEditedFunc(this.targetPoint)
            } else {
                this.endGame(
                    {
                        x: this.mouse.x,
                        y: this.mouse.y
                    });
            }
        }

        if (this.config.editionMode &&
            (this.targetPoint.firstRatio != newsRadius.firstRatio || this.targetPoint.secondRatio != newsRadius.secondRatio)
        ) {

            this.targetPoint.firstRatio = newsRadius.firstRatio;
            this.targetPoint.secondRatio = newsRadius.secondRatio;
            this.pointEditedFunc(this.targetPoint);
        }

    }

    draw() {
        this.clearCanvas();
        this.ctx.globalAlpha = 1;
        if (this.imagenCargada) {
            this.ctx.drawImage(
                this.imageBackground,
                (this.config.width - this.imageBackground.width) / 2,
                (this.config.height - this.imageBackground.height) / 2,
                this.imageBackground.width,
                this.imageBackground.height);
        }
        if (this.showResult || this.config.editionMode) {
            this.drawCorrectAnswer();
        }


        if (!this.config.editionMode) {
            this.drawPointSelected();
        }

    }
    
    drawCircle(){
        this.ctx.beginPath();
        this.ctx.arc(this.pointSelected?.x, this.pointSelected?.y, 40, 0, 2 * Math.PI);
        this.ctx.strokeStyle = "#fdf2e9c7";
        this.ctx.shadowColor = "#fdf2e9c7";
        this.ctx.stroke();
    }

    drawPointSelected() {
        if (this.pointSelected) {
            this.ctx.beginPath();
            this.ctx.strokeStyle = "white";
            this.ctx.globalAlpha = 0.75;
            this.ctx.lineWidth = 15; //change
            this.ctx.shadowColor = "white";
            this.ctx.shadowBlur = 30;
            this.ctx.arc(this.pointSelected.x, this.pointSelected.y, this.ctx.lineWidth / 2, 0, 2 * Math.PI)
            this.ctx.stroke();
            this.ctx.closePath();
        }
    }

    drawCorrectAnswer() {
        this.ctx.globalAlpha = this.config.editionMode ? 0.3 : 0.2;

        let targetPoint = this.targetPoint //this.config.editionMode && this.pointSelected ? this.pointSelected : this.targetPoint ;

        //Draw circle orange
        this.ctx.beginPath();
        this.ctx.strokeStyle = "orange";
        this.ctx.lineWidth = this.targetPoint.secondRatio;
        this.ctx.arc(this.targetPoint.x, this.targetPoint.y, this.targetPoint.firstRatio + (this.targetPoint.secondRatio / 2), 0, 2 * Math.PI);

        this.ctx.stroke();
        this.ctx.closePath();
        //

        this.ctx.strokeStyle = "green";
        this.ctx.beginPath();
        this.ctx.lineWidth = this.targetPoint.firstRatio;
        this.ctx.arc(this.targetPoint.x, this.targetPoint.y, this.targetPoint.firstRatio / 2, 0, 2 * Math.PI)
        this.ctx.stroke();
        this.ctx.closePath();

        if (!this.config.editionMode) {
            this.ctx.strokeStyle = "red";
            this.ctx.beginPath();
            this.ctx.lineWidth = 1500;
            this.ctx.arc(this.targetPoint.x, this.targetPoint.y, this.targetPoint.firstRatio + this.targetPoint.secondRatio + (1500 / 2), 0, 2 * Math.PI)
            this.ctx.stroke();
            this.ctx.closePath();
        }
    }
}
export default SeekAndFindClass;
