import styled from "../../themes/styled";
import { IThemeProps } from "../../themes/types";


export const PreviewContainer = styled('div') <{channel?: string, theme: IThemeProps, fontSize?: Number}>`
    min-height: 100%;
    margin: auto;
    background-color:  ${({ theme }) => theme.styles.previewContainerBackgroundColor ?? "white"};
    color: #385985;
    font-family: Poppins;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;

    font-size: ${({fontSize}) => fontSize ? fontSize +'px' : ''};
`
