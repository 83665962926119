import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";

export const StyledTextContainer = styled ('p')<{theme: IThemeProps, channel: string}>`
    background-color: white;
    padding: 1rem;
    border-radius: 8.08px;
    height: auto;
    width: ${({channel,theme}) => channel === "mobile" ? '100%' : theme.themeActivities.input.text.fullwidth ? '100%' : "50%"};
    margin-bottom: ${({channel}) => channel === "mobile" ? '0.5rem' : "0"};
    word-wrap: break-word;
    font-size: ${({theme}) => theme.themeActivities?.input?.text?.fontSize ?? theme.styles.text.subtitle.size};
    color: ${({theme}) => theme.themeActivities?.input?.text?.color ?? theme.styles.text.subtitle.color};
    font-family: ${({theme}) => theme.themeActivities?.input?.text?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
    font-weight:  ${({theme}) => theme.themeActivities?.input?.text?.fontWeight ?? theme.styles.text.subtitle.fontWeight};

`

export const StyledInput = styled('textarea')<{theme: IThemeProps, themeActivity: string}>`
    width: 100%;
    height: ${({theme, themeActivity}) => themeActivity === 'input-long' ? theme.themeActivities?.input?.input?.height : 'auto'};
    background: transparent;
    border: ${({theme}) => theme.themeActivities?.input?.input?.border};
    color: ${({theme}) => theme.themeActivities?.input?.input?.color ?? theme.styles.text.subtitle.color};
    border-radius: ${({theme}) => theme.themeActivities?.input?.input?.borderRadius};
    font-family: ${({theme}) => theme.styles.text.subtitle.fontFamily};
    resize: none;
    box-sizing: border-box;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;

    &:focus{
        outline: none !important;
        border: ${({theme}) => theme.themeActivities?.input?.input?.borderOnFocus} !important;
    }
`;

export const StyledWordCountLabel = styled('div')<{ theme: IThemeProps }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.margin.short};

    label:nth-child(1) {
        font-size: ${({ theme }) => theme.themeActivities?.input?.label?.fontSize ?? theme.styles.text.subtitle.size};
        color: ${({ theme }) => theme.themeActivities?.input?.label?.color ?? theme.styles.subtitle.text.color};
        font-family: ${({ theme }) => theme.themeActivities?.input?.label?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
        font-weight: ${({ theme }) => theme.themeActivities?.input?.label?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
    }

    label:nth-child(2) {
        font-size: ${({ theme }) => theme.themeActivities?.input?.wordCounter?.fontSize ?? theme.styles.text.subtitle.size};
        color: ${({ theme }) => theme.themeActivities?.input?.wordCounter?.color ?? theme.styles.text.subtitle.color};
        font-family: ${({ theme }) => theme.themeActivities?.input?.wordCounter?.fontFamily ?? theme.styles.text.subtitle.fontFamily};
        font-weight: ${({ theme }) => theme.themeActivities?.input?.wordCounter?.fontWeight ?? theme.styles.text.subtitle.fontWeight};
    }
`;

export const StyledInputContainer = styled('div')<{theme: IThemeProps, channel: string}>`
    display: flex;
    flex-direction: ${({theme}) => theme.themeActivities?.input?.input.direction ?? "colum-reverse"};
    width: ${({channel}) => channel === "mobile" ? '100%' : "50%"};
    margin: ${({theme}) => theme.themeActivities.input.input.hasMargin ? '0.5em' : '0'};
    margin-top: 0;
    
    `;
