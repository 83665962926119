import styled from "styled-components"
import { IThemeProps } from "./types"

const Variables = styled('div')<{theme: IThemeProps}>`
    --margin-default: ${({ theme }) => theme.margin.default};
    --margin-short: ${({ theme }) => theme.margin.short};
    --margin-long: ${({ theme }) => theme.margin.long};
    --margin-superlong: ${({ theme }) => theme.margin.superLong};
`

export default (type: any) => {
    return styled(Variables).attrs({
        as: type
    })
}
