import React, {useState} from 'react';
import Button from '@mui/material/Button';
import { useTheme } from 'styled-components';
import styled from "styled-components";
import { MobileStepper } from '@mui/material';

export const StyledMobileStepper = styled(MobileStepper)`
    // position: absolute;
    // left: 0;
    width: 10%;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    // margin: 1em;
    background-color: transparent !important;
    .MuiMobileStepper-dots{
        display: flex;
        flex-direction: column;
    }
    .MuiMobileStepper-dot{
        margin-top: 1em;
        width: ${({theme}) => theme.themeActivities.correspondence.steps.width};
        height: ${({theme}) => theme.themeActivities.correspondence.steps.height};
    }
    .MuiMobileStepper-dotActive{
        background-color: ${({theme}) => theme.themeActivities.correspondence.steps.color};
    }
    .MuiMobileStepper-dot:nth-child(-n + ${({stepsControlCheck}) => stepsControlCheck}){
        background-color: ${({theme}) => theme.themeActivities.correspondence.steps.color};
    }
`
export default function DotsStepper({
    activeStep,
    stepperSteps,
}) {
  return (
    <StyledMobileStepper
        stepsControlCheck={stepperSteps - (stepperSteps - activeStep)}
        variant="dots"
        steps={stepperSteps}
        position="static"
        activeStep={activeStep}
        sx={{ maxWidth: 400, flexGrow: 1 }}
    />
  );
}




