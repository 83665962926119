import React, { useState } from 'react'
import DOMPurify from "isomorphic-dompurify"
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, XEduActivityContainer } from '../XEduActivitiesStyles';
import { StyledActivityOptionContainer, StyledMaxOptions } from './ActivityChoiceStyles'
import PLACEHOLDER from "../images/placeholder-image.jpg";
import { MEDIA_PREVIEW } from '../../../CONSTANT';
import HTMLReactParser from 'html-react-parser'

const ActivityChoice = ({ client, activity, optionList, setStateMicrogame, activityType,}) => {
    const [checkeds, setCheckeds] = useState([])
    const maxRightOptions = optionList.filter(option => option.is_right.toLowerCase() === 'true').length

    const onChange = (event, option) => {
        let newCheckeds = [...checkeds];
        if (event.target.checked === true) {
            if (checkeds.length >= maxRightOptions) {
                event.target.checked = false;
            } else {
                newCheckeds.push(option);
            }
        } else {
            newCheckeds = newCheckeds.filter((element) => element.id !== option.id);
        }
        setCheckeds(newCheckeds);
        setStateMicrogame(newCheckeds);
    }

    return (
        <StyledActivityContainer>
			{activity?.title && <StyledActivityHeader><h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}/></StyledActivityHeader>}

<StyledActivityContent client={client}>
    <XEduActivityContainer>
      {activity?.text && <p className='subtitle'  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }} />}
                <StyledActivityOptionContainer  activityName={activityType === "text-choice" ? "textChoice" : "imageChoice"} >
                    {optionList.map((element, index) => (
                        <>
                            <input
                                type="checkbox"
                                id={`optionImage ${index}`}
                                onChange={(event) => onChange(event, element)}
                                hidden
                                />
                            <label htmlFor={`optionImage ${index}`} key={index} className={checkeds.includes(element) ? "checked" : ""}>
                                {activityType === "image-choice" ? (
                                    <img  src={element.target?.image ? 
                                        `${element.target?.image?.startsWith("@@@") ? MEDIA_PREVIEW : ''}${element.target?.image}` : element.target?.image_url ?
                                        element.target?.image_url : 
                                        PLACEHOLDER
                                        }
                                        alt={element?.title}
                                    />
                                    ) : (
                                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(String(element.title)) }} ></p>
                                        )}
                            </label>
                        </>
                    ))}
                    </StyledActivityOptionContainer>
                    <StyledMaxOptions>{`${checkeds.length}/${maxRightOptions}`}</StyledMaxOptions>
                 </XEduActivityContainer>
            </StyledActivityContent>
        </StyledActivityContainer>
    )
}

export default ActivityChoice
