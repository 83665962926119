import styled from "styled-components";


export const StyledSeekAndFindTableGame = styled('div')`
    width: 100%;
    height: fit-content !important;
    margin-top: 15px;
    display:flex; 
    align-items: center; 
    flex-direction: column;
    position: relative;
    overflow: hidden;

    @media (max-width: 992px) {
        margin-top: 09px;
    }


`

export const StyledGame = styled('div')`
    width: 100%;
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: row;
    width:80%;
    
    @media (max-width: 992px) {
        flex-direction: ${(({theme, channel}) => channel === 'web' && theme.themeActivities.seekAndFind?.webDirection ? theme.themeActivities.seekAndFind?.webDirection : 'column')};
        width: 100%;
    }
    
    
    .gameCanvas {
        display: flex;
        align-items: center; 
        justify-content: center; 
        width: 100%;
        
        canvas {
            width: 85% !important;
            @media (min-width: 992px) {
                width: 90% !important;
            }
        }
    }

    .edition-part{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .input-range {
            width: 50%;
            box-sizing: border-box;
        }
    }

`

export const StyledGameInfoContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;       


    @media (max-width: 992px) {
        width: 100%;
    }

    p {
        margin: 0;
    }

    .timer {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        div{
            background-color: ${({theme}) => theme.themeActivities.wordSearch.timer?.backgroundColor};
            color: ${({theme}) => theme.themeActivities.wordSearch.timer?.color};
            font-size: ${({theme}) => theme.themeActivities.wordSearch.timer?.fontSize};
            border-radius: ${({theme}) => theme.themeActivities.wordSearch.timer?.borderRadius};
            text-align: center;
            width: 50%;
        }

        @media (max-width: 992px) {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        img{
            margin-right: 5px;
        }
    }


}`
