import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { IThemeProps } from "../../../themes/types";

export const StyledTinderContainer = styled('div')`

    width: 100%;
    height: 380px;
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    opacity: 1;

    & * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
      }
`

export const StyledTinderStatus = styled('div')`
    position: absolute;
    bottom: 60%;
    z-index: 2;
    width: 100%;
    text-align: center;
    pointer-events: none;
    z-index: 99;
  
    &::after {
      content: " ";
      position: absolute;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      transform: translate(-50%, 119%);
      width: 88px;
      height: 88px;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 100%;
      z-index: 99;
    }
`


export const StyledTinderIcon = styled(FontAwesomeIcon)`
    font-size: 300px;
    opacity: 0;
    transform: scale(0.3);
    transition: all 0.2s ease-in-out;
    position: absolute;
    width: 300px;
    margin-left: -150px;
    opacity: 0.8;
    z-index: 100;

`

export const StyledTinderCardsContainer = styled('div')`
    flex-grow: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 1;
    
`

export const StyledTinderCard = styled('div')<{theme: IThemeProps}>`
    display: inline-block;
    width: 90vw;
    max-height: 900px;
    height: 100%;
    max-width: 400px;
    background-color: ${({theme}) => theme.themeActivities.flashCards?.normalCard?.backgroundColor};
    padding-bottom: 10px;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    border: 1px solid #ececec;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;

    div{
        width: 100%;
        background-color: transparent;
        pointer-events: none;

        img{
            max-width: 100%;
            max-height: 400px;
            pointer-events: none;
        }
    }

    h3{
        color: ${({theme}) => theme.themeActivities.flashCards?.normalCard?.title?.color};
        font-size: ${({theme}) => theme.themeActivities.flashCards?.normalCard?.title?.fontSize};
        font-weight: ${({theme}) => theme.themeActivities.flashCards?.normalCard?.title?.fontWeight};
        margin-top: 20px;
        padding: 0 16px;
        pointer-events: none;
    }

    p{
        margin-top: 10px;
        color: ${({theme}) => theme.themeActivities.flashCards?.normalCard?.subtitle?.color};
        font-size: ${({theme}) => theme.themeActivities.flashCards?.normalCard?.subTitle?.fontSize};
        font-weight: ${({theme}) => theme.themeActivities.flashCards?.normalCard?.subTitle?.fontWeight};
        padding: 0 16px;
        pointer-events: none;
    }
`


export const StyledFinalCard = styled(StyledTinderCard)<{theme: IThemeProps}>`
    background-color: ${({theme}) => theme.themeActivities.flashCards?.finalCard?.backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({theme}) =>  theme.margin.long};
  
    h3 {
        font-family: ${({theme}) => theme.themeActivities.flashCards?.finalCard?.fontFamily};
        font-size: ${({theme}) => theme.themeActivities.flashCards?.finalCard?.fontSize};
        color: ${({theme}) => theme.themeActivities.flashCards?.finalCard?.color};
    }

`

export const StyledInitialCard = styled(StyledTinderCard)<{theme: IThemeProps}>`
    max-height: 900px;
    height: 100%;

    &>h3 {
        font-family: ${({theme}) => theme.themeActivities.flashCards?.initialCard?.fontFamily};
        font-size: ${({theme}) => theme.themeActivities.flashCards?.initialCard?.fontSize};
        margin: ${({theme}) => theme.margin.long};
        text-align: center;
        color: ${({theme}) => theme.themeActivities.flashCards?.initialCard?.color};
    }
`

export const StyledContainerSymbols = styled('div')`
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    &>div {

      width: 50%;
      display: flex-end;
      padding: 2rem;

      & .icon-simbol {
        font-size: 70px;
      }

      &:first-child {
        justify-content: start;
      }

      &:last-child {
        justify-content: end;
      }
    }

`
