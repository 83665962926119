import React from 'react'
import SeekAndFindClass from "./SeekAndFindClass";
import PLACEHOLDER from "../images/placeholder-image.jpg";
import { MEDIA_PREVIEW } from "../../../CONSTANT";
import InputRange from './inputRange/InputRange';
import { withTranslation } from 'react-i18next';
import { StyledGame, StyledGameInfoContainer, StyledSeekAndFindTableGame } from './SeekAndFindStyles';
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, XEduActivityContainer } from '../XEduActivitiesStyles';
import HTMLReactParser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import { withTheme } from 'styled-components';

class SeekAndFind extends React.Component {
	canvasRef;
	canvas;
	context;
	animationFrameId;
	frameCount = 0;
	timerShow
	currentStep;
	config;
	themeConfig

	scaleRatio = {
		xRatio: 0,
		YRatio: 0
	};

	game = null;

	//Mouse variables
	mouse = {
		x: 0,
		y: 0,
		click: false,
		enter: false
	};

	targetRadiusEditionMode = {
		firstRatio: 0,
		secondRatio: 0
	}

	intervalTimer = null;

	targetCoords = null;

	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
		this.renderCanvas = this.renderCanvas.bind(this);
		this.themeConfig = props.theme.themeActivities.seekAndFind;
		this.config = {
			editionMode: props.editionMode,
			width: 1000,
			height: 1000,
			imageBackground: props?.data?.image ? 
				`${props.data?.image.startsWith("@@@") ? MEDIA_PREVIEW : ''}${props.data.image}` : props.data.image_url ?
				props.data.image_url : 
				PLACEHOLDER
		};
		var minutes = "00"
		var seconds = "00"
		
		// If existe time : set timer
		if(props?.data?.props?.time){
			minutes = Math.floor(props.data.props?.time / 60);
			seconds = props.data.props?.time - minutes * 60;
		}

		this.state = {
			timer: {
				minutes: minutes === "00" ? "00" : `0${minutes}`,
				seconds: seconds === "00" ? "00" : seconds < 10 ? `0${seconds}` : seconds,
			},
			words: {
				wordsToSearch: [],
				wordsSearched: []
			},
			start: false,
			gameEnd: false,
			valueFirtsCircle: 100,
			valueSecondCircle: 150
		};

		const { options, targets } = props.data;
		const indexFirstRatio = options.findIndex(option => option?.is_right?.toUpperCase() === 'TRUE')
		const indexSecondRatio = options.findIndex(option => option?.is_right?.toUpperCase() === 'PARTIALLY')

		this.targetCoords = {
			x: targets[0].area.x,
			y: targets[0].area.y,
			firstRatio: options[indexFirstRatio].area.radius,
			secondRatio: options[indexSecondRatio].area.radius
		}

		this.targetRadiusEditionMode.firstRatio = options[indexFirstRatio].area.radius;
		this.targetRadiusEditionMode.secondRatio = options[indexSecondRatio].area.radius;

	}

	componentDidMount() {
		//this.currentStep = this.props.currentStep;

		this.startGame();
	}

	startGame = () => {
		this.setState({ start: true });
		this.canvas = this.canvasRef.current;
		this.context = this.canvasRef.current.getContext("2d");
		if (this.context) {
			this.game = new SeekAndFindClass(this.context, this.config, this.targetCoords, this.gameEnd, this.setPointEditable);

			//We launch the thread to render the canvas
			this.renderCanvas();
			if (!this.config.editionMode) {
				this?.props?.data?.props?.time && this.initTimer();
			}
		}
	};

	setPointEditable = point => {
		this.props.onedit(point)
	}

	componentWillUnmount() {
		if (this.intervalTimer) {
			clearInterval(this.intervalTimer);
		}
	}

	initTimer() {
		this.intervalTimer = setInterval(() => {
			const { timer } = this.state;
			if (this?.props?.data?.props?.time) {
				if(parseInt(timer.minutes) !== 0 || parseInt(timer.seconds) !== 0){
					var minutes = parseInt(timer.seconds) === 0 ? parseInt(timer.minutes) - 1 : parseInt(timer.minutes)  
					var seconds = parseInt(timer.minutes) !== 0 && parseInt(timer.seconds) === 0 ? 59 : (parseInt(timer.seconds) - 1);
										
					if (seconds === 0 && minutes === 1) {
						minutes = 1
					}
					
					if(seconds === 0 && minutes === 0){
						this.setState({
							timer: {
								seconds: seconds,
								minutes: minutes
							}
						})
						this.componentWillUnmount()
					}
				}

			} else {
				var seconds = (parseInt(timer.seconds) + 1) % 60;
				var minutes = seconds === 0 ? parseInt(timer.minutes) + 1 : parseInt(timer.minutes);
			}

			if (seconds < 10) seconds = "0" + seconds;
			if (minutes < 10) minutes = "0" + minutes;

			this.setState({
				timer: {
					seconds: seconds,
					minutes: minutes
				}
			});
		}, 1000);
	}

	renderCanvas() {
		this.frameCount++;
		//this.clearCanvas(this.con	text);

		// Check if time is over
		if (this?.props?.data?.props?.time) {
			if(parseInt(this?.state?.timer?.seconds) === 0 && parseInt(this?.state?.timer?.minutes) === 0){
				this.game.updateStatus(
					{...this.mouse,
						enter:true,
						click:true},
					this.scaleRatio,
					this.props.showResult,
					this.targetRadiusEditionMode
				);
				return
			}
		}
		

		//Calculamos Xratio
		const rect = this.canvas.getBoundingClientRect();
		this.scaleRatio.xRatio = this.canvas.width / this.config.width;
		this.scaleRatio.yRatio = this.canvas.width / this.config.width;

		//We update the canvas
		this.game.updateStatus(this.mouse, this.scaleRatio, this.props.showResult, this.targetRadiusEditionMode);

		//We draw the canvas
		this.game.draw();

		//We call the main Canvas loop again
		if (this.state.gameEnd) return;
		this.animationFrameId = window.requestAnimationFrame(this.renderCanvas);
	}

	handleMouseDown = (event) => {
		event.preventDefault();
		this.mouse.click = true;
	};

	handleMouseUp = () => {
		this.mouse.click = false;
	};

	handleMouseMove = (event) => {
		const rect = this.canvas.getBoundingClientRect();

		const x = ((event.clientX - rect.left) / rect.width) * this.config.width;
		const y = ((event.clientY - rect.top) / rect.height) * this.config.height;

		this.mouse.x = x;
		this.mouse.y = y;
	};

	gameEnd = (selectedPoint) => {
		this.props.setStateMicrogame(selectedPoint);
	};

	mouseOnEnter = () => {
		this.mouse.enter = true;
	};

	mouseOnLeave = () => {
		this.mouse.enter = false;
	};

	smartphoneTouchStart = (e) => {
		e.preventDefault();
		this.mouse.click = true;
		this.mouse.enter = true;
		this.handleMouseMove({
			clientX: e.touches[0].clientX,
			clientY: e.touches[0].clientY
		});
	};

	smartphoneTouchEnd = (e) => {
		e.preventDefault();
		this.mouse.click = false;
		this.mouse.enter = false;
	};

	smartphoneTouchMove = (e) => {
		e.preventDefault();
		this.mouse.click = true;
		this.mouse.enter = true;
		this.handleMouseMove({
			clientX: e.touches[0].clientX,
			clientY: e.touches[0].clientY
		});
	};

	scroll = (e) => {
		e.preventDefault();
		if (this.mouse.enter) e.preventDefault();
	};

	///Pruebas

	firtsCircleChange = (e) => {
		this.setState({ valueFirtsCircle: e.target.value });
	}
	secondCircleChange = (e) => {
		this.setState({ valueSecondCircle: e.target.value });
	}

	onchangeInputFirtRatio = (e) => {
		console.log(e.target.value)
	}

	renderHeader = (display) =>{
		if(display) 
			return(
				<>
				{this.props?.data?.title && 
				<StyledActivityHeader>
					<h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props?.data?.title) }}/>
				</StyledActivityHeader>
				}
				</>
			)
		return <></>
	}

	renderSubtitle = (display) => {
		if(display) 
		return(
			<>
			{this.props?.data?.text && 
				<p className='subtitle'  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props?.data?.text) }} />
			}
			</>
		)
	return <></>
	}

	render() {
		return (
			<StyledActivityContainer>

				{this.renderHeader(this.props.channel === "mobile" ? true : this?.themeConfig?.titilesWithActivityInfoWeb ? false : true)}
				<StyledActivityContent client={this.props?.client}>
					<XEduActivityContainer>
					{this.renderSubtitle(this.props.channel === "mobile" ? true : this?.themeConfig?.subtitlesWithActivityInfoWeb ? false : true)}

					<StyledSeekAndFindTableGame onScroll={this.scroll}>
						<StyledGame className='game' channel={this.props.channel}>
							<div className='gameCanvas'>
								<canvas
									ref={this.canvasRef}
									width={this.config.width}
									height={this.config.height}
									onMouseDown={this.handleMouseDown}
									onMouseUp={this.handleMouseUp}
									onMouseMove={this.handleMouseMove}
									onMouseLeave={this.mouseOnLeave}
									onMouseEnter={this.mouseOnEnter}
									onTouchStart={this.smartphoneTouchStart}
									onTouchEnd={this.smartphoneTouchEnd}
									onTouchMoveCapture={this.smartphoneTouchMove}
									className='canvasRef'
								></canvas>
							</div>
							{this.config.editionMode ?
								(
									<div className="edition-part">
										<InputRange
											min={30}
											max={500}
											value={this.targetCoords.firtsRatio}
											onChange={event => this.targetRadiusEditionMode.firstRatio = parseInt(event.target.value)}
											className="input-range"
										/>
										<InputRange
											min={30}
											max={500}
											value={this.targetCoords.secondRatio}
											onChange={event => this.targetRadiusEditionMode.secondRatio = parseInt(event.target.value)}
											className="input-range" />
									</div>
								) : 
									<StyledGameInfoContainer>
										{this.renderHeader( this.props.channel === "mobile" ? false : this.themeConfig.titilesWithActivityInfoWeb ? true : false)}
										{this.renderSubtitle( this.props.channel === "mobile" ? false : this.themeConfig.subtitlesWithActivityInfoWeb ? true : false)}

										{this.props?.data?.props?.time > 0 ? 
										<div className='timer'>
											<div>
												{this.props.theme?.themeActivities.seekAndFind?.timer?.useIcon && <img src={'/icons/timerIcon.svg'}/>}
												{parseInt(this.state.timer.seconds) === 0 && parseInt(this.state.timer.minutes) === 0
													?
														<samp>{this.i18n.exists("kakuma_activities:common.times_up") ? this.i18n.t("kakuma_activities:common.times_up") : "Time's up"}</samp>
													:
													<>
														<samp className='mitutes'>{this.state.timer.minutes}</samp>:<samp className='seconds'>{this.state.timer.seconds}</samp>
													</>
												}
											</div>
										</div>
										: <></> 
										}
									</StyledGameInfoContainer>
								}
						</StyledGame>
					</StyledSeekAndFindTableGame>
					</XEduActivityContainer>
				</StyledActivityContent>
			</StyledActivityContainer>
		);
	}
}

export default withTheme(withTranslation('kakuma_activities')(SeekAndFind));
