/* 

!!!!!!!!!!!!!!!!!!
THIS ACTIVITY HAS TROUBLES WITH LAST VERSIONS OF REACT BEACUSE OF THE REACT-HAMMERJS LIBRARY 
!!!!!!!!!!!!!!!1

*/

import React, { useEffect, useState } from 'react'
// import Hammer from "react-hammerjs"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { MEDIA_PREVIEW } from '../../../CONSTANT';
import PLACEHOLDER from "../images/placeholder-image.jpg";

import DOMPurify from "isomorphic-dompurify";
import { StyledActivityContainer, StyledActivityContent, StyledActivityHeader, XEduActivityContainer } from '../XEduActivitiesStyles';
import HTMLReactParser from 'html-react-parser';
import { StyledContainerSymbols, StyledFinalCard, StyledInitialCard, StyledTinderCard, StyledTinderCardsContainer, StyledTinderContainer, StyledTinderIcon, StyledTinderStatus } from './FlashCardStyles';

const FlashCard = ({ client, activity, optionList, setStateMicrogame, isMobile, end = () => { }, i18n}) => {
    const [onMoveState, setOnMoveState] = useState(false);
    const [answeredInitialCard, setAnsweredInitialCard] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [flashCardStatus, setFlashCardStatus] = useState(null);

    const VELOCITY = isMobile ? 0.5 : 0.1;

    useEffect(() => {
        setStateMicrogame([{
            id: 0,
            is_right: true
        },{
            id: 0,
            is_right: true
        },{
            id: 0,
            is_right: true
        }])
    }, [answers])


    const getMoveCard = (event) => {
        var moveOutWidth = document.body.clientWidth;
        var keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < VELOCITY;

        event.target.classList.toggle('removed', !keep);

        if (keep) {
            event.target.style.transform = '';
        } else {
            var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
            var toX = event.deltaX > 0 ? endX : -endX;
            var endY = Math.abs(event.velocityY) * moveOutWidth;
            var toY = event.deltaY > 0 ? endY : -endY;
            var xMulti = event.deltaX * 0.03;
            var yMulti = event.deltaY / 80;
            var rotate = xMulti * yMulti;

            event.target.style.transform = 'translate(' + toX + 'px, ' + (toY + event.deltaY) + 'px) rotate(' + rotate + 'deg)';
            return toX;
        }
        return null;
    }

    const handdlerOnPan = (event, isInitial = false) => {

        if (isInitial === false && answeredInitialCard === false) return;
        event.target.classList.add("moving")
        if (event.deltaX === 0) return;
        if (event.center.x === 0 && event.center.y === 0) return;

        var xMulti = event.deltaX * 0.03;
        var yMulti = event.deltaY / 80;
        var rotate = xMulti * yMulti;
        setFlashCardStatus(event.deltaX > 0 ? true : false);
        event.target.style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)';
    }

    const handdleOnPanEndInitialCard = (event) => {
        const distanceX = getMoveCard(event);
        setFlashCardStatus(null);
        if (distanceX === null) return;

        setAnsweredInitialCard(true);
    }

    const handdlerOnPanEnd = (event, element) => {
        const distanceX = getMoveCard(event);
        if (distanceX !== null) {
            setAnswerNew(element.id, distanceX > 0 ? 'TRUE' : 'FALSE')
        }
        setFlashCardStatus(null);
    }


    const setAnswerNew = (id, response) => {
        const newArray = [...answers];

        newArray.push({
            id: id,
            is_right: response
        })

        setAnswers(newArray);

        // trigger gameEnd event
        if (newArray?.length === optionList.length) end(newArray);
    }

    return (<></>)
    // return (
    //     <StyledActivityContainer>
	// 		{activity?.title && <StyledActivityHeader><h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.title) }}/></StyledActivityHeader>}

    //         <StyledActivityContent client={client}>
    //             {activity?.text && <p className='subtitle'  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }} />}

    //             <StyledTinderContainer>
    //                 {flashCardStatus === null ? null : flashCardStatus ? (
    //                     <StyledTinderStatus>
    //                         <StyledTinderIcon icon={faCheckCircle}  color='#00ff00' />
    //                     </StyledTinderStatus >
    //                 ) : (
    //                     <StyledTinderStatus>
    //                         <StyledTinderIcon icon={faTimesCircle}  color='#ff0000' />
    //                     </StyledTinderStatus >
    //                 )}


    //                 <StyledTinderCardsContainer>
    //                     <Hammer
    //                         key={"Initial card"}
    //                         onPan={(event) => handdlerOnPan(event, true)}
    //                         onPanEnd={(event) => handdleOnPanEndInitialCard(event)}
    //                     >
    //                         <StyledInitialCard
    //                             id={"card-initial"}
    //                             style={{
    //                                 zIndex: optionList.length + 1
    //                             }}
    //                         >
    //                             <h3 >{i18n.exists("microgames:flash_cards") ? i18n.t("microgames:flash_cards.initial_card") : "Swipe right for true, swift left for false"}</h3>
    //                             <StyledContainerSymbols>
    //                                 <div>
    //                                     <FontAwesomeIcon icon={faTimesCircle} className="icon-simbol" color='#ff0000' />
    //                                 </div >
    //                                 <div>
    //                                     <FontAwesomeIcon icon={faCheckCircle} className="icon-simbol" color='#00ff00' />
    //                                 </div >
    //                             </StyledContainerSymbols>
    //                         </StyledInitialCard>
    //                     </Hammer>


    //                     {optionList.map((element, index) => (
    //                         <Hammer
    //                             key={index}
    //                             onPan={(event) => handdlerOnPan(event)}
    //                             onPanEnd={(event) => handdlerOnPanEnd(event, element)}
    //                         >
    //                             <StyledTinderCard
    //                                 id={"card-" + element.id}
    //                                 key={index}
    //                                 style={{
    //                                     zIndex: index
    //                                 }}
    //                             >
    //                                 <div>
    //                                     <img 
    //                                         src={element?.image ? 
    //                                             `${element?.image?.startsWith("@@@") ? MEDIA_PREVIEW : ''}${element?.image}` : element?.image_url ?
    //                                             element?.image_url :
    //                                             PLACEHOLDER
    //                                         } 
    //                                         alt="Option"
    //                                     />
    //                                 </div>

    //                                 <h3  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element?.title) }}></h3>
    //                                 <p>{element?.text}</p>
    //                             </StyledTinderCard>
    //                         </Hammer>
    //                     ))}

    //                     <StyledFinalCard>
    //                         <h3 >{i18n.exists("microgames:flash_cards") ? 
    //                         i18n.t("microgames:flash_cards.final_card") :
    //                          "There are no more cards to be able to slide"}</h3>
    //                     </StyledFinalCard>
    //                 </StyledTinderCardsContainer>
    //             </StyledTinderContainer>
    //         </StyledActivityContent>
    //     </StyledActivityContainer>
    // )
}

export default FlashCard 
