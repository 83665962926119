import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18next/i18next'
import './readSpeaker.scss'


const ReadSpeaker = () => {
    const [_t] = useTranslation("preview");
    const activityLanguage = i18n.language;
    const currentURL = window.location.href;
    const readSpeakerURL = encodeURIComponent(currentURL);
    
    const readSpeakerLanguages = {
        es: 'es_es',
        ca: 'ca_es',
        en:   'en_us',
        eu: 'eu_es',
        gl: 'gl_es'
    }
    window.rsConf = {general: {usePost: true}};
    const langsDisabled = ['eu', 'gl']

    useEffect(() => {
        if(!langsDisabled.includes(activityLanguage)){
            var oHead = document.getElementsByTagName('HEAD').item(0);
            var oScript= document.createElement("script");
            oScript.type = "text/javascript";
            oScript.src=`https://app-eu.readspeaker.com/cgi-bin/rsent?customerid=12874&amp;lang=${readSpeakerLanguages[activityLanguage]}&amp;readid=highlightable&amp;url=${readSpeakerURL}`;
            oHead.appendChild(oScript);
        }
      }, [])


    return (
        <>
            {!langsDisabled.includes(activityLanguage) &&
                <div className="btn-group-vertical cursor-pointer readspeaker_area" id="readspeaker_area" title="ReadSpeaker">
                    <div id="readspeaker_button1" className="rs_skip rsbtn rs_preserve">
                    <a rel="nofollow" className="rsbtn_play" accesskey="L"
                        title={_t("header.readSpeaker_title_play")}
                        href={`https://app-eu.readspeaker.com/cgi-bin/rsent?customerid=12874&amp;lang=${readSpeakerLanguages[activityLanguage]}&amp;readid=highlightable&amp;url=${readSpeakerURL}`}
                    >
                        <span className="rsbtn_left rsimg rspart">
                            <span className="rsbtn_text">
                                <span>
                                    {_t("header.readSpeaker_title")}
                                </span>
                            </span>
                        </span>
                        <span className="rsbtn_right rsimg rsplay rspart"></span>
                    </a>
                    </div>
                </div>
            }
        </>

    )
}

export default ReadSpeaker
