import config from "./config"

const {
    REACT_APP_SUFFLE_ASSESSMENT_ACTIVITIES
} = process.env


export const USER_ACTIVITY_URI = config.xevalUrl + "/user-activities/{id}"
export const USER_ACTIVITY_START_URI = config.xevalUrl + '/user-activities/{id}/start'
export const USER_ACTIVITY_END_URI = config.xevalUrl + '/user-activities/{id}/end'
export const USER_ASSESSMENT_START_URI = config.xevalUrl + '/user-assessments/{id}/start'
export const USER_ASSESSMENT_END_URI = config.xevalUrl + '/user-assessments/{id}?lang={lang}'
export const USER_ASSESSMENT_URI = config.xevalUrl + "/user-activities?user_assessment_id={id}"
export const USER_ASSESSMENT_ATTEMPT_URI = config.xevalUrl + '/user-assessments/{id}/new_try'

export const ONLINE_ACTIVITIES_URI = config.xevalUrl + "/online/activities/{id}"

export const MEDIA_PREVIEW = process.env.REACT_APP_XDAM_API + '/resource/render/'

export const ACTIVITY_TYPE = {
    FROGGY_JUMP: "froggy-jump",
    SINGLE_CHOICE: 'single-choice',
    MULTIPLE_CHOICE:  'multiple-choice',
    TRUE_FALSE: 'true-false',
    TEXT_PAIRING: 'text-pairing',
    IMAGE_PAIRING: 'image-pairing',
    CARD_CHOICE: 'card-choice',
    SEEK_AND_FIND: 'seek-and-find',
    WORD_CATCHER: 'word-catcher',
    WORD_SEARCH: 'word-search',
    FLASH_CARDS: 'flash-cards',
    TEXT_CHOICE: 'text-choice',
    IMAGE_CHOICE: 'image-choice',
    FILL_THE_BLANKS: 'fill-the-blanks',
    ORDER_CHRONOLOGY: 'order-chornology',
    DEDUCTION: 'deduction',
    FIT_WORD: 'fit-word',
    SHORT_QUESTION: 'short-question',
    OPINION_SCALE: 'opinion-scale',
    CORRESPONDENCE: 'correspondence',
    INPUT: 'input',
    INPUT_LONG: 'input-long',
    RANK: 'match',
    MATCH: 'rank'

}

export const RESPONSE_ANSWER_ONLY_OPTIONS = [
    ACTIVITY_TYPE.FLASH_CARDS,
    ACTIVITY_TYPE.SINGLE_CHOICE,
    ACTIVITY_TYPE.TRUE_FALSE,
    ACTIVITY_TYPE.SHORT_QUESTION,
    ACTIVITY_TYPE.CARD_CHOICE,
    ACTIVITY_TYPE.OPINION_SCALE,
    ACTIVITY_TYPE.MULTIPLE_CHOICE,
    ACTIVITY_TYPE.IMAGE_CHOICE,
    ACTIVITY_TYPE.TEXT_CHOICE,
]

export const RESPONSE_ANSWER_ONLY_TARGETS = [
    ACTIVITY_TYPE.WORD_SEARCH,
    ACTIVITY_TYPE.WORD_CATCHER,
    ACTIVITY_TYPE.FILL_THE_BLANKS,
    ACTIVITY_TYPE.SEEK_AND_FIND,
    ACTIVITY_TYPE.INPUT,
    ACTIVITY_TYPE.INPUT_LONG
]

export const RESPONSE_ANSWER_OPTIONS_AND_TARGETS = [
    ACTIVITY_TYPE.FROGGY_JUMP,
    ACTIVITY_TYPE.IMAGE_PAIRING,
    ACTIVITY_TYPE.TEXT_PAIRING,
    ACTIVITY_TYPE.ORDER_CHRONOLOGY,
    ACTIVITY_TYPE.CORRESPONDENCE,
    ACTIVITY_TYPE.DEDUCTION,
    ACTIVITY_TYPE.FIT_WORD,
    ACTIVITY_TYPE.MATCH,
    ACTIVITY_TYPE.RANK,
]


export const ACTIVITIES_INFORMATION_TEMPLATES = {
    [ACTIVITY_TYPE.FROGGY_JUMP]: {
        "id": 1,
        "current_language": "es-ES",
        "type": 'froggy-jump',
        "title": "Froggy Jump",
        "text": "<p>Choose the correct answwer</p>",
        "image": "",
        "props": {
            "time": null,
            "background_image": { }
        },
        "options": [
            {
                "id": 3476,
                "title": "Azul",
                "is_right": "TRUE",
                "target_order": 1
            },
            {
                "id": 3480,
                "title": "2+2=4",
                "is_right": "TRUE",
                "target_order": 2
            },
            {
                "id": 3478,
                "title": "Amarillo",
                "is_right": "FALSE",
                "target_order": 1
            },
            {
                "id": 3477,
                "title": "Rojo",
                "is_right": "FALSE",
                "target_order": 1
            },
            {
                "id": 3479,
                "title": "2+2=22",
                "is_right": "FALSE",
                "target_order": 2
            },
            {
                "id": 3481,
                "title": "2+2=2",
                "is_right": "FALSE",
                "target_order": 2
            }
        ],
        "targets": [
            {
                "id": 3474,
                "title": "¿Cuánto es 2+2?",
                "order": 2
            },
            {
                "id": 3473,
                "title": "De qué color es el cielo?",
                "order": 1
            },
        ],
        "feedback": [
            {
                "text": "Frong answer",
                "success_percentage": 0
            },
            {
                "text": "RIBBIT!!",
                "success_percentage": 100
            }
        ],
    },
    [ACTIVITY_TYPE.SINGLE_CHOICE]: {
        "assessments": [],
        "id": 1,
        "current_language": "es-ES",
        "type": "single-choice",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Escoge la respuesta correcta.",
        "text": "¿Cómo se llama el río más largo del mundo?",
        "options": [
            { "id": 0, "is_right": "FALSE", "title": "Yangtsé​" },
            { "id": 0, "is_right": "FALSE", "title": "Amazonas" },
            { "id": 0, "is_right": "TRUE", "title": "Nilo" },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    [ACTIVITY_TYPE.MULTIPLE_CHOICE]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "multiple-choice",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Marca las respuestas que son correctas.",
        "text": "¿Cuáles son planetas del sistema solar?",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Neptuno" },
            { "id": 0, "is_right": "FALSE", "title": "Luna" },
            { "id": 0, "is_right": "TRUE", "title": "Júpiter" },
            { "id": 0, "is_right": "FALSE", "title": "HR 2562 b" },
            { "id": 0, "is_right": "FALSE", "title": "Draugr" },

        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    [ACTIVITY_TYPE.TRUE_FALSE]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "true-false",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Marca si la afirmación es verdadera o falsa.",
        "text": "Las sustancias iniciales de una reacción química se denominan reactivos.",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Verdadero" },
            { "id": 0, "is_right": "FALSE", "title": "Falso" },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    [ACTIVITY_TYPE.IMAGE_PAIRING]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "image-pairing",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Une la primera fila de imágenes con la imagen correspondiente de la segunda fila.",
        "text": "",
        "options": [
            {
                "id": 0, "target_order": 1, "is_right": "TRUE",
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "target_order": 2, "is_right": "TRUE",
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "target_order": 3, "is_right": "TRUE",
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "order": 2,
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "order": 3,
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    [ACTIVITY_TYPE.TEXT_PAIRING]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "text-pairing",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Une la primera fila de texto con el texto correspondiente de la segunda fila.",
        "text": "",
        "options": [
            {
                "id": 0, "target_order": 1, "is_right": "TRUE",
                "title": "",
            },
            {
                "id": 0, "target_order": 2, "is_right": "TRUE",
                "title": "",
            },
            {
                "id": 0, "target_order": 3, "is_right": "TRUE",
                "title": "",
            },
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "title": "",
            },
            {
                "id": 0, "order": 2,
                "title": "",
            },
            {
                "id": 0, "order": 3,
                "title": "",
            },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    [ACTIVITY_TYPE.CARD_CHOICE]:  {
        "id": 1,
        "current_language": "es-ES",
        "type": "card-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Mueve el icono de la parte inferior hacia la tarjeta con tu respuesta a lo siguiente:",
        "text": "Match the moment during the lesson with the objective to use group work.",
        "background": "PIRATES",
        "options": [
            {
                "id": 0,
                "is_right": "FALSE",
                "feedback": "Hmmm vuelve a intentarlo",
                "title": "Antes de la lección"
            },
            {
                "id": 0,
                "is_right": "TRUE",
                "feedback": "Perfecto",
                "title": "Durante la lección"
            },
            {
                "id": 0,
                "is_right": "FALSE",
                "feedback": "Hmmm vuelve a intentarlo",
                "title": "Después de la lección"
            }
        ]
    },
    [ACTIVITY_TYPE.SEEK_AND_FIND]:  {
        "id": 1,
        "current_language": "es-ES",
        "type": "seek-and-find",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Toca donde está corazón en la imagen.",
        "image": "@@@dam:@image@93dc44b7-f18b-4626-b882-e303a02b37d7@84d97db0-5efa-497c-be13-9087e9c1f146@@@",
        "options": [
            {
                "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 120
                }
            },
            {
                "id": 0, "is_right": "TRUE", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 60
                }
            }
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "area": {
                    "x": 518, "y": 478, "radius": null
                }
            }
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    [ACTIVITY_TYPE.WORD_CATCHER]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "word-catcher",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Atrapa la fruta.",
        "targets": [
            {
                "id": 0,
                "order": 1,
                "accepted_values": ["Platano"],
                "unaccepted_values": ["Pizza", "Cebolla", "Bocadillo", "Queso", "Mantequilla", "Sopa", "Huevo", "Pasta", "Tomate", "Lechuga", "Pan"]
            },
        ],
        "background": null,
        "props": {
            "time": 120,
            "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
            "word_color": '#eeeeee',
            "speed": { "min": 0.5, "max": 1.5 },
        },
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    [ACTIVITY_TYPE.WORD_SEARCH]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "word-search",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Busca estas frutas: plátano, pera y coco.",
        "text": "",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["platano", "pera", "coco"] },
        ],
        "props": {
            "time": 120, "background_image": {}
        },
        "matrix": {
            "data": [],
            "props": {
                "alphabet": "EN",
                "rules": {
                    "allow_diagonal": false,
                    "allow_horizontal": true,
                    "allow_inverse": false,
                    "allow_vertical": true
                },
                "size": {
                    "height": 10,
                    "width": 10
                }
            },
        },
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    [ACTIVITY_TYPE.FLASH_CARDS]: {
        "id": 1,
        "current_language": "es-ES",
        "type": ACTIVITY_TYPE.FLASH_CARDS,
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Swipe Swipe",
        "text": "<p>Swipe right true, swipe left false</p>",
        "image": '',
        "options": [
            {
                "id": 0,
                "text": "First description Card",
                "image": "@@@dam:@image@9655d75c-e087-4273-9c28-cd703bae0720@f16a9d9c-7d41-4c1b-89ae-07c729245046@@@",
                "title": "First card",
                "is_right": "TRUE",
                "target_order": 0,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d75c-e087-4273-9c28-cd703bae0720@f16a9d9c-7d41-4c1b-89ae-07c729245046@@@"
            },
            {
                "id": 0,
                "text": "Second description Card",
                "image": "@@@dam:@image@9655d7b8-da7d-4aea-86b6-7275138c7b3a@383eb435-6179-4e90-869e-8f018b773438@@@",
                "title": "Second card",
                "is_right": "TRUE",
                "target_order": 1,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d7b8-da7d-4aea-86b6-7275138c7b3a@383eb435-6179-4e90-869e-8f018b773438@@@"
            },
            {
                "id": 0,
                "text": "Three description Card",
                "image": "@@@dam:@image@9655d822-8854-4ec8-85ce-c1ec76f178fe@1cb452ba-5066-48bc-be84-197de3b3c5c0@@@",
                "title": "Three Card",
                "is_right": "FALSE",
                "target_order": 2,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d822-8854-4ec8-85ce-c1ec76f178fe@1cb452ba-5066-48bc-be84-197de3b3c5c0@@@"
            },
            {
                "id": 0,
                "text": "Four description Card",
                "image": "@@@dam:@image@9655d86f-710b-4fb9-8550-e99b1ebdf5ea@e8692cf9-3f1d-4697-9165-65bfd4bf3e59@@@",
                "title": "Four Card",
                "is_right": "TRUE",
                "target_order": 3,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d86f-710b-4fb9-8550-e99b1ebdf5ea@e8692cf9-3f1d-4697-9165-65bfd4bf3e59@@@"
            }
        ],
        "feedback": [
            {
                "text": "Sorry, your answer is not correct.",
                "success_percentage": 0
            },
            {
                "text": "Well done!",
                "success_percentage": 3
            }
        ]
    },
    [ACTIVITY_TYPE.TEXT_CHOICE]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "text-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Haga clic en las tarjetas que responden a la pregunta siguiente",
        "text": "",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Voluntario" },
            { "id": 0, "is_right": "TRUE", "title": "Tocar un instrumento" },
            { "id": 0, "is_right": "FALSE", "title": "Beber" },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Oops. Vuelve a intentarlo" },
            { "success_percentage": 50, "text": "Mmm. Vuelve a intentarlo. ¡Tú puedes!" },
            { "success_percentage": 100, "text": "Bien hecho. ¡Enhorabuena!" }
        ]
    },
    [ACTIVITY_TYPE.IMAGE_CHOICE]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "image-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Haga clic en las tarjetas que responden a la pregunta siguiente",
        "text": "¿Cuál de las siguientes imágenes no tiene nada que ver con las demás?",
        "options": [
            {
                "id": 0, "is_right": "FALSE",
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "is_right": "FALSE",
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "is_right": "TRUE",
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Oops. Vuelve a intentarlo" },
            { "success_percentage": 50, "text": "Mmm. Vuelve a intentarlo. ¡Tú puedes!" },
            { "success_percentage": 100, "text": "Bien hecho. ¡Enhorabuena!" }
        ]
    },
    [ACTIVITY_TYPE.FILL_THE_BLANKS]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "fill-the-blanks",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Rellena los espacios en blanco para completar la frase siguiente:",
        "text": "Un {1} no es más que una serie de de pasos que se siguen para completar una determinada {2}.",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["algoritmo"] },
            { "id": 0, "order": 2, "accepted_values": ["tarea"] },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    [ACTIVITY_TYPE.ORDER_CHRONOLOGY]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "order-chronology",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Ordena las imágenes según la cantidad de criterios que cumplen: facilitan tu movilidad, la movilidad de tus alumnos, trabajar en grupos y mirar la pizarra.",
        "text": "",
        "options": [
            { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "image_url": "" },
            { "id": 0, "target_order": 1, "is_right": "FALSE", "image": "", "image_url": "" },
            { "id": 0, "target_order": 2, "is_right": "FALSE", "image": "", "image_url": "" }
        ],
        "targets": [
            { "id": 0, "order": 3, "title": "Permite trabajar en grupos, pueden moverse, pero algunos están de espaldas a la pizarra." },
            { "id": 0, "order": 1, "title": "Permite trabajar en grupos, mirar la pizarra y facilita la movilidad de la mayoría de los alumnos." },
            { "id": 0, "order": 2, "title": "Permite trabajar en grupos, pueden moverse bien, pero no todos verán bien la pizarra." }
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    [ACTIVITY_TYPE.DEDUCTION]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "fit-word",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Arrastra cada palabra a la casilla correspondiente.",
        "text": "La ecología es el estudio de las relaciones entre los {1} vivos, incluidos los humanos, y su {2} físico.",
        "targets": [{ "id": 0, "order": 1 }, { "id": 0, "order": 2 }],
        "options": [
            { "id": 0, "target_order": 1, "title": "organismos", "is_right": "TRUE" },
            { "id": 0, "target_order": 2, "title": "entorno", "is_right": "TRUE" },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ],
    },
    [ACTIVITY_TYPE.FIT_WORD]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "fit-word",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Arrastra cada palabra a la casilla correspondiente.",
        "text": "La ecología es el estudio de las relaciones entre los {1} vivos, incluidos los humanos, y su {2} físico.",
        "targets": [{ "id": 0, "order": 1 }, { "id": 0, "order": 2 }],
        "options": [
            { "id": 0, "target_order": 1, "title": "organismos", "is_right": "TRUE" },
            { "id": 0, "target_order": 2, "title": "entorno", "is_right": "TRUE" },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ],
    },
    [ACTIVITY_TYPE.SHORT_QUESTION]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "short-question",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Elige la pregunta correcta a la pregunta planteada",
        "text": "Reutilizar un objeto o material, ya sea para el mismo fin que tenía originalmente o para uno nuevo.",
        "options": [
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "¿Qué es el reciclaje?",
                "feedback": "Debemos empezar por reducir los residuos que generamos y que acaban en los vertederos en la mayoría de los casos, como los plásticos."
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "¿Qué es la reutilización?",
                "feedback": "Como hemos presentado, todo puede tener múltiples usos, sólo tenemos que pensar qué necesitamos y cómo podemos hacerlo con los recursos disponibles a nuestro alrededor"
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "¿Qué es reducir?",
                "feedback": "Debemos empezar por reducir los residuos que generamos y que acaban en los vertederos en la mayoría de los casos, como los plásticos."
            }
        ],
    },
    [ACTIVITY_TYPE.OPINION_SCALE]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "opinion-scale",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Estás de acuerdo con...",
        "text": "Las tareas auténticas son aquellas que promueven que los estudiantes construyan sus respuestas y las utilicen para dar respuesta a situaciones del mundo real.",
        "options": [
            {
                "id": 0,
                "is_right": "FALSE",
                "image": "Disagree_Colour.svg",
                "title": "En desacuerdo",
                "order": 2
            },
            {
                "id": 0,
                "is_right": "FALSE",
                "image": "Neutral_Colour.svg",
                "title": "Neutro",
                "order": 3
            },
            {
                "id": 0,
                "is_right": "TRUE",
                "image": "Agree_Colour.svg",
                "title": "De acuerdo",
                "order": 4
            }
        ],
        "feedback": [
            { "success_percentage": 0, "text": "¿No quieres reconsiderarlo? Con las tareas auténticas podremos observar qué han aprendido realmente nuestros alumnos." },
            { "success_percentage": 100, "text": "Así es. De esta forma vamos a poder observar qué han aprendido realmente nuestros alumnos." }
        ]
    },
    [ACTIVITY_TYPE.CORRESPONDENCE]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "correspondence",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Título de actividad de tipo correspondencia.",
        "text": "Contenido de actividad de tipo correspondencia",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE" },
            { "id": 0, "target_order": 1, "is_right": "FALSE" }
        ],
        "targets": [
            { "id": 0, "order": 1 }
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    [ACTIVITY_TYPE.INPUT]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "input",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Escribe tu respuesta a continuación.",
        "text": "¿Cómo se llama la unidad más pequeña de la vida?",
        "options": [],
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["célula"] },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ],
    },
    [ACTIVITY_TYPE.INPUT_LONG]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "input-long",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Escribe tu respuesta a continuación.",
        "text": "¿Cómo se llama la unidad más pequeña de la vida?",
        "options": [],
        "targets": [
            { "id": 0, "order": 1, "accepted_values": [] },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ],
    },
    [ACTIVITY_TYPE.MATCH]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "match",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Empareja las opciones como corresponda.",
        "text": "Ordena cronológicamente estas edades de la historia (de más a menos reciente).",
        "options": [
            { "id": 0, "target_order": 1, "title": "Edad Moderna", "is_right": "TRUE" },
            { "id": 0, "target_order": 3, "title": "Edad Antigua", "is_right": "TRUE" },
            { "id": 0, "target_order": 2, "title": "Edad Media", "is_right": "TRUE" },
        ],
        "targets": [
            { "id": 0, "order": 1, "title": "Primera" },
            { "id": 0, "order": 2, "title": "Segunda" },
            { "id": 0, "order": 3, "title": "Tercera" },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
    [ACTIVITY_TYPE.RANK]: {
        "id": 1,
        "current_language": "es-ES",
        "type": "rank",
        "status": "ACTIVE",
        "version": 1,
        "level": 1,
        "title": "Empareja las opciones como corresponda.",
        "text": "Ordena cronológicamente estas edades de la historia (de más a menos reciente).",
        "options": [
            { "id": 0, "target_order": 1, "title": "Edad Moderna", "is_right": "TRUE" },
            { "id": 0, "target_order": 3, "title": "Edad Antigua", "is_right": "TRUE" },
            { "id": 0, "target_order": 2, "title": "Edad Media", "is_right": "TRUE" },
        ],
        "targets": [
            { "id": 0, "order": 1, "title": "Primera" },
            { "id": 0, "order": 2, "title": "Segunda" },
            { "id": 0, "order": 3, "title": "Tercera" },
        ],
        "feedback": [
            { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
            { "success_percentage": 100, "text": "¡Muy bien!" }
        ]
    },
}


export const SHUFFLE_ASSESSMENT_ACTIVITIES = REACT_APP_SUFFLE_ASSESSMENT_ACTIVITIES === 'true'
